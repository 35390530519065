<template>
  <div class="my-5">
    <v-row class="pb-3">
      <v-toolbar
        rounded="lg"
        outlined
        dark
        color="grey lighten-3"
        height="30"
        elevation="0"
      >
       <v-toolbar-title class="black--text">Load Basic</v-toolbar-title>
      </v-toolbar>
    </v-row>
    <v-row>
      <v-col lg="6" sm="12" class="py-0">
        <v-autocomplete
          v-model="load.stateLoadId"
          :items="loadsTypes"
          label="In Transit"
          item-text="description"
          :return-object="true"
          required
          outlined
          dense
        ></v-autocomplete>
      </v-col>
      <v-col lg="6" sm="12" class="py-0 ma-0">
        <v-text-field
          label="Load Reference ID / Numbers"
          v-model="load.reference"
          required
          outlined
          dense
          :rules="[rules.validation]"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row class="pb-3">
      <v-toolbar
        rounded="lg"
        outlined
        dark
        color="grey lighten-3"
        height="30"
        elevation="0"
      >
       <v-toolbar-title class="black--text">Broker Information</v-toolbar-title>
      </v-toolbar>
    </v-row>
    <v-row>
      <v-col lg="6" sm="12" class="py-0">
        <auto-complete
          label="Select Broker"
          :items="brokers"
          textItem="company"
          url="/broker"
          :defaultValue="load.brokerId"
        />
      </v-col>
    </v-row>
    <v-row class="pb-3">
      <v-toolbar
        rounded="lg"
        outlined
        dark
        color="grey lighten-3"
        height="30"
        elevation="0"
      >
       <v-toolbar-title class="black--text">Carrier/Asset Info</v-toolbar-title>
      </v-toolbar>
    </v-row>
    <v-row>
      <v-col lg="6" sm="12" class="py-0">
        <auto-complete
          label="Select Driver"
          :items="drivers"
          textItem="name"
          url="/driver"
          :defaultValue="load.driverId"
        />
      </v-col>
      <v-col lg="6" sm="12" class="py-0 ma-0">
        <auto-complete
          label="Select Trailer"
          :items="trailers"
          textItem="trailer_number"
          url="/trailers"
          :defaultValue="load.trailerId"
        />
      </v-col>
      <v-col lg="6" sm="12" class="py-0 ma-0">
        <auto-complete
          label="Select Tractor"
          :items="tractors"
          textItem="power_unit"
          url="/tractor"
          :defaultValue="load.tractorId"
        />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import AutoComplete from '@/components/general/AutoComplete.vue';

export default {
  components: {
    AutoComplete,
  },
  data() {
    return {
      rules: {
        validation: (v) => v !== '' || '* is required',
      },
    };
  },
  computed: {
    ...mapState({
      drivers: (state) => state.Loads.drivers,
      trailers: (state) => state.Loads.trailers,
      tractors: (state) => state.Loads.tractors,
      brokers: (state) => state.Loads.brokers,
      loadsTypes: (state) => state.Loads.loadsTypes,
      load: (state) => state.Loads.load,
    }),
  },
};
</script>
