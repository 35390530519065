import httpClient from '@/config';

export const getRoles = () => new Promise((resolve, reject) => {
  httpClient.get('/roles')
    .then((response) => {
      const { data } = response;
      resolve(data);
    })
    .catch((error) => reject(error));
});

export const get = (user) => new Promise((resolve, reject) => {
  httpClient.post('/state', user)
    .then((response) => {
      const { data } = response;
      resolve(data);
    })
    .catch((error) => reject(error));
});
