<template>
  <div class="">
    <v-card class="mt-5 rounded-lg b-shadow">
      <v-card-title>
        <v-btn color="primary"
          @click="$router.push({ name: ROUTES.TRAILERS.CREATE })"
        >
          create trailers
        </v-btn>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        v-if="!isLoading"
        :headers="headers"
        :items="trailers"
        :search="search"
        :page.sync="page"
        :items-per-page="itemsPerPage"
        hide-default-footer
        class="text-body-1 font-weight-black"
      >
      <template v-slot:[`item.stateId.description`]="{ item }">
        <v-chip
          class="status-chip"
          :color="item.stateId.description === 'Active' ? 'green' : 'red'"
          dark
          small
        >
          {{ item.stateId.description }}
        </v-chip>
    </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon
          small
          class="mr-2"
          @click="editTrailer(item)"
        >
          mdi-pencil-outline
        </v-icon>
        <v-icon
          small
          @click="openDeleteDialog(item, item.id)"
        >
          mdi-trash-can-outline
        </v-icon>
      </template>
      </v-data-table>
      <loading-table
        v-if="isLoading"
      />
      <template v-if="deleteDialog">
        <delete-dialog
        :showModal="deleteDialog"
        @closed="closeDeleteDialog"
        />
      </template>
      <div class="text-center my-4 pb-3">
        <v-pagination
        v-model="page"
        :length="pageCount"
      ></v-pagination>
      </div>
    </v-card>
  </div>
</template>
<script>
import { ROUTES } from '@/store/constants';
import * as Trailers from '@/services/trailers';
import LoadingTable from '@/components/general/LoadingTable.vue';
import DeleteDialog from '@/components/dialog/Delete.vue';

export default {
  name: 'Tractors',
  components: {
    LoadingTable,
    DeleteDialog,
  },
  data() {
    return {
      take: 10,
      skip: 0,
      deleteDialog: false,
      ROUTES,
      isLoading: false,
      page: 1,
      pageCount: 1,
      itemsPerPage: 10,
      search: '',
      trailers: [],
      trailer: [],
      trailerId: 0,
      headers: [
        { text: 'Actions', value: 'actions', sortable: false },
        { text: 'Id', value: 'id' },
        { text: 'Make/Model', value: 'model' },
        { text: 'Type triler', value: 'type' },
        { text: 'Trailer Number', value: 'trailer_number' },
        { text: 'License Plate', value: 'license_plate' },
        { text: 'Vehicle id', value: 'vehicle_id' },
        { text: 'Year model', value: 'year_model' },
        { text: 'State', value: 'stateId.description' },

      ],
    };
  },
  watch: {
    page() {
      this.skip = (this.page - 1) * 10;
      this.getTrailers();
    },
    search() {
      this.skip = 0;
      this.getTrailers();
    },
  },
  methods: {
    editTrailer(trailer) {
      const to = { name: 'edit trailer', params: { id: trailer.id } };
      this.$router.push(to);
    },
    async getTrailers() {
      this.isLoading = true;
      const response = await Trailers.getTrailers(this.take, this.skip, this.search);
      this.pageCount = Math.ceil(response.count / 10);
      this.trailers = response.data;
      this.isLoading = false;
    },
    openDeleteDialog(trailer, id) {
      this.trailer = trailer;
      this.trailerId = id;
      this.deleteDialog = true;
    },

    async closeDeleteDialog(close) {
      if (close) {
        this.trailer.stateId = 3;

        const result = await Trailers.deleteTrailer(this.trailerId, this.trailer);
        if (result) this.getTrailers();
      }
      this.deleteDialog = false;
    },
  },
  created() {
    this.getTrailers();
  },
};
</script>
