<template>
  <v-autocomplete
    :label="label"
    v-model="modelValue"
    :items="itemsValue"
    :item-text="textItem"
    :return-object="!multiple"
    :search-input.sync="search"
    required
    outlined
    dense
    :multiple="multiple"
    :chips="chips"
    :deletable-chips="chips"
    item-value="id"
  ></v-autocomplete>
</template>
<script>
import { mapState } from 'vuex';
import httpClient from '@/config';

const token = localStorage.getItem('token');
const headers = {
  headers: {
    Authorization: `Bearer ${token}`,
  },
};

export default {
  props: {
    textItem: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
    defaultValue: {
      required: false,
    },
    multiple: {
      required: false,
      default: false,
    },
    chips: {
      required: false,
      default: false,
    },
  },
  data() {
    return {
      search: '',
      modelValue: null,
      itemsValue: [],
    };
  },
  watch: {
    search() {
      this.getData();
    },
    modelValue() {
      this.$emit('updateValue', this.modelValue);
      switch (this.url) {
        case '/tractor':
          this.load.tractorId = this.modelValue;
          break;
        case '/broker':
          this.load.brokerId = this.modelValue;
          break;
        case '/driver':
          this.load.driverId = this.modelValue;
          break;
        case '/trailers':
          this.load.trailerId = this.modelValue;
          break;
        default:
          break;
      }
    },
  },
  computed: {
    ...mapState({
      load: (state) => state.Loads.load,
    }),
  },
  methods: {
    async getData() {
      if (this.search === '' || this.search === null) return;
      headers.headers.take = 10;
      headers.headers.search = this.search;
      const response = await httpClient.get(`${this.url}`, headers);
      this.itemsValue = response.data.data;
      this.itemsValue.push(...this.items);
    },
  },
  mounted() {
    this.itemsValue = this.items;
    if (this.defaultValue) {
      this.modelValue = this.defaultValue;
      this.itemsValue.push(this.defaultValue);
    }
  },
};
</script>
