<template>
  <v-app>
    <div class="d-flex">
      <v-main>
        <router-view/>
      </v-main>
      <container />
    </div>
  </v-app>
</template>

<script>
import Container from '@/components/general/Container.vue';

export default {
  name: 'App',
  components: {
    Container,
  },
};
</script>
