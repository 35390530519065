import * as constants from '@/store/constants';
import * as Trailers from '@/services/trailers';
import * as Drivers from '@/services/drivers';
import * as Tractors from '@/services/tractors';
import * as Brokers from '@/services/brokers';
import * as Loads from '@/services/loads';
import router from '@/router';

const initData = {
  stateId: 1,
  driver_payment: false,
  payment_time: '',
  stateLoadId: 1,
  dead_head_milles: 0,
  dead_head_address: '',
  driverId: '',
  tractorId: '',
  trailerId: '',
  brokerId: '',
  reference: '',
  cost: 0,
  milles: 0,
};
const state = {
  load: initData,
  directions: [],
  deadhead: '',
  shipper: [],
  shippersDeleted: [],
  consignee: [],
  consigneeDeleted: [],
  deadheadMilles: 0,
  carrierMilles: 0,
  accesorials: [],
  brokers: [],
  tractors: [],
  trailers: [],
  drivers: [],
  milles: 0,
  loadsTypes: [],
  accesorialTypes: [],
  accesorialCategories: [],
  isEditLoad: false,
  document: [],
  counter: 0,
  loadMap: false,
  isLoading: false,
  isError: false,
  message: '',
  accesorialDialog: false,
  accesorialAction: '',
  accesorialNumberType: null,
  accesorialEdit: null,
  isLoadAccesorial: false,
  accesorialsIncome: [],
  accesorialsExpense: [],
  accesorialsDeductions: [],
  accesorialsDeleted: [],
  isLoadingLoad: false,
  isLoadingAction: false,
  isLoadingOneLoad: false,
  showOptionsButtons: true,
};

const actions = {
  [constants.LOADS_ADD_DEADHEAD]: ({ commit, dispatch, rootState }, data) => {
    const { Loads: { directions } } = rootState;
    if (data) {
      if (state.deadhead) {
        directions[0] = { path: data, position: 0 };
      } else {
        directions.unshift({ path: data, position: 0 });
      }
      commit(constants.LOADS_SET_PROPERTY, { deadhead: data });
      commit(constants.LOADS_SET_PROPERTY, { directions });
    } else {
      state.directions = directions.filter((element) => element.position > 0);
      commit(constants.LOADS_SET_PROPERTY, { deadhead: '' });
    }
    dispatch(constants.LOADS_RELOAD_MAP);
  },

  [constants.LOADS_ADD_SHIPPER]: ({ commit, dispatch, rootState }, data) => {
    const { Loads: { shipper, directions } } = rootState;
    const { edit, index, ...info } = data;

    if (edit) {
      shipper[index] = info;
      const i = directions.findIndex((d) => d.position === info.position);
      directions[i] = info;
    } else {
      info.position = state.counter + 1;
      state.counter += 1;
      shipper.push(info);
      directions.push(info);
    }
    commit(constants.LOADS_SET_PROPERTY, { shipper });
    commit(constants.LOADS_SET_PROPERTY, { directions });
    dispatch(constants.LOADS_RELOAD_MAP);
  },

  [constants.LOADS_ADD_CONSIGNEE]: ({ commit, dispatch, rootState }, data) => {
    const { Loads: { consignee, directions } } = rootState;
    const { edit, index, ...info } = data;

    if (edit) {
      consignee[index] = info;
      const i = directions.findIndex((d) => d.position === info.position);
      directions[i] = info;
    } else {
      info.position = state.counter + 1;
      state.counter += 1;
      consignee.push(info);
      directions.push(info);
    }
    commit(constants.LOADS_SET_PROPERTY, { consignee });
    commit(constants.LOADS_SET_PROPERTY, { directions });
    dispatch(constants.LOADS_RELOAD_MAP);
  },

  [constants.LOADS_RELOAD_MAP]: ({ commit }) => {
    commit(constants.LOADS_SET_PROPERTY, { loadMap: false });
    setTimeout(() => {
      commit(constants.LOADS_SET_PROPERTY, { loadMap: true });
    }, 100);
  },

  [constants.LOADS_ADD_DIRECTION]: ({ commit, rootState }, data) => {
    const { Loads: { directions } } = rootState;
    directions.push(data);
    commit(constants.LOADS_SET_PROPERTY, { directions });
  },

  [constants.LOADS_SET_ACCESORIAL]: ({ commit, rootState }, data) => {
    commit(constants.LOADS_SET_PROPERTY, { isLoadAccesorial: true });
    const { Loads: { accesorials } } = rootState;
    const { accesorial, action } = data;
    if (action === 'create') {
      accesorial.index = new Date();
      accesorials.push(accesorial);
      commit(constants.LOADS_SET_PROPERTY, { accesorials });
    }
    if (action === 'edit') {
      let position = accesorials.findIndex((acc) => acc?.index === accesorial?.index);
      if (!position) position = accesorials.findIndex((acc) => acc.id === accesorial?.id);
      accesorials[position] = accesorial;
      commit(constants.LOADS_SET_PROPERTY, { accesorials });
    }
    if (action === 'delete') {
      let newAccesorials = [];
      if (accesorial?.id) {
        state.accesorialsDeleted.push(accesorial);
        newAccesorials = accesorials.filter((acc) => acc.id !== accesorial.id);
      } else {
        newAccesorials = accesorials.filter((acc) => acc.index !== accesorial.index);
      }
      commit(constants.LOADS_SET_PROPERTY, { accesorials: newAccesorials });
    }
    setTimeout(() => {
      commit(constants.LOADS_SET_PROPERTY, { isLoadAccesorial: false });
    }, 300);
  },

  [constants.LOADS_REMOVE_PATH]: ({ commit, dispatch, rootState }, data) => {
    const { Loads: { shipper, directions, consignee } } = rootState;
    const { action, ...info } = data;
    if (action === 'shipper') {
      if (info.id) state.shippersDeleted.push(info);
      const newShippers = shipper.filter((shi) => shi.path !== data.path);
      commit(constants.LOADS_SET_PROPERTY, { shipper: newShippers });
    } else {
      if (info.id) state.consigneeDeleted.push(info);
      const newConsignee = consignee.filter((consig) => consig.path !== data.path);
      commit(constants.LOADS_SET_PROPERTY, { consignee: newConsignee });
    }
    const newDirections = directions.filter((direct) => direct.path !== data.path);
    commit(constants.LOADS_SET_PROPERTY, { directions: newDirections });
    dispatch(constants.LOADS_RELOAD_MAP);
    state.counter = Math.max(...state.directions.map((d) => d.position), 0);
  },

  [constants.LOADS_GET_INFORMATION]: ({ commit }) => {
    state.isLoadingLoad = true;
    Promise.all([
      Trailers.getTrailers(),
      Brokers.getBrokers(),
      Tractors.getTractors(),
      Drivers.getDrivers(),
      Loads.getLoadsTypes(),
      Loads.getAccesorialTypes(),
      Loads.getAccesorialCategories(),
    ]).then((
      [trailers, brokers, tractors, drivers, loadsTypes, accesorialTypes, accesorialCategories],
    ) => {
      commit(constants.LOADS_SET_PROPERTY, { trailers: trailers.data });
      commit(constants.LOADS_SET_PROPERTY, { brokers: brokers.data });
      commit(constants.LOADS_SET_PROPERTY, { tractors: tractors.data });
      commit(constants.LOADS_SET_PROPERTY, { drivers: drivers.data });
      commit(constants.LOADS_SET_PROPERTY, { loadsTypes });
      commit(constants.LOADS_SET_PROPERTY, { accesorialTypes });
      commit(constants.LOADS_SET_PROPERTY, { accesorialCategories });
      state.isLoadingLoad = false;
    });
  },

  [constants.LOADS_GET_LOAD]: async ({ commit }, loadId) => {
    if (router.history.current.name === 'create loads') return;
    state.showOptionsButtons = false;
    state.isLoadingOneLoad = true;
    const load = await Loads.getOneLoad(loadId);
    state.isLoadingOneLoad = false;

    commit(constants.LOADS_SET_PROPERTY, { load });
    state.accesorials = load.accesorialId;
    const newDirections = [];
    state.loadMap = false;
    setTimeout(() => {
      state.accesorials = load.accesorialId
        .sort(((a, b) => a.accesorialTypeId.id - b.accesorialTypeId.id));
      state.deadhead = load.dead_head_address;
      if (load.dead_head_address) {
        newDirections.push({ path: state.deadhead, position: 0 });
      }
      state.consignee = load.consigneeId.sort((a, b) => a.position - b.position);
      state.shipper = load.shipperId.sort((a, b) => a.position - b.position);
      newDirections.push(...state.consignee, ...state.shipper);
      state.directions = newDirections.sort((a, b) => a.position - b.position);
      const counter = Math.max(...state.directions.map((d) => d.position));
      state.counter = Number(counter) >= 0 ? Number(counter) : 0;
      state.loadMap = true;
      state.showOptionsButtons = true;
    }, 3000);
  },

  [constants.LOADS_CREATE_LOAD]: ({ commit, dispatch, rootState }) => {
    commit(constants.LOADS_SET_PROPERTY, { isLoadingAction: true });
    const {
      Loads: {
        load,
        shipper,
        shippersDeleted,
        consignee,
        consigneeDeleted,
        accesorials,
        accesorialsDeleted,
      },
    } = rootState;

    const loadCopy = { ...load, dead_head_address: state.deadhead };
    const accesorialCopy = [...accesorials];
    if (accesorialsDeleted.length > 0) accesorialCopy.push(...accesorialsDeleted);

    const newAccesorials = accesorialCopy.map((item) => {
      const info = { ...item };
      info.accesorialCategoryId = item.accesorialCategoryId?.id;
      info.accesorialTypeId = item.accesorialTypeId?.id;
      info.driverId = load.driverId?.id;
      info.stateId = item.stateId?.id ? item.stateId?.id : 1;
      delete info?.index;
      delete info?.driver;
      delete info?.isDriver;
      if (!state.isEditLoad) delete info?.id;
      return info;
    });

    Object.assign(loadCopy, {
      driverId: load?.driverId?.id || null,
      tractorId: load?.tractorId?.id || null,
      trailerId: load?.trailerId?.id || null,
      brokerId: load?.brokerId?.id,
      stateLoadId: load?.stateLoadId?.id || 1,
    });
    const consigneeData = [];
    const shipperData = [];

    shipperData.push(...shipper, ...shippersDeleted);
    consigneeData.push(...consignee, ...consigneeDeleted);
    const sendLoad = {
      load: {
        ...loadCopy,
      },
      shipper: shipperData,
      consignee: consigneeData,
      accesorial: newAccesorials,
      document: [],
    };
    console.log('sendLoad: ', sendLoad);

    if (state.isEditLoad) {
      delete sendLoad.load.accesorialId;
      delete sendLoad.load.consigneeId;
      delete sendLoad.load.shipperId;
      delete sendLoad.load.documentId;
      delete sendLoad.load.stateId;
      delete sendLoad.load.id;
      Loads.updateLoad(load.id, sendLoad)
        .then((data) => {
          console.log('data: ', data);
          state.isLoading = true;
          state.message = 'Load updated';
          commit(constants.LOADS_SET_PROPERTY, { isLoadingAction: false });
          dispatch(constants.LOADS_GET_LOAD, load.id);
          dispatch(constants.LOADS_RESET_DATA);
        })
        .catch(() => {
          state.isLoading = true;
          state.message = 'There is a error';
          state.isError = true;
          commit(constants.LOADS_SET_PROPERTY, { isLoadingAction: false });
        });
    } else {
      Loads.createLoad(sendLoad)
        .then((resp) => {
          console.log('resp: ', resp);
          state.isLoading = true;
          state.message = 'Load created';
          commit(constants.LOADS_SET_PROPERTY, { isLoadingAction: false });
          const to = { name: 'edit load', params: { id: resp.data.id } };
          setTimeout(() => {
            router.push(to);
          }, 2000);
        })
        .catch((error) => {
          state.isLoading = true;
          state.message = error?.response?.data?.message;
          state.isError = true;
          commit(constants.LOADS_SET_PROPERTY, { isLoadingAction: false });
        });
    }
  },

  [constants.LOADS_RESET_DATA]: ({ commit }) => {
    commit(constants.LOADS_SET_PROPERTY, { deadhead: '' });
    commit(constants.LOADS_SET_PROPERTY, { deadheadMilles: 0 });
    commit(constants.LOADS_SET_PROPERTY, { carrierMilles: 0 });
    commit(constants.LOADS_SET_PROPERTY, { directions: [] });
    commit(constants.LOADS_SET_PROPERTY, { shipper: [] });
    commit(constants.LOADS_SET_PROPERTY, { shippersDeleted: [] });
    commit(constants.LOADS_SET_PROPERTY, { consignee: [] });
    commit(constants.LOADS_SET_PROPERTY, { consigneeDeleted: [] });
    commit(constants.LOADS_SET_PROPERTY, { document: [] });
    commit(constants.LOADS_SET_PROPERTY, { counter: 0 });
    commit(constants.LOADS_SET_PROPERTY, { milles: 0 });
    commit(constants.LOADS_SET_PROPERTY, { accesorials: [] });
    commit(constants.LOADS_SET_PROPERTY, { accesorialsDeductions: [] });
    commit(constants.LOADS_SET_PROPERTY, { accesorialsExpense: [] });
    commit(constants.LOADS_SET_PROPERTY, { accesorialsIncome: [] });
    commit(constants.LOADS_SET_PROPERTY, { load: { ...initData } });
  },
};
const mutations = {
  // eslint-disable-next-line
  [constants.LOADS_SET_PROPERTY]: (state, data) => {
    const [[property, value]] = Object.entries(data);
    state[property] = value;
  },
};

export default {
  state,
  actions,
  mutations,
};
