<template>
  <div>
    <v-row>
      <v-col lg="6" sm="12">
        <v-toolbar
          rounded="lg"
          class="mb-3"
          outlined
          dark
          color="grey lighten-3"
          height="30"
          elevation="0"
        >
          <v-toolbar-title class="black--text">Load Document</v-toolbar-title>
        </v-toolbar>
        <v-row class="ma-0">
          <v-col class="py-0 ma-0">
            <div v-if="showFiles.rate_con">
              <v-btn text
                class="circular-btn"
                :href="showFiles.rate_con"
                target="_blank"
              >
                <v-icon small
                >
                  mdi-eye
                </v-icon>
              </v-btn>
              <v-btn text class="circular-btn"
                @click="openDeleteDialog({ rate_con: showFiles.rate_con })"
              >
                <v-icon small color="red">
                  mdi-trash-can-outline
                </v-icon>
              </v-btn>
            </div>
            <v-file-input
              v-model="files.rate_con"
              :disabled="!!showFiles.rate_con"
              prepend-icon="mdi-camera"
              label="Rate Con"
              required
              dense
            ></v-file-input>
          </v-col>
          <v-col class="py-0">
            <div v-if="showFiles.bill_of_loading">
              <v-btn text
                class="circular-btn"
                :href="showFiles.bill_of_loading"
                target="_blank"
              >
                <v-icon small>
                  mdi-eye
                </v-icon>
              </v-btn>
              <v-btn text class="circular-btn">
                <v-icon small color="red"
                  @click="openDeleteDialog({ bill_of_loading: showFiles.bill_of_loading })"
                >
                  mdi-trash-can-outline
                </v-icon>
              </v-btn>
            </div>
            <v-file-input
              v-model="files.bill_of_loading"
              :disabled="!!showFiles.bill_of_loading"
              prepend-icon="mdi-camera"
              label="Bill of Loading"
              required
              dense
            ></v-file-input>
          </v-col>
        </v-row>
        <v-row class="ma-0">
          <v-col class="py-0 ma-0">
            <div v-if="showFiles.proof_delivery">
              <v-btn text
                class="circular-btn"
                :href="showFiles.proof_delivery"
                target="_blank"
              >
                <v-icon small>
                  mdi-eye
                </v-icon>
              </v-btn>
              <v-btn text class="circular-btn"
                @click="openDeleteDialog({ proof_delivery: showFiles.proof_delivery })"
              >
                <v-icon small color="red">
                  mdi-trash-can-outline
                </v-icon>
              </v-btn>
            </div>
            <v-file-input
              v-model="files.proof_delivery"
              :disabled="!!showFiles.proof_delivery"
              prepend-icon="mdi-camera"
              label="Proof of Delivery"
              required
              dense
            ></v-file-input>
          </v-col>
          <v-col class="py-0 ma-0">
            <div v-if="showFiles.lumper_recepit">
              <v-btn text
                class="circular-btn"
                :href="showFiles.lumper_recepit"
                target="_blank"
              >
                <v-icon small>
                  mdi-eye
                </v-icon>
              </v-btn>
              <v-btn text class="circular-btn"
                @click="openDeleteDialog({ lumper_recepit: showFiles.lumper_recepit })"
              >
                <v-icon small color="red">
                  mdi-trash-can-outline
                </v-icon>
              </v-btn>
            </div>
            <v-file-input
              v-model="files.lumper_recepit"
              :disabled="!!showFiles.lumper_recepit"
              prepend-icon="mdi-camera"
              label="Lumper Recepit"
              required
              dense
            ></v-file-input>
          </v-col>
        </v-row>
        <v-row class="ma-0">
          <v-col class="py-0 ma-0">
            <div v-if="showFiles.scale_ticket">
              <v-btn text
                class="circular-btn"
                :href="showFiles.scale_ticket"
                target="_blank"
              >
                <v-icon small>
                  mdi-eye
                </v-icon>
              </v-btn>
              <v-btn text class="circular-btn">
                <v-icon small color="red"
                  @click="openDeleteDialog({ scale_ticket: showFiles.scale_ticket })"
                >
                  mdi-trash-can-outline
                </v-icon>
              </v-btn>
            </div>
            <v-file-input
              v-model="files.scale_ticket"
              :disabled="!!showFiles.scale_ticket"
              prepend-icon="mdi-camera"
              label="Scale Ticket"
              required
              dense
            ></v-file-input>
          </v-col>
          <v-col class="py-0 ma-0">
            <div v-if="showFiles.other">
              <v-btn text
                class="circular-btn"
                :href="showFiles.other"
                target="_blank"
              >
                <v-icon small>
                  mdi-eye
                </v-icon>
              </v-btn>
              <v-btn text class="circular-btn">
                <v-icon small color="red"
                  @click="openDeleteDialog({ other: showFiles.other })"
                >
                  mdi-trash-can-outline
                </v-icon>
              </v-btn>
            </div>
            <v-file-input
              v-model="files.other"
              :disabled="!!showFiles.other"
              prepend-icon="mdi-camera"
              label="Other"
              required
              dense
            ></v-file-input>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="6">
        <v-form
          ref="form"
          v-model="validateForm"
          lazy-validation
        >
          <v-text-field
            v-model="email"
            :rules="emailRules"
            label="E-mail"
            required
            outlined
            dense
          ></v-text-field>
          <v-text-field
            outlined
            dense
            v-model="pod"
            :rules="emailRules"
            label="POD"
            required
          ></v-text-field>
          <v-text-field
            outlined
            dense
            v-model="cc"
            :rules="emailRules"
            label="CC"
            required
          ></v-text-field>
          <v-btn
            class="mr-4"
            :loading="isLoadingEmail"
            @click="validate"
          >
            Send email
          </v-btn>
          <v-btn
            color="primary"
            class="mr-4"
            @click="setData(0)"
            :loading="isLoading"
          >
            Save files
          </v-btn>
          <v-btn
            color="primary"
            class="mr-4"
            @click="setData(1)"
            :loading="isLoading"
          >
            Save and exit
          </v-btn>
        </v-form>
      </v-col>
    </v-row>
    <template v-if="deleteDialog">
      <delete-dialog
      :showModal="deleteDialog"
      @closed="closeDeleteDialog"
      />
    </template>
    <notification
      :error="error"
      :notification="notification"
      :message="message"
      @closed="closed"
    />
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import * as Loads from '@/services/loads';
import * as users from '@/services/users';
import Notification from '@/components/general/Notification.vue';
import DeleteDialog from '@/components/dialog/Delete.vue';
import * as constants from '@/store/constants';

export default {
  components: {
    Notification,
    DeleteDialog,
  },
  data: () => ({
    email: '',
    pod: '',
    cc: '',
    deleteDialog: false,
    fileToDelete: null,
    showFiles: {
      rate_con: null,
      proof_delivery: null,
      scale_ticket: null,
      bill_of_loading: null,
      lumper_recepit: null,
      other: null,
      POD: '',
      CC: '',
      stateId: null,
      email_to_factoring: '',
      loadId: 0,
    },
    files: {
      rate_con: null,
      proof_delivery: null,
      scale_ticket: null,
      bill_of_loading: null,
      lumper_recepit: null,
      other: null,
      POD: '',
      CC: '',
      stateId: null,
      email_to_factoring: '',
      loadId: 0,
    },
    required: [
      (v) => !!v || 'Is required',
    ],
    emailRules: [
      (v) => !!v || 'E-mail is required',
      (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
    ],
    validateForm: false,
    message: '',
    isLoading: false,
    error: false,
    notification: false,
    isLoadingEmail: false,
  }),
  watch: {
    loadMap() {
      if (this.loadMap && this.isLoading) {
        this.getDocuments();
        this.notification = true;
        this.message = 'Files Saved';
        this.isLoading = false;
      }
    },
  },
  computed: {
    ...mapState({
      loadId: (state) => state.Loads.load?.id,
      load: (state) => state.Loads.load,
      document: (state) => state.Loads.load?.documentId,
      accesorialsIncome: (state) => state.Loads.accesorialsIncome,
      loadMap: (state) => state.Loads.loadMap,
    }),
    totalIncome() {
      return this.accesorialsIncome.map((item) => item.total)
        .reduce((pre, curr) => pre + curr, 0);
    },
  },
  methods: {
    ...mapActions({
      chargeLoad: constants.LOADS_GET_LOAD,
    }),
    validate() {
      this.$refs.form.validate();
      setTimeout(() => {
        this.sendEmail();
      }, 500);
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    async setData(option) {
      this.isLoading = true;
      this.error = false;
      const files = new FormData();
      const arrayFiles = Object.entries(this.files);
      arrayFiles.forEach((file) => {
        const [property, document] = file;
        if (document) {
          files.append(`${property}`, document);
        }
      });
      files.append('loadId', this.loadId);
      files.append('stateId', 1);
      files.append('documentId', this.showFiles?.id);
      files.append('updateFiles', JSON.stringify(this.showFiles));
      try {
        await Loads.saveFiles(files);
        this.chargeLoad(this.loadId);
        if (option === 1) {
          setTimeout(() => {
            this.$router.push({ path: '/loads' });
          }, 5000);
        }
      } catch (error) {
        this.message = error.response.data.message;
        this.error = true;
        this.notification = true;
      }
    },
    closed() {
      this.notification = false;
      this.message = '';
      this.error = false;
    },
    async getDocuments() {
      const user = await users.getOneUser(6);
      if (this.document.length > 0) {
        // eslint-disable-next-line
        this.showFiles = this.document[0];
      }
      this.email = user.email;
      this.pod = user.name;
      this.cc = user.password;
    },
    async deleteFile(key, fileName) {
      this.isLoading = true;
      this.error = false;
      const copyFiles = { ...this.showFiles };
      copyFiles[key] = '';
      const data = {
        files: copyFiles,
        deleteFile: fileName,
      };
      delete data?.id;
      try {
        await Loads.deleteFile(this.showFiles.id, data);
        this.showFiles[key] = '';
        this.isLoading = false;
        this.notification = true;
        this.message = 'File deleted';
      } catch (error) {
        this.message = error.response.data.message;
        this.error = true;
        this.notification = true;
      }
    },
    openDeleteDialog(file) {
      this.fileToDelete = file;
      this.deleteDialog = true;
    },
    closeDeleteDialog(data) {
      if (data) {
        const [[property, value]] = Object.entries(this.fileToDelete);
        this.deleteFile(property, value);
      }
      this.deleteDialog = false;
    },
    async sendEmail() {
      if (!this.validateForm) return;
      this.isLoadingEmail = true;
      const info = {};
      info.income = this.totalIncome;
      info.loadId = this.loadId;
      info.documents = [];
      info.broker = this.load.brokerId.company;
      info.reference = this.load.reference;
      info.email = this.email;
      info.ccEmails = `${this.cc}, ${this.pod}`;
      info.subject = `${info.loadId} ${info.broker} Load # ${info.reference} - $ ${info.income}`;

      const arrayFiles = Object.entries(this.document[0]);
      arrayFiles.forEach((file) => {
        const [property, document] = file;
        const data = {};
        if (document.length > 30) {
          data.path = document;
          data.filename = `${info.subject}-${property}.pdf`;
          data.cid = `${new Date()}`;
          info.documents.push(data);
        }
      });
      if (info.documents.length <= 0) {
        this.error = true;
        this.notification = true;
        this.message = 'no files to send';
        this.isLoadingEmail = false;
        return;
      }
      try {
        await Loads.sendEmail(info);
        this.notification = true;
        this.message = 'e-mail sent';
      } catch (error) {
        this.message = error.response.data.message;
        this.error = true;
        this.notification = true;
      }
      this.isLoadingEmail = false;
    },
  },
  created() {
    this.getDocuments();
  },
};
</script>
