<template>
  <div class="drivers">
    <div class="h2-title">Reporting</div>
    <bread-crumbs />
    <v-card class="rounded-lg b-shadow pa-5 mt-5" v-if="loadingInitData">
      <v-row>
        <v-col md="6" cols="12">
          <v-skeleton-loader
            type="article, list-item-two-line"
          ></v-skeleton-loader>
        </v-col>
        <v-col md="6" cols="12">
          <v-skeleton-loader
            type="article, list-item-two-line"
          ></v-skeleton-loader>
        </v-col>
        <v-col md="6" cols="12">
          <v-skeleton-loader
            type="article, list-item-two-line"
          ></v-skeleton-loader>
        </v-col>
        <v-col md="6" cols="12">
          <v-skeleton-loader
            type="article, list-item-two-line"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
    </v-card>
    <v-card v-else class="rounded-lg b-shadow pa-5 mt-5">
      <div class="mb-10 display-1">Reporting</div>
      <div class="mt-5 mb-5">
        <v-form
          ref="form"
          lazy-validation
        >
        <v-row>
          <v-col lg="6" sm="12" class="py-0">
            <auto-complete
              label="Select Driver"
              :items="drivers"
              textItem="name"
              url="/driver"
              :multiple="true"
              :chips="true"
              @updateValue="updateDriverId"
            />
          </v-col>
          <v-col lg="6" sm="12" class="py-0">
            <auto-complete
              label="Select Broker"
              :items="brokers"
              textItem="company"
              url="/broker"
              :multiple="true"
              :chips="true"
              @updateValue="updateBrokerId"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col lg="6" sm="12" class="py-0">
            <auto-complete
              label="Select Tractor"
              :items="tractors"
              textItem="power_unit"
              url="/tractor"
              :multiple="true"
              :chips="true"
              @updateValue="updateTractorId"
            />
          </v-col>
          <v-col lg="6" sm="12" class="py-0">
            <auto-complete
              label="Select Trailer"
              :items="trailers"
              textItem="trailer_number"
              url="/broker"
              :multiple="true"
              :chips="true"
              @updateValue="updateTrailerId"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col class="py-0">
            <v-text-field
              v-model="startDate"
              outlined
              dense
              label="From"
              type="date"
            ></v-text-field>
          </v-col>
          <v-col class="py-0">
            <v-text-field
              v-model="endDate"
              outlined
              dense
              label="To"
              type="date"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="py-0">
            <v-autocomplete
              :items="loadState"
              v-model="loadStateIds"
              outlined
              dense
              label="Load State"
              required
              :return-object="false"
              multiple
              item-text="description"
              deletable-chips
              item-value="id"
              chips
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col lg="6" sm="12" class="py-0 d-flex">
            <v-btn @click="getLoadsReport" class="primary" :loading="isLoadingReport">
              <v-icon>mdi-magnify</v-icon>
              Search
            </v-btn>
            <export-excel
              :data="dataReport"
              :fields="json_fields"
              worksheet="Load"
              name="loads.xls">
              <v-btn color="orange" class="ml-5">
                <v-icon>mdi-file-export-outline</v-icon>
                Export Report
              </v-btn>
            </export-excel>
          </v-col>
        </v-row>
        </v-form>
      </div>
    </v-card>
    <v-card class="mt-5 rounded-lg b-shadow pa-5" v-if="dataReport.length > 0">
      <div class="d-flex justify-space-between">
        <v-col>
          <div class="d-flex justify-space-between">
            <div>
              <div>Total Income:</div>
              <div>Total Client Mileage: </div>
              <div>Client RPM: </div>
            </div>
            <div>
              <div>{{ totalIncome | formatPrice }}</div>
              <div>{{ totalClientMileage | fixedDecimal }}</div>
              <div>{{ totalRPM }}</div>
            </div>
          </div>
        </v-col>
        <v-col class="mx-5">
          <div class="d-flex justify-space-between">
            <div>
              <div>Total Deadhead:</div>
              <div>Total Mileage + DH:</div>
              <div>Client + DH RPM:</div>
            </div>
            <div>
              <div>{{ totalDeadhead | fixedDecimal }}</div>
              <div>{{ totalMillage | fixedDecimal }}</div>
              <div>{{ totalRpmToDH }}</div>
            </div>
          </div>
        </v-col>
        <v-col>
          <div class="d-flex justify-space-between">
            <div>
              <div>Total Expenses:</div>
              <div>Gross Profit:</div>
            </div>
            <div>
              <div>{{ totalExpense | formatPrice }}</div>
              <div>{{ totalGossProfit | formatPrice }}</div>
            </div>
          </div>
        </v-col>
      </div>
    </v-card>
    <v-card class="rounded-lg b-shadow pa-5 mt-5 mb-10" v-if="dataReport.length > 0">
      <div class="load-table">
        <div class="load-table-content">
          <v-data-table
            v-if="!loadingInitData"
            :calculate-widths="false"
            dense
            :headers="headers"
            :items="dataReport"
            :items-per-page="1000000"
            hide-default-footer
            class="text-body-1 font-weight-black"
          >
          <template v-slot:[`item.driverId.name`]="{ item }">
            <div v-if="item.driverId">
              {{ item.driverId.name.substring(0, 15) }}...
            </div>
          </template>
          <template v-slot:[`item.reference`]="{ item }">
            <div>
              {{ item.reference.substring(0, 10) }}
              <span v-if="item.reference.length >= 10">...</span>
            </div>
          </template>
          <template v-slot:[`item.brokerId`]="{ item }">
            <div v-if="item.brokerId">
              {{ item.brokerId.company.substring(0, 13) }}...
            </div>
          </template>
          <template v-slot:[`item.shipperId`]="{ item }">
            <div v-for="(row, o) in item.shipperId" :key="`a-${o}`">
                {{ o + 1 }}.
                {{ row.path}}
            </div>
          </template>
          <template v-slot:[`item.income`]="{ item }">
            <div v-if="item.accesorialId.length > 0">
              $ {{ calculateIncome(item).totalIncome }}
            </div>
          </template>
          <template v-slot:[`item.rpm`]="{ item }">
            <div v-if="item.accesorialId.length > 0">
              $ {{ calculateIncome(item).rpm }}
            </div>
          </template>
          <template v-slot:[`item.shipperId[0].pickup_start_date`]="{ item }">
            <div v-for="(row, f) in item.shipperId" :key="`b-${f}`">
              {{ row.pickup_start_date | formatDate(true, false) }}
            <span v-if="row.pickup_end_date">
              &ensp;to&ensp; {{ row.pickup_end_date | formatDate(true, false) }}
            </span>
            </div>
          </template>
          <template v-slot:[`item.consigneeId`]="{ item }">
              <div v-for="(row, i) in item.consigneeId" :key="`d-${i}`">
                {{ i + 1}}.
                {{ row.path}}
            </div>
          </template>
          <template v-slot:[`item.consigneeId[0].drop_start_date`]="{ item }">
            <div v-for="(row, e) in item.consigneeId" :key="`e-${e}`">
             {{ row.drop_start_date | formatDate(true, false) }}
             <span v-if="row.drop_end_date">
              &ensp;to&ensp; {{ row.drop_end_date | formatDate(true, false)}}
            </span>
            </div>
          </template>
          <template v-slot:[`item.stateLoadId.description`]="{ item }">
            <v-chip
              :class="item.stateLoadId.description "
              class="d-flex justify-center"
              dark
              small
              v-if="item.stateLoadId"
              :style="{width: 100 + '%'}"
            >
              {{ item.stateLoadId.description }}
            </v-chip>
          </template>
          <template v-slot:[`item.milles`]="{ item }">
            <div>
              {{ item.milles | fixedDecimal()}}
            </div>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon
              small
              class="mr-2"
              @click="editLoad(item)"
            >
              mdi-pencil-outline
            </v-icon>
          </template>
          </v-data-table>
        </div>
      </div>
    </v-card>
  </div>
</template>
<script>
import BreadCrumbs from '@/components/general/BreadCrumbs.vue';
import * as Drivers from '@/services/drivers';
import * as Brokers from '@/services/brokers';
import * as Loads from '@/services/loads';
import * as Tractor from '@/services/tractors';
import * as Trailer from '@/services/trailers';
import AutoComplete from '@/components/general/AutoComplete.vue';
import moment from 'moment';

export default {
  components: {
    BreadCrumbs,
    AutoComplete,
  },
  data: () => ({
    totalIncome: 0,
    totalExpense: 0,
    totalGossProfit: 0,
    totalClientMileage: 0,
    totalDeadhead: 0,
    totalMillage: 0,
    totalRPM: 0,
    totalRpmToDH: 0,
    dataReport: [],
    loadState: [],
    loadStateIds: [],
    brokers: [],
    brokersIds: [],
    drivers: [],
    tractors: [],
    trailers: [],
    tractorsIds: [],
    trailersIds: [],
    driversIds: [],
    startDate: null,
    endDate: null,
    loadingInitData: false,
    isLoadingReport: false,
    headers: [
      { text: 'Actions', value: 'actions', sortable: false },
      { text: 'Load ID', value: 'id' },
      { text: 'Reference', value: 'reference' },
      { text: 'Load Status', value: 'stateLoadId.description', sortable: true },
      { text: 'Customers', value: 'brokerId' },
      { text: 'Picks', value: 'shipperId' },
      { text: 'Pick Date', value: 'shipperId[0].pickup_start_date' },
      { text: 'Drops', value: 'consigneeId' },
      { text: 'Drop Date', value: 'consigneeId[0].drop_start_date' },
      { text: 'Driver', value: 'driverId.name' },
      { text: 'Tractor P.U', value: 'tractorId.power_unit' },
      { text: 'Trailer', value: 'trailerId.trailer_number' },
      { text: 'Income', value: 'income' },
      { text: 'RPM', value: 'rpm' },
      { text: 'Distance', value: 'milles' },
    ],
    /* eslint-disable */
    json_fields: {
      'Load ID': 'id',
      'Reference': 'reference',
      'Load Status': 'stateLoadId.description',
      'Broker': 'brokerId.company',
      'Driver': 'driverId.name',
      'Tractor': 'tractorId.power_unit',
      'Trailer': 'trailerId.trailer_number',
      'Picks': {
        field: 'shipperId',
        callback: (value) => {
          let text = '';
          value.forEach((data, i) => text += `${i+1}. ${data.path} \n`);
          return text;
        },
      },
      'Pick Date': {
        field: 'shipperId',
        callback: (value) => {
          let text = '';
          value.forEach((data) =>{
            const newDate = moment(data.pickup_start_date, 'YYYY-MM-DD').format('MM/DD/YYYY');
            text += newDate + `\n`;
          });
          return text;
        },
      },
      'Drops': {
        field: 'consigneeId',
        callback: (value) => {
          let text = '';
          value.forEach((data, i) => text += `${i+1}. ${data.path} \n`);
          return text;
        },
      },
      'Drop Date': {
        field: 'consigneeId',
        callback: (value) => {
          let text = '';
          value.forEach((data) => {
            const newDate = moment(data.drop_start_date, 'YYYY-MM-DD').format('MM/DD/YYYY');
            text += newDate + `\n`;
          });

          return text ;
        },
      },
      'Income': {
        field: 'accesorialId',
        callback: (value) => {
          let total = 0;
          value.map((data) => {
            if (data.accesorialTypeId.id === 1) {
              total += data.total;
            }
          });
          return total;
        },
      },
      'Expense': {
        field: 'accesorialId',
        callback: (value) => {
          let total = 0;
          value.map((data) => {
            if (data.accesorialTypeId.id === 2) {
              total += data.total;
            }
          });
          return total;
        },
      },
      'Gross Profit/Loss': {
        callback: (load) => {
          let totalIncome = 0;
          let totalExpense = 0;
          const { accesorialId } = load;
          accesorialId.map((data) => {
            if (data.accesorialTypeId.id === 1) {
              totalIncome += data.total;
            }
            if (data.accesorialTypeId.id === 2) {
              totalExpense += data.total;
            }
          });
          return totalIncome - totalExpense;
        },
      },
      'Gross Profit/Loss %': {
        callback: (load) => {
          let totalIncome = 0;
          let totalExpense = 0;
          const { accesorialId } = load;
          accesorialId.map((data) => {
            if (data.accesorialTypeId.id === 1) {
              totalIncome += data.total;
            }
            if (data.accesorialTypeId.id === 2) {
              totalExpense += data.total;
            }
          });
          const value = (((totalIncome - totalExpense) / totalIncome) * 100).toFixed(2);
          return `${value}%`;
        },
      },
      'RPM': {
        callback: (load) => {
          let totalIncome = 0;
          const { accesorialId, milles } = load;
          if (accesorialId.length === 0) return;
          accesorialId.forEach((acc) => {
            if (acc.accesorialTypeId.id === 1) {

              totalIncome = acc.total;
            }
          });
          return (totalIncome / milles).toFixed(2);
        },
      },
      'Distance': 'milles',
      'Dead Head Address': 'dead_head_address',
      'Dead Head Milles': 'dead_head_milles',
      'Billed Date': 'billed_date',
    },
  }),
  methods: {
    editLoad(load) {
      const to = { name: 'edit load', params: { id: load.id } };
      this.$router.push(to);
    },
    getPrincipalData() {
      this.loadingInitData = true;
      Promise.all([
        Loads.getLoadsTypes(),
        Drivers.getDrivers(),
        Brokers.getBrokers(),
        Tractor.getTractors(),
        Trailer.getTrailers(),
      ])
        .then(([loadState, drivers, brokers, tractors, trailers]) => {
          this.loadState = loadState;
          this.drivers = drivers.data;
          this.brokers = brokers.data;
          this.tractors = tractors.data;
          this.trailers = trailers.data;
          this.loadingInitData = false;
        });
    },
    async getLoadsReport() {
      this.resetData();
      this.isLoadingReport = true;
      /* eslint-disable */
      const data = await Loads
        .getLoadsReport(this.loadStateIds, this.driversIds, this.brokersIds,
        this.tractorsIds, this.trailersIds, this.startDate, this.endDate);
      this.dataReport = data.data;

      this.dataReport = this.dataReport.filter((load)=> load.shipperId.length > 0)
      this.dataReport.forEach((load) => {
        this.totalDeadhead += Number(load.dead_head_milles);
        this.totalMillage += Number(load.milles);
        load.accesorialId.forEach((acc) => {
          if (acc.accesorialTypeId.id === 2) {
            this.totalExpense += acc.total;
          }
          if (acc.accesorialTypeId.id === 1) {
            this.totalIncome = this.totalIncome + acc.total;
        }
        });
      });
      this.totalClientMileage = this.totalMillage - this.totalDeadhead;
      this.totalRPM = (this.totalIncome / this.totalClientMileage).toFixed(2);
      this.totalRpmToDH = (this.totalIncome / this.totalMillage).toFixed(2);
      this.totalGossProfit = this.totalIncome - this.totalExpense;
      this.isLoadingReport = false;
    },
    updateDriverId(value) {
      this.driversIds = value;
    },
    updateBrokerId(value) {
      this.brokersIds = value;
    },
    updateTractorId(value) {
      this.tractorsIds = value;
    },
    updateTrailerId(value) {
      this.trailersIds = value;
    },
    calculateIncome(load) {
      let totalIncome = 0;
      let rpm = 0;
      const { accesorialId, milles } = load;
      if (accesorialId.length === 0) return;
      accesorialId.forEach((acc) => {
        if (acc.accesorialTypeId.id === 1) {
          totalIncome = acc.total;
        }
      });
      rpm = totalIncome / milles;
      // eslint-disable-next-line
      return {
        totalIncome,
        rpm: rpm.toFixed(2),
      };
    },
    resetData() {
      this.totalDeadhead = 0;
      this.totalMillage = 0;
      this.totalExpense = 0;
      this.totalIncome = 0;
    }
  },
  created() {
    this.getPrincipalData();
  },
};
</script>



