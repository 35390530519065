<template>
  <div class="billing">
    <v-btn-toggle mandatory class="mt-5">
      <v-btn @click="getLoads(0)" class="px-5">
        Pending
      </v-btn>
      <v-btn @click="getLoads(1)">
        Paid
      </v-btn>
    </v-btn-toggle>
    <v-card class="mb-10">
      <v-card-title class="d-flex flex-column align-stretch">
        <v-row>
          <v-spacer></v-spacer>
          <v-btn v-if="loadSelected.length > 0" text class="mx-3"
            color="primary"
            @click="sendPdf"
            :loading="isLoadingSendPdf"
          >
            <v-icon small>mdi-email-outline</v-icon>
            Send email
          </v-btn>
          <v-btn v-if="loadSelected.length > 0" text class="mx-3"
            color="primary"
            @click="payDraiver"
            >
            <v-icon small>mdi-check</v-icon>
            {{ typeLoad ? 'Pending' : 'Paid' }}
          </v-btn>
        </v-row>
        <v-row>
        <v-col cols="3">
          <v-text-field
            v-model="pickup_start_date"
            :label="'Start'"
            outlined
            dense
            type="date"
        ></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-text-field
            v-model="pickup_end_date"
            :label="'End'"
            outlined
            dense
            type="date"
        ></v-text-field>
        </v-col>
        <v-col cols="2">
          <v-btn color="primary"
          @click="getLoads(0)">
          Submit
        </v-btn>
        </v-col>
        <v-col>
          <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          dense
          label="Search"
          single-line
          hide-details
        ></v-text-field>
        </v-col>
      </v-row>
      </v-card-title>
      <div class="load-table">
        <div class="load-table-content">
          <v-data-table
            v-if="!isLoading"
            dense
            :headers="headers"
            :search="search"
            :items="loads"
            show-select
            :single-select="false"
            :page.sync="page"
            :items-per-page="itemsPerPage"
            hide-default-footer
            @page-count="pageCount = $event"
            v-model="loadSelected"
            class="text-body-1 font-weight-black"
          >
          <template v-slot:[`item.driver_payment`]="{ item }">
            <div v-if="item.driver_payment === '1'">
              Paid
            </div>
            <div v-else>
              Pending
            </div>
          </template>
          <template v-slot:[`item.expenses`]="{ item }">
            <div v-if="item.accesorialId.length > 0">
              {{ calculateExpense(item).totalExpense| fixedDecimal }} -
              {{ calculateExpense(item).totalDeduction | fixedDecimal}} =
              {{ calculateExpense(item).total | formatPrice}}
            </div>
          </template>
          <template v-slot:[`item.driverId.name`]="{ item }">
            <div v-if="item.driverId">
              {{ item.driverId.name.substring(0, 15) }}...
            </div>
          </template>
          <template v-slot:[`item.reference`]="{ item }">
            <div>
              {{ item.reference.substring(0, 10) }}
              <span v-if="item.reference.length >= 10">...</span>
            </div>
          </template>
          <template v-slot:[`item.brokerId`]="{ item }">
            <div v-if="item.brokerId">
              {{ item.brokerId.company.substring(0, 13) }}...
            </div>
          </template>
          <template v-slot:[`item.shipperId`]="{ item }">
            <div v-for="(row, o) in item.shipperId" :key="`a-${o}`">
                {{ o + 1 }}.
                {{ row.path}}
            </div>
          </template>
          <template v-slot:[`item.income`]="{ item }">
            <div v-if="item.accesorialId.length > 0">
              {{ calculateIncome(item).totalIncome | formatPrice }}
            </div>
          </template>
          <template v-slot:[`item.rpm`]="{ item }">
            <div v-if="item.accesorialId.length > 0">
              $ {{ calculateIncome(item).rpm }}
            </div>
          </template>
          <template v-slot:[`item.shipperId[0].pickup_start_date`]="{ item }">
            <div v-for="(row, f) in item.shipperId" :key="`b-${f}`">
              {{ row.pickup_start_date | formatDate(true, false) }}
            <span v-if="row.pickup_end_date">
              &ensp;to&ensp; {{ row.pickup_end_date | formatDate(true, false) }}
            </span>
            </div>
          </template>
          <template v-slot:[`item.consigneeId`]="{ item }">
              <div v-for="(row, i) in item.consigneeId" :key="`d-${i}`">
                {{ i + 1}}.
                {{ row.path}}
            </div>
          </template>
          <template v-slot:[`item.consigneeId[0].drop_start_date`]="{ item }">
            <div v-for="(row, e) in item.consigneeId" :key="`e-${e}`">
             {{ row.drop_start_date | formatDate(true, false) }}
             <span v-if="row.drop_end_date">
              &ensp;to&ensp; {{ row.drop_end_date | formatDate(true, false)}}
            </span>
            </div>
          </template>
          <template v-slot:[`item.milles`]="{ item }">
            <div>
              {{ item.milles | fixedDecimal}}
            </div>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon
              small
              class="mr-2"
              @click="editLoad(item)"
            >
              mdi-pencil-outline
            </v-icon>
          </template>
          <template v-slot:[`item.stateLoadId.description`]="{ item }">
            <v-chip
              :class="item.stateLoadId.description "
              class="d-flex justify-center"
              dark
              small
              v-if="item.stateLoadId"
              :style="{width: 100 + '%'}"
            >
              {{ item.stateLoadId.description }}
            </v-chip>
          </template>
          </v-data-table>
        </div>
      </div>
      <loading-table v-if="isLoading" />
      <div class="text-center my-4 pb-3">
        <v-pagination
          v-model="page"
          :length="pageCount"
        ></v-pagination>
      </div>
      <div class="pdf-container">
        <template v-for="(loads, x) in loadsToGeneratePDF">
            <div class="page-pdf" :class="`page-pdf-${x}`" ref="pagePdf" :key="x">
              <div class="header">
                <div class="logo">
                  <img src="@/assets/img/milam.png" alt="logo"/>
                  <div><b>MILAM TRANSPORT, LLC</b></div>
                  <div>TAMPA, FL 33646</div>
                  <div>PO BOX 40783</div>
                  <div><b>Phone:</b> 1-888-433-0331</div>
                  <div><b>Fax:</b> 813-315-6260</div>
                </div>
                <div class="driver mt-10">
                  <div><b>SETTLEMENT REPORT</b></div>
                  <div>
                    <b>Driver: </b>
                    <span v-if="loads.name">{{ loads.name }}</span>
                  </div>
                  <div>Date {{ new Date()  | formatDate }}</div>
                </div>
              </div>
              <div class="subtitle mt-8">Settlement Items:</div>
              <hr class="mb-8" />
              <table>
                <thead>
                  <th>Source</th>
                  <th>Payroll Date</th>
                  <th>Pick</th>
                  <th>Drop</th>
                  <th>Description</th>
                  <th>Rate</th>
                  <th>Quantity</th>
                  <th>Amount</th>
                </thead>
                <tbody>
                  <template v-for="(load, x) in loads.loads">
                    <tr :key="x">
                      <td>Load#{{ load.id}}</td>
                      <td>{{ new Date() | formatDate }}</td>
                      <td v-if="load.shipperId.length > 0">
                        <template v-for="(shipper, i) in load.shipperId">
                          <div :key="i">
                            {{ shipper.pickup_start_date | formatDate }}
                            <hr>
                            {{ shipper.path }}
                          </div>
                        </template>
                      </td>
                      <td v-else></td>
                      <td v-if="load.consigneeId.length > 0">
                        <template v-for="(consig, l) in load.consigneeId">
                          <div :key="l">
                            {{ consig.drop_start_date | formatDate }}
                            <hr>
                            {{ consig.path }}
                          </div>
                        </template>
                      </td>
                      <td v-else></td>
                      <td></td>
                      <td v-if="load.driverId">${{ load.driverId.rate }}</td>
                      <td>{{ load.milles | fixedDecimal }}</td>
                      <td class="text-right" v-if="load.driverId">
                        ${{ Number(load.driverId.rate * load.milles) | fixedDecimal }}
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
              <div class="mb-1 mt-8 subtitle">Deductions:</div>
              <table>
                <thead>
                  <th>Source</th>
                  <th>Description</th>
                  <th>Rate</th>
                  <th>Quantity</th>
                  <th>Amount</th>
                </thead>
                <tbody>
                <template v-for="(load, i) in loads.loads">
                  <template v-if="calculateExpense(load).totalDeduction > 0">
                    <tr v-for="(deduction , i) in load.accesorialId" :key="i">
                      <template v-if="deduction.accesorialTypeId.id === 3">
                        <td>Load #{{ load.id }}</td>
                        <td>{{ deduction.notes }}</td>
                        <td>${{ deduction.rate }}</td>
                        <td>{{ deduction.quantity | fixedDecimal}}</td>
                        <td class="text-right">${{ deduction.total | fixedDecimal}}</td>
                      </template>
                    </tr>
                  </template>
                  <template v-else>
                    <tr v-if="loads.loads.length === i + 1" :key="i+20">
                      <td></td>
                      <td></td>
                      <td>No Deductions</td>
                      <td></td>
                      <td></td>
                    </tr>
                  </template>
                  </template>
                </tbody>
              </table>
              <div class="mb-1 mt-8 subtitle"
              >Accesorial:</div>
              <template v-for="(load) in loads.loads">
                <template v-for="(acce , i) in load.accesorialId">
                  <template v-if="acce.accesorialTypeId.id === 2">
                    <div :key="i" v-if="acce.accesorialCategoryId">
                      <div v-if="acce.accesorialCategoryId.id !== 3"
                        class="d-flex justify-space-between"
                      >
                        <div>
                          {{ acce.notes }}
                        </div>
                        <div>
                          ${{ acce.total | fixedDecimal}}
                        </div>
                      </div>
                      <v-divider></v-divider>
                    </div>
                    <div v-else :key="i"></div>
                  </template>
                </template>
              </template>

              <div class="d-flex justify-space-between mt-5">
                <div class="subtitle">Total:</div>
                <div><b>${{ totalLoad(loads.loads) }}</b></div>
              </div>
            </div>
        </template>
      </div>
      <v-row class="pa-5">
        <v-btn v-if="loadSelected.length > 0"
          @click="generatePdf"
          color="orange"
          :loading="isLoadingPdf"
        >
          Show PDF
        </v-btn>
      </v-row>
    </v-card>
  </div>
</template>
<script>
import * as Loads from '@/services/loads';
import * as Mail from '@/services/mail';
import LoadingTable from '@/components/general/LoadingTable.vue';
import html2PDF from 'jspdf-html2canvas';

export default {
  components: {
    // DateTimePicker,
    LoadingTable,
  },
  data() {
    return {
      isLoadingPdf: false,
      isLoadingSendPdf: false,
      typeLoad: true,
      isLoading: false,
      loadSelected: [],
      pickup_start_date: '',
      pickup_end_date: '',
      loads: [],
      textFieldProps: {
        outlined: true,
        dense: true,
      },
      datetime: '',
      page: 1,
      pageCount: 5,
      itemsPerPage: 20,
      search: '',
      headers: [
        { text: 'Actions', value: 'actions', sortable: false },
        { text: 'Load ID', value: 'id' },
        { text: 'Pay Status', value: 'driver_payment' },
        { text: 'Load Status', value: 'stateLoadId.description', sortable: true },
        { text: 'Driver Name', value: 'driverId.name' },
        { text: 'Expenses', value: 'expenses' },
        { text: 'Reference', value: 'reference' },
        { text: 'Customers', value: 'brokerId' },
        { text: 'Picks', value: 'shipperId' },
        { text: 'Pick Date', value: 'shipperId[0].pickup_start_date' },
        { text: 'Drops', value: 'consigneeId' },
        { text: 'Drop Date', value: 'consigneeId[0].drop_start_date' },
        { text: 'Driver', value: 'driverId.name' },
        { text: 'Tractor P.U', value: 'tractorId.power_unit' },
        { text: 'Income', value: 'income' },
        { text: 'RPM', value: 'rpm' },
        { text: 'Distance', value: 'milles' },
      ],
      loadsToGeneratePDF: [],
    };
  },
  watch: {
    loadSelected() {
      const array1 = this.loadSelected;
      const array2 = this.loadSelected;
      const filter = [];

      array1.forEach((l1) => {
        const info = {
          ...l1.driverId,
          loads: array2.filter((l2) => l2.driverId.id === l1.driverId.id),
        };
        if (filter.length === 0) {
          filter.push(info);
        }
        const find = filter.filter((f) => f.loads.find((fl) => fl.id === l1.id));
        if (find.length === 0) filter.push(info);
      });
      this.loadsToGeneratePDF = filter;
    },
  },
  methods: {
    editLoad(load) {
      const to = { name: 'edit load', params: { id: load.id } };
      this.$router.push(to);
    },
    onDateTime(value) {
      this.datetime = value;
    },
    generatePdf() {
      this.isLoadingPdf = true;
      const page = document.getElementsByClassName('page-pdf');
      html2PDF(
        page,
        {
          jsPDF: {
            unit: 'px',
            format: 'a4',
          },
          imageType: 'image/jpeg',
          imageQuality: 1,
          output: '/generate.pdf',
          pagebreak: { mode: ['avoid-all'] },
          margin: {
            top: 30,
            right: 20,
            bottom: 20,
            left: 20,
          },
          init: (pdf) => {
            pdf.setFont('helvetica');
            pdf.setFontSize(30);
          },
          success: (pdf) => {
            pdf.output('dataurlnewwindow', { filename: 'reporte.pdf' });
            this.isLoadingPdf = false;
          },
        },
      );
    },
    async sendPdf() {
      this.isLoadingSendPdf = true;
      new Promise(() => {
        this.loadsToGeneratePDF.forEach(async (driver, i) => {
          const page = document.getElementsByClassName(`page-pdf-${i}`);
          Promise.all([
            html2PDF(
              page,
              {
                jsPDF: {
                  unit: 'px',
                  format: 'a4',
                },
                imageType: 'image/jpeg',
                imageQuality: 1,
                pagebreak: { mode: ['avoid-all'] },
                // output: '/generate.pdf',
                margin: {
                  top: 30,
                  right: 20,
                  bottom: 20,
                  left: 20,
                },
                init: (pdf) => {
                  pdf.setFont('helvetica');
                  pdf.setFontSize(30);
                },
                success: () => {
                },
              },
            ),
          ])
            .then(async ([pdf]) => {
              let objectFile = pdf.output('datauristring');
              /* eslint-disable */
              objectFile = objectFile.split('base64,')[1];
              const formData = new FormData();
              formData.append('file', objectFile);
              formData.append('email', driver.email);
              formData.append('name', driver.name);
              await Mail.sendPdf(formData);
              this.isLoadingSendPdf = false;
            });
        });
      })
        .catch((e) => {
          console.log('e: ', e);
        });
    },
    async getLoads(state) {
      this.typeLoad = state;
      this.isLoading = true;
      this.loads = [];
      const { data } = await Loads.getDriversPayment(state, this.pickup_start_date, this.pickup_end_date);
      this.isLoading = false;
      this.loads = data.filter((load)=> load.shipperId.length > 0);
    },
    async payDraiver() {
      try {
        const loads = this.loadSelected
          .map((load) => ({ id: load.id, driver_payment: !this.typeLoad }));
        await Loads.payDriver(loads);
        this.loadSelected = [];
        this.getLoads(this.typeLoad);
      } catch (error) {
        console.log('error: ', error);
      }
    },
    totalLoad(loads) {
      let totalExpense = 0;
      let totalDeduction = 0;
      let total = 0;
      loads.forEach((load) => {
        load.accesorialId.forEach((acc) => {
          if (acc.accesorialTypeId.id === 2) {
            totalExpense += acc.total;
          }
          if (acc.accesorialTypeId.id === 3) {
            totalDeduction += acc.total;
          }
        });
      });
      total = totalExpense - totalDeduction;
      return total.toFixed(2);
    },
    calculateExpense(load) {
      let totalExpense = 0;
      let totalDeduction = 0;
      let total = 0;
      const { accesorialId } = load;
      accesorialId.forEach((acc) => {
        if (acc.accesorialTypeId.id === 2) {
          totalExpense += acc.total;
        }
        if (acc.accesorialTypeId.id === 3) {
          totalDeduction += acc.total;
        }
      });
      total += totalExpense - totalDeduction;
      // eslint-disable-next-line
      return {
        totalExpense,
        totalDeduction,
        total,
      };
    },
    calculateIncome(load) {
      let totalIncome = 0;
      let rpm = 0;
      const { accesorialId, milles } = load;
      if (accesorialId.length === 0) return;
      accesorialId.forEach((acc) => {
        if (acc.accesorialTypeId.id === 1) {
          totalIncome = acc.total;
        }
      });
      rpm = totalIncome / milles;
      // eslint-disable-next-line
      return {
        totalIncome,
        rpm: rpm.toFixed(2),
      };
    },
  },
  created() {
    this.getLoads(0);
  },
};
</script>
