import httpClient from '@/config';

const token = localStorage.getItem('token');

const headers = {
  headers: {
    Authorization: `Bearer ${token}`,
  },
};

export const sendPdf = (pdf) => new Promise((resolve, reject) => {
//   headers.params = { statesLoad: states };
  httpClient.post(
    '/mail/sendPDF',
    pdf,
    headers,
  )
    .then((response) => {
      const { data } = response;
      resolve(data);
    })
    .catch((error) => reject(error));
});

export const x = (pdf) => new Promise((resolve, reject) => {
  httpClient.post(
    '/sendPDF',
    pdf,
    headers,
  )
    .then((response) => {
      const { data } = response;
      resolve(data);
    })
    .catch((error) => reject(error));
});
