<template>
  <v-form
    ref="form"
    v-model="valid"
    lazy-validation
  >
    <div contenteditable="true" class="content-sms mb-5" id="sms">
      <div>Hi {{load.driverId.name}}</div>
      <br>
      <div v-if="load.shipperId.length > 0">
        <template v-for="(shipper, i) in load.shipperId">
          <div :key="i">
            Pickup {{ i + 1 }}: <br><br>
            Pickup date and time: <br>
            {{ shipper.pickup_start_date | formatDate(!shipper.to_be_assigned ? true: false ) }}
            <span v-if="shipper.pickup_end_date">to</span>
            {{ shipper.pickup_end_date | formatDate(!shipper.to_be_assigned ? true: false ) }}
            <span v-if="shipper.to_be_assigned">Pending Appt</span>
            <br>
            Shipper Name: <br>
            {{ shipper.shipper_name}} <br>
            Location address: <br>
            {{ shipper.path }}
            <br>
            Shipper Contact: <br>
            {{ shipper.shipper_contact}} <br>
            Pickup #: {{ shipper.shipper_no}} <br>
            Pickup Note: <br>
            {{ shipper.shipper_note }}
            <br> <br>
          </div>
        </template>
      </div>
      <div v-if="load.consigneeId.length > 0">
        <template v-for="(consignee, i) in load.consigneeId">
          <div :key="i">
            Delivery {{ i + 1 }}: <br><br>
            Delivery date and time: <br>
            {{ consignee.drop_start_date | formatDate(!consignee.to_be_assigned ? true: false ) }}
            <span v-if="consignee.drop_end_date">to</span>
            {{ consignee.drop_end_date | formatDate(!consignee.to_be_assigned ? true: false ) }}
            <span v-if="consignee.to_be_assigned">Pending Appt</span>
            <br>
            Consignee Name: <br>
            {{ consignee.consignee_name}} <br>
            Location address: <br>
            {{ consignee.path }}
            <br>
            Consignee Contact: <br>
            {{ consignee.consignee_contact}} <br>
            Delivery #: {{ consignee.consignee_no}} <br>
            Delivery Note: <br>
            {{ consignee.consignee_note }}
            <br> <br>
          </div>
        </template>
      </div>
    </div>
    <v-btn
      :disabled="!valid"
      color="primary"
      class="mr-4"
      @click="sendSms"
    >
      Send SMS
    </v-btn>
    <notification
      :error="error"
      :notification="notification"
      :message="message"
      @closed="closed"
    />
  </v-form>
</template>
<script>
import { mapState } from 'vuex';
import Notification from '@/components/general/Notification.vue';
import * as Loads from '@/services/loads';

export default {
  components: {
    Notification,
  },
  data: () => ({
    valid: true,
    sms: '',
    message: '',
    error: false,
    notification: false,
  }),
  computed: {
    ...mapState({
      load: (state) => state.Loads.load,
    }),
  },
  methods: {
    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    async sendSms() {
      const sms = document.getElementById('sms');
      const formData = new FormData();
      formData.append('sms', sms.innerText);
      formData.append('phone', this.load.driverId.phone);
      try {
        await Loads.sendSMS(formData);
        this.error = false;
        this.notification = true;
        this.message = 'Message sent';
      } catch (error) {
        this.notification = true;
        this.message = 'Message error';
        this.error = true;
      }
    },
    closed() {
      this.notification = false;
      this.message = '';
      this.error = false;
    },
  },
};
</script>
