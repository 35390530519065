<template>
  <div class="">
    <v-card class="mt-5 rounded-lg b-shadow">
      <v-card-title>
        <v-btn color="primary"
          @click="$router.push({ name: ROUTES.BROKERS.CREATE})"
        >
          create brokers
        </v-btn>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        v-if="!isLoading"
        :headers="headers"
        :items="brokers"
        :search="search"
        :page.sync="page"
        :items-per-page="itemsPerPage"
        hide-default-footer
        class="text-body-1 font-weight-black"
      >
        <template v-slot:[`item.stateId.description`]="{ item }">
          <v-chip
            class="status-chip"
            :color="item.stateId.description === 'Active' ? 'green' : 'red'"
            dark
            small
          >
            {{ item.stateId.description }}
          </v-chip>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon
            small
            class="mr-2"
            @click="editBroker(item)"
          >
            mdi-pencil-outline
          </v-icon>
          <v-icon
            small
            @click="openDeleteDialog(item, item.id)"
          >
            mdi-trash-can-outline
          </v-icon>
        </template>
      </v-data-table>
      <template v-if="deleteDialog">
        <delete-dialog
        :showModal="deleteDialog"
        @closed="closeDeleteDialog"
        />
      </template>
      <loading-table
        v-if="isLoading"
      />
      <div class="text-center my-4 pb-3">
        <v-pagination
          v-model="page"
          :length="pageCount"
          total-visible="15"
        ></v-pagination>
      </div>
    </v-card>
  </div>
</template>
<script>
import { ROUTES } from '@/store/constants';
import * as Brokers from '@/services/brokers';
import LoadingTable from '@/components/general/LoadingTable.vue';
import DeleteDialog from '@/components/dialog/Delete.vue';

export default {
  name: 'Brokers',
  components: {
    LoadingTable,
    DeleteDialog,
  },
  data() {
    return {
      take: 10,
      skip: 0,
      deleteDialog: false,
      ROUTES,
      isLoading: false,
      page: 1,
      pageCount: 1,
      itemsPerPage: 10,
      search: '',
      brokers: [],
      broker: [],
      brokerId: 0,
      headers: [
        { text: 'Actions', value: 'actions', sortable: false },
        { text: 'Id', value: 'id' },
        { text: 'Direct Customer', value: 'company' },
        { text: 'Address', value: 'address' },
        { text: 'Contact', value: 'contact' },
        { text: 'mc_number', value: 'mc_number' },
        { text: 'State', value: 'stateId.description' },
        { text: 'Type', value: 'brokerType.description' },

      ],
    };
  },
  watch: {
    page() {
      this.skip = (this.page - 1) * 10;
      this.getBrokers();
    },
    search() {
      this.skip = 0;
      this.getBrokers();
    },
  },
  methods: {
    async getBrokers() {
      this.isLoading = true;
      const response = await Brokers.getBrokers(this.take, this.skip, this.search);
      this.pageCount = Math.ceil(response.count / 10);
      this.brokers = response.data;
      this.isLoading = false;
    },
    editBroker(broker) {
      const to = { name: 'edit broker', params: { id: broker.id } };
      this.$router.push(to);
    },

    openDeleteDialog(broker, id) {
      this.broker = broker;
      this.brokerId = id;
      this.deleteDialog = true;
    },

    async closeDeleteDialog(close) {
      if (close) {
        this.broker.stateId = 3;
        this.broker.brokerType = 0;
        const result = await Brokers.deleteBroker(this.brokerId, this.broker);
        if (result) this.getBrokers();
      }
      this.deleteDialog = false;
    },
  },
  created() {
    this.getBrokers();
  },
};
</script>
