import httpClient from '@/config';

const token = localStorage.getItem('token');
const headers = {
  headers: {
    Authorization: `Bearer ${token}`,
  },
};

export const getDrivers = (take = 10, skip = 0, search = '') => new Promise((resolve, reject) => {
  headers.headers.take = take;
  headers.headers.skip = skip;
  headers.headers.search = search;
  httpClient.get(
    '/driver',
    headers,
  )
    .then((response) => {
      const { data } = response;
      resolve(data);
    })
    .catch((error) => reject(error));
});

export const createDriver = (driver) => new Promise((resolve, reject) => {
  httpClient.post(
    '/driver',
    driver,
    headers,
  )
    .then((response) => {
      console.log('response', response);
      resolve(response);
    })
    .catch((error) => reject(error));
});

export const getOneDriver = (id) => new Promise((resolve, reject) => {
  httpClient.get(
    `/driver/${id}`,
    headers,
  )
    .then((response) => {
      const { data } = response;
      console.log('response', response);
      resolve(data);
    })
    .catch((error) => reject(error));
});

export const updateDriver = (id, driver) => new Promise((resolve, reject) => {
  httpClient.put(
    `/driver/${id}`,
    driver,
    headers,
  )
    .then((response) => {
      const { data } = response;
      console.log('response', response);
      resolve(data);
    })
    .catch((error) => reject(error));
});

export const deleteDriver = (id, driver) => new Promise((resolve, reject) => {
  httpClient.put(
    `/driver/delete/${id}`,
    driver,
    headers,
  )
    .then((response) => {
      const { data } = response;
      console.log('response', response);
      resolve(data);
    })
    .catch((error) => reject(error));
});

export const deleteFile = (id, driver, fileToDelete) => new Promise((resolve, reject) => {
  httpClient.post(
    `/driver/deleteFile/${id}`,
    {
      driver,
      fileToDelete,
    },
    headers,
  )
    .then((response) => {
      const { data } = response;
      console.log('response', response);
      resolve(data);
    })
    .catch((error) => reject(error));
});
