import httpClient from '@/config';

const tokenUser = localStorage.getItem('token');
const headers = {
  headers: {
    Authorization: `Bearer ${tokenUser}`,
  },
};

export const login = (user) => new Promise((resolve, reject) => {
  httpClient.post('/auth/login', user)
    .then((response) => {
      const { data: { token } } = response;
      if (token) {
        resolve(token);
        localStorage.setItem('token', token);
      }
    })
    .catch((error) => reject(error));
});

export const createUser = (user) => new Promise((resolve, reject) => {
  httpClient.post(
    '/users',
    user,
    headers,
  )
    .then((response) => {
      console.log('response', response);
      resolve(response);
    })
    .catch((error) => reject(error));
});

export const getUsers = (take = 10, skip = 0, search = '') => new Promise((resolve, reject) => {
  headers.headers.take = take;
  headers.headers.skip = skip;
  headers.headers.search = search;
  httpClient.get(
    '/users',
    headers,
  )
    .then((response) => {
      const { data } = response;
      resolve(data);
    })
    .catch((error) => reject(error));
});

export const getOneUser = (id) => new Promise((resolve, reject) => {
  httpClient.get(
    `/users/${id}`,
    headers,
  )
    .then((response) => {
      const { data } = response;
      console.log('response', response);
      resolve(data);
    })
    .catch((error) => reject(error));
});

export const updateUser = (id, user) => new Promise((resolve, reject) => {
  httpClient.put(
    `/users/${id}`,
    user,
    headers,
  )
    .then((response) => {
      const { data } = response;
      console.log('response', response);
      resolve(data);
    })
    .catch((error) => reject(error));
});
