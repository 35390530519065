<template>
  <div>
    <v-data-table
      dense
      :headers="headers"
      :items="items"
      hide-default-footer
      class="text-body-1 font-weight-black"
    >
      <template v-slot:[`item.types`]="{ item }">
        <div v-if="item.accesorialTypeId">
          {{ item.accesorialTypeId.description }}
        </div>
      </template>
      <template v-slot:[`item.categories`]="{ item }">
        <div v-if="item.accesorialCategoryId">
          {{ item.accesorialCategoryId.description }}
        </div>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn text x-small class="text-center"
          @click="openAccesesorial('edit', item)"
        >
          <v-icon small>
            mdi-pencil-outline
          </v-icon>
        </v-btn>
        <v-btn text x-small class="text-center"
          @click="deleteAccesorial(item)"
        >
          <v-icon small>
            mdi-trash-can-outline
          </v-icon>
        </v-btn>
      </template>
      <template v-slot:footer v-if="items.length > 0">
        <div class="d-flex ">
          <div>Total: USD {{ total | fixedDecimal}}</div>
        </div>
      </template>
    </v-data-table>
    <v-divider class="mb-7"></v-divider>
    <template v-if="deleteDialog">
      <delete-dialog
      :showModal="deleteDialog"
      @closed="closeDeleteDialog"
      />
    </template>
  </div>
</template>
<script>
import { mapActions, mapMutations } from 'vuex';
import * as constants from '@/store/constants';
import DeleteDialog from '@/components/dialog/Delete.vue';

export default {
  name: 'TableAccesorials',
  components: {
    DeleteDialog,
  },
  props: {
    items: {
      type: Array,
    },
  },
  data() {
    return {
      deleteDialog: false,
      itemDelete: null,
      headers: [
        { text: 'Actions', value: 'actions', sortable: false },
        { text: 'Category', value: 'categories' },
        { text: 'Company', value: 'company' },
        { text: 'Notes', value: 'notes' },
        { text: 'Rate', value: 'rate' },
        { text: 'Quantity', value: 'quantity' },
        { text: 'Total', value: 'total' },
      ],
    };
  },
  computed: {
    total() {
      return this.items.map((item) => item.total)
        .reduce((pre, curr) => pre + curr, 0);
    },
  },
  methods: {
    ...mapMutations({
      loadSetProperty: constants.LOADS_SET_PROPERTY,
    }),
    ...mapActions({
      loadAddAccesorial: constants.LOADS_SET_ACCESORIAL,
    }),
    // type is actionType e.g: income = 1 etc
    openAccesesorial(action, data = '') {
      this.loadSetProperty({ accesorialDialog: true });
      this.loadSetProperty({ accesorialAction: action });
      this.loadSetProperty({ accesorialEdit: { ...data } });
    },
    deleteAccesorial(item) {
      this.itemDelete = item;
      this.deleteDialog = true;
    },
    closeDeleteDialog(value) {
      this.deleteDialog = false;
      if (value) {
        this.itemDelete.stateId = { id: 3 };
        this.loadAddAccesorial({ accesorial: this.itemDelete, action: 'delete' });
      }
    },
  },
};
</script>
