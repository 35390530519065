import httpClient from '@/config';

const token = localStorage.getItem('token');
const headers = {
  headers: {
    Authorization: `Bearer ${token}`,
  },
};

export const getBrokers = (take = 10, skip = 0, search = '') => new Promise((resolve, reject) => {
  headers.headers.take = take;
  headers.headers.skip = skip;
  headers.headers.search = search;
  httpClient.get(
    '/broker',
    headers,
  )
    .then((response) => {
      const { data } = response;
      resolve(data);
    })
    .catch((error) => reject(error));
});

export const getTypeBrokers = () => new Promise((resolve, reject) => {
  httpClient.get(
    '/type-broker',
    headers,
  )
    .then((response) => {
      const { data } = response;
      resolve(data);
    })
    .catch((error) => reject(error));
});

export const createBroker = (broker) => new Promise((resolve, reject) => {
  httpClient.post(
    '/broker',
    broker,
    headers,
  )
    .then((response) => {
      resolve(response);
    })
    .catch((error) => reject(error));
});

export const getOneBroker = (id) => new Promise((resolve, reject) => {
  httpClient.get(
    `/broker/${id}`,
    headers,
  )
    .then((response) => {
      const { data } = response;
      resolve(data);
    })
    .catch((error) => reject(error));
});

export const updateBroker = (id, broker) => new Promise((resolve, reject) => {
  httpClient.put(
    `/broker/${id}`,
    broker,
    headers,
  )
    .then((response) => {
      const { data } = response;
      resolve(data);
    })
    .catch((error) => reject(error));
});

export const deleteBroker = (id, broker) => new Promise((resolve, reject) => {
  httpClient.put(
    `/broker/delete/${id}`,
    broker,
    headers,
  )
    .then((response) => {
      const { data } = response;
      resolve(data);
    })
    .catch((error) => reject(error));
});
