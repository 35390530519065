<template>
  <gmap-autocomplete class="location-input"
    @place_changed="eventPlace"
    :placeholder="label"
    @input="inputValue = $event.target.value"
    :value="inputValue"
  />
</template>
<script>
export default {
  name: 'PlaceAutocomplete',
  props: {
    label: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      inputValue: this.value,
    };
  },
  watch: {
    value() {
      this.inputValue = this.value;
    },
    inputValue() {
      this.$emit('eventPlace', this.inputValue);
    },
  },
  methods: {
    eventPlace(place) {
      const value = place.formatted_address || place.name;
      this.$emit('eventPlace', value);
    },
  },
};
</script>
