<template>
  <v-row class="mb-10">
    <v-spacer></v-spacer>
    <v-col lg="6" sm="12" class="py-0 mt-5">
      <v-divider></v-divider>
      <v-row class="mt-5">
        <span class="ml-5">Total Income</span>
        <v-spacer></v-spacer>
        <span class="mr-5">USD {{ totalIncome }}</span>
      </v-row>
      <v-divider class="mt-5"></v-divider>
      <v-row class="mt-5">
        <span class="ml-5">Total Expenditures</span>
        <v-spacer></v-spacer>
        <span class="mr-5">USD {{ totalExpense }}</span>
      </v-row>
      <v-divider class="mt-5"></v-divider>
      <v-row class="mt-5">
        <span class="ml-5">Gross Profit/Loss</span>
        <v-spacer></v-spacer>
        <span class="mr-5">
          USD {{ totalExpediture }}
        </span>
      </v-row>
      <v-divider class="mt-5"></v-divider>
      <v-row class="mt-5">
        <span class="ml-5">Gross Profit/Loss Porcentage</span>
        <v-spacer></v-spacer>
        <span class="mr-5">
          {{ percent }}%
        </span>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
import { mapState } from 'vuex';

export default {
  data() {
    return {
    };
  },
  computed: {
    ...mapState({
      accesorialsIncome: (state) => state.Loads.accesorialsIncome,
      accesorialsExpense: (state) => state.Loads.accesorialsExpense,
      accesorialsDeductions: (state) => state.Loads.accesorialsDeductions,
    }),
    totalIncome() {
      return this.accesorialsIncome.map((item) => item.total)
        .reduce((pre, curr) => pre + curr, 0);
    },
    totalExpense() {
      const value = this.accesorialsExpense.map((item) => item.total)
        .reduce((pre, curr) => pre + curr, 0);
      return Number(value.toFixed(2));
    },
    percent() {
      let value = ((this.totalIncome - this.totalExpense) / this.totalIncome) * 100;
      value = Number(value.toFixed(2)) || 0;
      return value;
    },
    totalExpediture() {
      const value = this.totalIncome - this.totalExpense;
      return Number(value.toFixed(2)) || 0;
    },
  },
};
</script>
