<template>
  <v-row>
    <v-form
      ref="form"
      v-model="validateForm"
      lazy-validation
    >
      <v-row class="ma-0">
        <v-col class="py-0">
          <v-autocomplete
            label="Find Shipper Address"
            :items="array.places"
            v-model="findPath"
            item-text="name"
            :return-object="true"
            item-value="address"
            required
            outlined
            dense
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-row class="ma-0">
        <v-col class="py-0 ma-0">
          <v-text-field
            label="Shipper Name"
            v-model="shipper.shipper_name"
            required
            outlined
            dense
          ></v-text-field>
        </v-col>

        <v-col class="py-0">
          <v-text-field
            v-model="shipper.pickup_start_date"
            :label="!setDate ? 'Pickup Date' : 'Start Time'"
            outlined
            dense
            type="datetime-local"
          ></v-text-field>
          <v-text-field
            v-if="setDate"
            v-model="shipper.pickup_end_date"
            :label="'End Time'"
            outlined
            dense
            type="datetime-local"
          ></v-text-field>
          <!-- eslint-disable-next-line -->
          <div class="date-text"
            @click="setDate = !setDate"
          >Set a specific date/time</div>
          <div class="date-text">
            <v-checkbox
              v-model="shipper.to_be_assigned"
              label="To be assigned"
              color="red"
              hide-details
            ></v-checkbox>
          </div>
        </v-col>
      </v-row>
      <v-row class="ma-0">
        <v-col class="py-0">
          <place-autocomplete
            @eventPlace="eventPlace"
            label="Pickup Address"
            :required="true"
            :value="shipper.path"
          />
        </v-col>
      </v-row>
      <v-row class="ma-0 py-6">
        <v-col class="py-0 ma-0">
          <v-text-field
          label="Shipper Contact"
          v-model="shipper.shipper_contact"
          required
          outlined
          dense
          ></v-text-field>
        </v-col>
        <v-col class="py-0 ma-0">
          <v-text-field
            label="Pickup #"
            v-model="shipper.shipper_no"
            required
            outlined
            dense
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row class="ma-0">
        <v-col class="py-0 ma-0">
          <v-textarea
            label="Shipper Notes"
            v-model="shipper.shipper_note"
            outlined
            dense
            rows="2"
          ></v-textarea>
        </v-col>
      </v-row>
      <v-row class="ma-0 py-1">
        <v-col class="py-0 ma-0">
          <v-text-field
          label="Pickup Position"
            v-model="shipper.position"
            outlined
            dense
            :disabled="!shipper.path ? true: false"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn
            color="primary"
            @click="validate"
          >
            Add shipper
        </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-row>
</template>
<script>
import PlaceAutocomplete from '@/components/general/PlaceAutocomplete.vue';
import * as constants from '@/store/constants';
import { mapActions } from 'vuex';
import * as array from '@/db/places.json';

export default {
  name: 'ShipperDialog',
  components: {
    PlaceAutocomplete,
  },
  props: {
    data: {
      type: Object,
    },
  },
  data() {
    return {
      array,
      dialog: true,
      validateForm: false,
      setDate: false,
      findPath: {
        address: '',
        name: '',
      },
      shipper: {
        path: '',
        pickup_end_date: '',
        pickup_start_date: '',
        to_be_assigned: false,
        stateId: 1,
        shipper_name: '',
        position: '',
      },
      rules: {
        required: (v) => !!v || '*required',
        validateState: [
          (v) => v !== '' || '* state is required',
          (v) => v !== null || '* state is required',
        ],
      },
    };
  },
  watch: {
    data() {
      const d = new Date(this.data.pickup_start_date);
      const e = new Date(this.data.pickup_end_date);
      if (this.data) {
        this.shipper = this.data;
        if (this.shipper.pickup_start_date) this.shipper.pickup_start_date = `${d.getFullYear()}-${(`00${d.getMonth() + 1}`).slice(-2)}-${(`00${d.getDate()}`).slice(-2)}T${(`00${d.getHours()}`).slice(-2)}:${(`00${d.getMinutes()}`).slice(-2)}:${(`00${d.getSeconds()}`).slice(-2)}`;
        if (this.shipper.pickup_end_date) this.shipper.pickup_end_date = `${e.getFullYear()}-${(`00${e.getMonth() + 1}`).slice(-2)}-${(`00${e.getDate()}`).slice(-2)}T${(`00${e.getHours()}`).slice(-2)}:${(`00${e.getMinutes()}`).slice(-2)}:${(`00${e.getSeconds()}`).slice(-2)}`;
      }
    },
    findPath() {
      this.shipper.path = this.findPath.address;
      this.shipper.shipper_name = this.findPath.name;
    },
  },
  methods: {
    ...mapActions({
      loadAddShipper: constants.LOADS_ADD_SHIPPER,
    }),
    validate() {
      this.$refs.form.validate();
      setTimeout(() => {
        if (this.shipper.path) this.addShipper();
      }, 500);
    },
    addShipper() {
      if (!this.validateForm) return;
      this.shipper.stateId = 1;
      if (this.shipper.pickup_start_date) {
        this.shipper.pickup_start_date = new Date(this.shipper.pickup_start_date);
      }

      if (this.shipper.pickup_end_date) {
        this.shipper.pickup_end_date = new Date(this.shipper.pickup_end_date);
      }
      this.loadAddShipper(this.shipper);
      this.resetData();
    },
    resetData() {
      this.findPath = {
        address: '',
        name: '',
      };
      this.shipper = {
        path: '',
        pickup_end_date: '',
        pickup_start_date: '',
        to_be_assigned: false,
        stateId: 1,
        shipper_name: '',
      };
    },
    eventPlace(value) {
      this.shipper.path = value;
    },
    parseDataString(value) {
      if (value) return value.toString();
      return '';
    },
    closeDialog() {
      this.$emit('closed');
    },
  },
  created() {
    if (this.data) this.shipper = this.data;
  },
};
</script>
