import httpClient from '@/config';

const token = localStorage.getItem('token');

const headers = {
  headers: {
    Authorization: `Bearer ${token}`,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  },
};

export const getLoads = (take = 10, skip = 0, search = '', states = []) => new Promise((resolve, reject) => {
  headers.headers.take = take;
  headers.headers.skip = skip;
  headers.headers.search = search;
  headers.params = { statesLoad: states };
  httpClient.get(
    '/loads',
    headers,
  )
    .then((response) => {
      const { data } = response;
      resolve(data);
    })
    .catch((error) => reject(error));
});

export const createLoad = (load) => new Promise((resolve, reject) => {
  httpClient.post(
    '/loads',
    load,
    headers,
  )
    .then((response) => {
      resolve(response);
    })
    .catch((error) => reject(error));
});

export const getOneLoad = (id) => new Promise((resolve, reject) => {
  httpClient.get(
    `/loads/${id}`,
    headers,
  )
    .then((response) => {
      const { data } = response;
      console.log('data: ', { ...data });
      resolve(data);
    })
    .catch((error) => reject(error));
});

export const updateLoad = (id, load) => new Promise((resolve, reject) => {
  httpClient.put(
    `/loads/${id}`,
    load,
    headers,
  )
    .then((response) => {
      const { data } = response;
      resolve(data);
    })
    .catch((error) => reject(error));
});

export const deleteLoad = (id, load) => new Promise((resolve, reject) => {
  httpClient.put(
    `/loads/delete/${id}`,
    load,
    headers,
  )
    .then((response) => {
      const { data } = response;
      resolve(data);
    })
    .catch((error) => reject(error));
});

export const getLoadsTypes = () => new Promise((resolve, reject) => {
  httpClient.get(
    '/loads/types',
    headers,
  )
    .then((response) => {
      const { data } = response;
      resolve(data);
    })
    .catch((error) => reject(error));
});

export const getAccesorialTypes = () => new Promise((resolve, reject) => {
  httpClient.get(
    '/accesorial/types',
    headers,
  )
    .then((response) => {
      const { data } = response;
      resolve(data);
    })
    .catch((error) => reject(error));
});

export const getAccesorialCategories = () => new Promise((resolve, reject) => {
  httpClient.get(
    '/accesorial/categories',
    headers,
  )
    .then((response) => {
      const { data } = response;
      resolve(data);
    })
    .catch((error) => reject(error));
});

export const payDriver = (loads) => new Promise((resolve, reject) => {
  httpClient.post(
    '/loads/driverPayment',
    loads,
    headers,
  )
    .then((response) => {
      const { data } = response;
      resolve(data);
    })
    .catch((error) => reject(error));
});

export const makePayLoad = (loads) => new Promise((resolve, reject) => {
  httpClient.post(
    '/loads/makePayLoad',
    loads,
    headers,
  )
    .then((response) => {
      const { data } = response;
      resolve(data);
    })
    .catch((error) => reject(error));
});

export const getDriversPayment = (state, startDate, endDate) => new Promise((resolve, reject) => {
  headers.params = {
    startDate, endDate,
  };
  httpClient.get(
    `/loads/driversPayment/${state}`,
    headers,
  )
    .then((response) => {
      const { data } = response;
      resolve(data);
    })
    .catch((error) => reject(error));
});

export const saveFiles = (files) => new Promise((resolve, reject) => {
  httpClient.post(
    '/spaces',
    files,
    headers,
  )
    .then((response) => {
      const { data } = response;
      resolve(data);
    })
    .catch((error) => reject(error));
});

export const deleteFile = (documentId, files) => new Promise((resolve, reject) => {
  httpClient.post(
    `/spaces/delete/${documentId}`,
    files,
    headers,
  )
    .then((response) => {
      const { data } = response;
      resolve(data);
    })
    .catch((error) => reject(error));
});

export const sendEmail = (loadData) => new Promise((resolve, reject) => {
  httpClient.post(
    '/mail/documents',
    loadData,
    headers,
  )
    .then((response) => {
      const { data } = response;
      resolve(data);
    })
    .catch((error) => reject(error));
});

export const sendSMS = (sms) => new Promise((resolve, reject) => {
  httpClient.post(
    '/message',
    sms,
    headers,
  )
    .then((response) => {
      const { data } = response;
      resolve(data);
    })
    .catch((error) => reject(error));
});

export const getLoadsReport = (statesLoad = [], driverId = [], brokerId = [], tractorId = [], trailerId = [], startDate = '', endDate = '') => new
Promise((resolve, reject) => {
  headers.params = {
    statesLoad, driverId, brokerId, tractorId, trailerId, startDate, endDate,
  };
  httpClient.get(
    '/loads/loadReport',
    headers,
  )
    .then((response) => {
      const { data } = response;
      resolve(data);
    })
    .catch((error) => reject(error));
});
