import httpClient from '@/config';

let token = '';
let headers = {};

function getToken() {
  token = localStorage.getItem('token');
  headers = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}
getToken();

export const getTrailers = (take = 10, skip = 0, search = '') => new Promise((resolve, reject) => {
  headers.headers.take = take;
  headers.headers.skip = skip;
  headers.headers.search = search;
  httpClient.get(
    '/trailers',
    headers,
  )
    .then((response) => {
      const { data } = response;
      resolve(data);
    })
    .catch((error) => reject(error));
});

export const createTrailer = (trailer) => new Promise((resolve, reject) => {
  httpClient.post(
    '/trailers',
    trailer,
    headers,
  )
    .then((response) => {
      console.log('response', response);
      resolve(response);
    })
    .catch((error) => reject(error));
});

export const getOneTrailer = (id) => new Promise((resolve, reject) => {
  httpClient.get(
    `/trailers/${id}`,
    headers,
  )
    .then((response) => {
      const { data } = response;
      console.log('response', response);
      resolve(data);
    })
    .catch((error) => reject(error));
});

export const updateTriler = (id, trailer) => new Promise((resolve, reject) => {
  httpClient.put(
    `/trailers/${id}`,
    trailer,
    headers,
  )
    .then((response) => {
      const { data } = response;
      console.log('response', response);
      resolve(data);
    })
    .catch((error) => reject(error));
});

export const deleteTrailer = (id, trailer) => new Promise((resolve, reject) => {
  httpClient.put(
    `/trailers/delete/${id}`,
    trailer,
    headers,
  )
    .then((response) => {
      const { data } = response;
      console.log('response', response);
      resolve(data);
    })
    .catch((error) => reject(error));
});
