<template>
  <v-card class="rounded-lg b-shadow pa-5 mt-5 mb-16">
    <div class="mb-10 display-1">{{ formTitle }}</div>
    <v-form
      ref="form"
      v-model="validateForm"
      lazy-validation
    >
      <v-row>
        <v-col lg="4" sm="12" class="py-0">
          <v-text-field
            v-model="driver.name"
            label="Full Name"
            required
            outlined
            dense
            :rules="[rules.required]"
          ></v-text-field>
        </v-col>
        <v-col lg="4" sm="12" class="py-0">
          <v-text-field
            v-model="driver.dob"
            label="DOB"
            required
            outlined
            dense
            type="date"
            :rules="[rules.required]"
          ></v-text-field>
        </v-col>
        <v-col lg="4" sm="12" class="py-0">
          <v-text-field
            v-model="driver.social_security"
            label="Social Security"
            outlined
            dense
            :rules="[rules.required]"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col lg="6" sm="12" class="py-0">
          <v-text-field
            v-model="driver.hire_date"
            label="Hire Date"
            required
            outlined
            dense
            type="date"
            :rules="[rules.required]"
          ></v-text-field>
        </v-col>
        <v-col lg="6" sm="12" class="py-0">
          <v-text-field
            v-model="driver.contrat_expire"
            label="Termination Date"
            outlined
            dense
            type="date"
          ></v-text-field>
        </v-col>
        <v-col lg="6" sm="12" class="py-0">
          <v-text-field
            v-model="driver.phone"
            label="Contact Number"
            required
            outlined
            dense
            :rules="[rules.required]"
          ></v-text-field>
        </v-col>
        <v-col lg="6" sm="12" class="py-0">
          <v-text-field
            v-model="driver.emp_id"
            label="Employee ID"
            required
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col lg="6" sm="12" class="py-0">
          <v-text-field
            v-model="driver.rate"
            label="Rate per Mile"
            type="number"
            required
            outlined
            dense
            :rules="[rules.required]"
          ></v-text-field>
        </v-col>
        <v-col lg="6" sm="12" class="py-0">
          <v-text-field
            v-model="driver.email"
            label="Email"
            required
            outlined
            dense
            :rules="[rules.validateEmail]"
          ></v-text-field>
        </v-col>
        <v-col lg="6" sm="12" class="py-0">
          <v-text-field
            v-model="driver.license_no"
            label="License No."
            required
            outlined
            dense
            :rules="[rules.required]"
          ></v-text-field>
        </v-col>
        <v-col lg="6" sm="12" class="py-0">
          <v-autocomplete
            required
            outlined
            v-model="driver.stateId"
            :items="state"
            label="Status"
            dense
            :return-object="true"
            item-text="description"
            :rules="rules.validateState"
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="py-0">
          <v-textarea
            v-model="driver.emergency_contact"
            label="Emergency Contact Details"
            outlined
            rows="2"
            dense
            :rules="[rules.required]"
          ></v-textarea>
        </v-col>
      </v-row>
      <v-row>
        <v-col lg="6" sm="12">
          <v-toolbar flat>
            <v-toolbar-title>Driver License</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
          </v-toolbar>
            <div v-if="driver.url_license_img">
              <v-btn text
                class="circular-btn"
                :href="driver.url_license_img"
                target="_blank"
              >
                <v-icon small
                >
                  mdi-eye
                </v-icon>
              </v-btn>
              <v-btn text class="circular-btn"
                @click="openDeleteDialog({ url_license_img: driver.url_license_img })"
              >
                <v-icon small color="red">
                  mdi-trash-can-outline
                </v-icon>
              </v-btn>
            </div>
          <v-file-input
            v-model="files.license"
            :disabled="!!driver.url_license_img"
            class="mb-6"
            accept="image/png, image/jpeg, image/bmp"
            placeholder="Driver License Image"
            prepend-icon="mdi-camera"
            label="Driver License Image"
          ></v-file-input>
          <v-text-field
            v-model="driver.license_expire"
            label="Expiration Date"
            required
            outlined
            dense
            type="date"
            :rules="[rules.required]"
          ></v-text-field>
          <v-text-field
            v-model="driver.license_issued"
            label="Issued Date"
            required
            outlined
            dense
            type="date"
            :rules="[rules.required]"
          ></v-text-field>
        </v-col>
        <v-col
          lg="6"
          sm="12"
        >
          <v-toolbar flat>
            <v-toolbar-title >Medical Card</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
          </v-toolbar>
            <div v-if="driver.url_medical_img">
              <v-btn text
                class="circular-btn"
                :href="driver.url_medical_img"
                target="_blank"
              >
                <v-icon small
                >
                  mdi-eye
                </v-icon>
              </v-btn>
              <v-btn text class="circular-btn"
                @click="openDeleteDialog({ url_medical_img: driver.url_medical_img })"
              >
                <v-icon small color="red">
                  mdi-trash-can-outline
                </v-icon>
              </v-btn>
            </div>
          <v-file-input
            v-model="files.medical"
            :disabled="!!driver.url_medical_img"
            class="mb-6"
            accept="image/png, image/jpeg, image/bmp"
            placeholder="Medical Card Image"
            prepend-icon="mdi-camera"
            label="Medical Card Image"
          ></v-file-input>
          <v-text-field
            v-model="driver.medical_expire"
            label="Expiration Date"
            required
            outlined
            dense
            type="date"
            :rules="[rules.required]"
          ></v-text-field>
          <v-text-field
            v-model="driver.medical_issued"
            label="Issued Date"
            required
            outlined
            dense
            type="date"
            :rules="[rules.required]"
          ></v-text-field>
        </v-col>
      </v-row>
      <div class="text-center my-5">
        <v-btn width="140px" color="primary"
          :loading="isLoading"
          @click="validate"
        >Submit</v-btn>
      </div>
    </v-form>
    <template v-if="deleteDialog">
      <delete-dialog
      :showModal="deleteDialog"
      @closed="closeDeleteDialog"
      />
    </template>
    <notification
      :error="error"
      :notification="notification"
      :message="message"
      @closed="closed"
    />
  </v-card>
</template>
<script>
import * as Drivers from '@/services/drivers';
import * as state from '@/services/states';
import DeleteDialog from '@/components/dialog/Delete.vue';
import Notification from '@/components/general/Notification.vue';

export default {
  name: 'FromDriver',
  components: {
    Notification,
    DeleteDialog,
  },
  data() {
    return {
      validateForm: true,
      createDriverAction: true,
      files: {
        medical: null,
        license: null,
      },
      state: [],
      driver: {
        stateId: '',
        url_medical_img: '',
        url_license_img: '',
        email: '',
      },
      rules: {
        required: (v) => !!v || '*required',
        validateEmail: () => this.validateEmail || '* enter a valid email',
        validateState: [
          (v) => v !== '' || '* state is required',
          (v) => v !== null || '* state is required',
        ],
      },
      message: '',
      error: false,
      notification: false,
      deleteDialog: false,
      fileToDelete: null,
      isLoading: false,
    };
  },
  computed: {
    formTitle() {
      const route = this.$route;
      return route.name;
    },
    validateEmail() {
      // eslint-disable-next-line
      const validate = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return validate.test(this.driver.email);
    },
  },
  methods: {
    validate() {
      this.$refs.form.validate();
      setTimeout(() => {
        this.setDriver();
      }, 500);
    },
    openDeleteDialog(file) {
      this.fileToDelete = file;
      this.deleteDialog = true;
    },
    closeDeleteDialog(data) {
      if (data) {
        const [[property, value]] = Object.entries(this.fileToDelete);
        this.deleteFile(property, value);
      }
      this.deleteDialog = false;
    },
    async sentFile() {
      const formData = new FormData();
      formData.append('url_medical_img', this.files.medical);
      formData.append('url_license_img', this.files.license);
      formData.append('driver', JSON.stringify(this.driver));
      await Drivers.createDriver(formData);
    },
    async setDriver() {
      if (!this.validateForm) return;
      const formData = new FormData();
      const driverCopy = { ...this.driver };
      this.isLoading = true;
      this.error = false;
      delete driverCopy.id;
      delete driverCopy.createAt;
      Object.assign(driverCopy, {
        stateId: this.driver?.stateId?.id,
        rate: Number(driverCopy.rate),
      });
      formData.append('url_medical_img', this.files.medical);
      formData.append('url_license_img', this.files.license);
      formData.append('driver', JSON.stringify(driverCopy));
      try {
        if (this.createDriverAction) {
          await Drivers.createDriver(formData);
          this.notification = true;
          this.message = 'Driver created';
          this.$refs.form.reset();
        } else {
          await Drivers.updateDriver(this.driverId, formData);
          this.notification = true;
          this.message = 'Driver updated';
        }
      } catch (error) {
        this.error = true;
        this.notification = true;
        this.message = error.response.data.message;
      }
      this.isLoading = false;
    },
    async deleteFile(key, fileToDelete) {
      this.isLoading = true;
      this.error = false;
      const driverCopy = { ...this.driver };
      driverCopy[key] = '';
      delete driverCopy.id;
      delete driverCopy.createAt;
      Object.assign(driverCopy, {
        stateId: this.driver?.stateId?.id,
        rate: Number(driverCopy.rate),
      });
      try {
        await Drivers.deleteFile(this.driverId, driverCopy, fileToDelete);
        this.driver[key] = '';
        this.isLoading = false;
        this.notification = true;
        this.message = 'File deleted';
      } catch (error) {
        this.message = error.response.data.message;
        this.error = true;
        this.notification = true;
        this.isLoading = false;
      }
    },
    async getState() {
      const dataState = await state.getStates();
      this.state = dataState;
    },
    async getDriver() {
      const route = this.$route;
      if (!route.params?.id) return;
      this.driverId = route.params?.id;
      this.createDriverAction = false;
      if (this.driverId) {
        this.driver = await Drivers.getOneDriver(this.driverId);
      }
    },
    closed() {
      this.notification = false;
      this.message = '';
      this.error = false;
    },
  },
  created() {
    this.getState();
    this.getDriver();
  },
};
</script>
