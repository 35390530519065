<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
    scrollable
  >
    <v-card>
      <v-card tile height="100" elevation="0">
        <v-toolbar flat dark elevation="0" color="primary">
          <v-btn
            dark
            icon
            @click="closeDialog"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Settings Message</v-toolbar-title>
        </v-toolbar>
      </v-card>
      <v-card-text>
        <div class="font-weight-bold" v-if="load.brokerId">
         {{ load.id}} {{ load.brokerId.company}} Load # {{ load.reference}}
        </div>
        <br>
        <v-stepper v-model="e1" elevation="1">
          <v-stepper-header>
            <v-stepper-step editable :complete="e1 > 1" step="1">
              Send Message
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step editable :complete="e1 > 2" step="2">
              Send Email
            </v-stepper-step>
            <v-divider></v-divider>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content step="1">
              <div class="display-1 mb-5">Send SMS</div>
              <step-2 />

            </v-stepper-content>

            <v-stepper-content step="2">
              <div class="display-1 mb-5">Email to factoring</div>
              <step-1 />
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapState } from 'vuex';
import Step1 from '@/components/message/Step1.vue';
import Step2 from '@/components/message/Step2.vue';

export default {
  components: {
    Step1,
    Step2,
  },
  props: {
    showDialog: {
      type: Boolean,
    },
  },

  computed: {
    ...mapState({
      load: (state) => state.Loads.load,
    }),
  },
  data() {
    return {
      dialog: true,
      e1: 1,
      items: [
        {
          title: 'Click Me',
        },
        {
          title: 'Click Me',
        },
        {
          title: 'Click Me',
        },
        {
          title: 'Click Me 2',
        },
      ],
    };
  },
  methods: {
    closeDialog() {
      this.dialog = false;
      this.$emit('closed', false);
    },
  },
};
</script>
