<template>
  <v-breadcrumbs class="px-1"
      :items="items"
    >
    <template v-slot:item="{ item }">
      <router-link
        :to="{ name: item.href }"
      >
        <div>{{ item.text }}</div>
      </router-link>
    </template>
    </v-breadcrumbs>
</template>
<script>
export default {
  data() {
    return {
      items: [],
    };
  },
  watch: {
    $route() {
      this.getRouter();
    },
  },
  methods: {
    getRouter() {
      this.items = [];
      const initRouter = {
        text: 'home',
        href: '/',
        disabled: false,
      };
      this.items.push(initRouter);
      let router = [];
      router = [...this.$route.path.split('/')];
      router.forEach((r) => {
        const data = {};
        data.text = r;
        data.href = r;
        if (r !== '') {
          this.items.push(data);
        }
      });
    },
  },
  created() {
    this.getRouter();
  },
};
</script>
