<template>
  <v-card class="mt-5 rounded-lg b-shadow">
      <v-card-title>
        <v-btn color="primary"
          @click="$router.push({ name: ROUTES.TRACTORS.CREATE})"
        >
          create tractors
        </v-btn>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        v-if="!isLoading"
        :headers="headers"
        :items="tractors"
        :search="search"
        :page.sync="page"
        :items-per-page="itemsPerPage"
        hide-default-footer
        class="text-body-1 font-weight-black"
      >
      <template v-slot:[`item.stateId.description`]="{ item }">
        <v-chip
          class="status-chip"
          :color="item.stateId.description === 'Active' ? 'green' : 'red'"
          dark
          small
        >
          {{ item.stateId.description }}
        </v-chip>
    </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon
          small
          class="mr-2"
          @click="editTractor(item)"
        >
          mdi-pencil-outline
        </v-icon>
        <v-icon
          small
          @click="openDeleteDialog(item, item.id)"
        >
          mdi-trash-can-outline
        </v-icon>
      </template>
      </v-data-table>
      <loading-table
        v-if="isLoading"
      />
      <template v-if="deleteDialog">
        <delete-dialog
        :showModal="deleteDialog"
        @closed="closeDeleteDialog"
        />
      </template>
      <div class="text-center my-4 pb-3">
        <v-pagination
        v-model="page"
        :length="pageCount"
      ></v-pagination>
      </div>
  </v-card>
</template>
<script>
import { ROUTES } from '@/store/constants';
import * as Tractors from '@/services/tractors';
import LoadingTable from '@/components/general/LoadingTable.vue';
import DeleteDialog from '@/components/dialog/Delete.vue';

export default {
  name: 'Tractors',
  components: {
    LoadingTable,
    DeleteDialog,
  },
  data() {
    return {
      take: 10,
      skip: 0,
      deleteDialog: false,
      ROUTES,
      isLoading: false,
      page: 1,
      pageCount: 1,
      itemsPerPage: 10,
      search: '',
      tractors: [],
      tractor: [],
      tractorId: 0,
      headers: [
        { text: 'Actions', value: 'actions', sortable: false },
        { text: 'Id', value: 'id' },
        { text: 'Model', value: 'model' },
        { text: 'Power Unit No.', value: 'power_unit' },
        { text: 'Vehicle Id', value: 'vehicle_id' },
        { text: 'Year model', value: 'year_model' },
        { text: 'License Plate', value: 'license_plate' },
        { text: 'Registration Expiration', value: 'register_expiration' },
        { text: 'State', value: 'stateId.description' },

      ],
    };
  },
  watch: {
    page() {
      this.skip = (this.page - 1) * 10;
      this.getTractors();
    },
    search() {
      this.skip = 0;
      this.getTractors();
    },
  },
  methods: {
    async getTractors() {
      this.isLoading = true;
      const response = await Tractors.getTractors(this.take, this.skip, this.search);
      this.pageCount = Math.ceil(response.count / 10);
      this.tractors = response.data;
      this.isLoading = false;
    },
    editTractor(tractor) {
      const to = { name: 'edit tractor', params: { id: tractor.id } };
      this.$router.push(to);
    },
    openDeleteDialog(tractor, id) {
      this.tractor = tractor;
      this.tractorId = id;
      this.deleteDialog = true;
    },

    async closeDeleteDialog(close) {
      if (close) {
        this.tractor.stateId = 3;

        const result = await Tractors.deleteTractor(this.tractorId, this.tractor);
        if (result) this.getTractors();
      }
      this.deleteDialog = false;
    },
  },
  created() {
    this.getTractors();
  },
};
</script>
