<template>
  <div class="login-view h-100vh d-flex align-center justify-center">
    <v-card height="100vh" width="420" elevation="0"
      class="d-flex flex-column justify-center"
    >
      <div class="h1-title text-center">
        Milam Transport
      </div>
      <div class="d-flex justify-center pa-5">
        <v-img width="300" height="300" src="@/assets/img/check.svg" alt="" />
      </div>
    </v-card>
    <v-card
      v-if="!forgotPassword"
      width="500"
      class="mx-auto rounded-lg b-shadow"
    >
      <v-row>
        <v-col cols="6" offset="3">
          <div class="mx-auto text-center mt-4 h2-title">Welcome</div>
          <p class="text--secondary text-center font-weight-thin">
            Sign in to your account
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-form
            ref="form"
            v-model="validateForm"
            lazy-validation
            class="px-5"
          >
            <v-text-field
              v-model="username"
              type="email"
              aria-autocomplete="email"
              outlined
              label="email"
              required
              :rules="[rules.validateEmail]"
            ></v-text-field>
            <v-text-field
              v-model="password"
              type="password"
              label="password"
              outlined
              :rules="[rules.min]"
            ></v-text-field>
            <v-alert
              class="mb-10"
              v-model="isError"
              dense
              dismissible
              outlined
              type="error"
            >
              {{message}}
            </v-alert>
            <v-btn
              class="mr-4"
              color="primary"
              required
              :depressed="true"
              @click="login"
              block
              large
            >
              <span>Sign in</span>
            </v-btn>
          </v-form>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="d-flex justify-center">
          <v-btn text @click="forgotPassword = true" class="mb-5">Forgot password?</v-btn>
        </v-col>
      </v-row>
    </v-card>
    <v-card
      v-else
      width="500"
      class="mx-auto rounded-lg b-shadow"
    >
      <v-row>
        <v-col>
          <div class="mx-auto text-center mt-4 h2-title">Forgot Password?</div>
          <p class="text--secondary text-center font-weight-thin">
            Enter your account email address and we will send you a link to reset your password.
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <form class="px-5">
            <v-text-field
              v-model="username"
              outlined
              label="email"
              required
              :rules="[rules.validateEmail]"
            ></v-text-field>
            <v-btn
              class="mr-4"
              color="primary"
              required
              :depressed="true"
              @click="login"
              block
              large
            >
              <span>Request Password Reset</span>
            </v-btn>
          </form>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="d-flex justify-center">
          <v-btn link text @click="forgotPassword = false" class="mb-5">Back to Sign In</v-btn>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>
<script>
import * as users from '@/services/users';
import * as constants from '@/store/constants';
import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
  name: 'Login',
  data() {
    return {
      validateForm: false,
      isError: false,
      message: '',
      username: '',
      password: '',
      forgotPassword: false,
      rules: {
        min: () => this.password.length >= 4 || 'Min 4 characters',
        validateEmail: () => this.validateEmail || 'Ingrese un email valido',
      },
    };
  },
  computed: {
    ...mapGetters({
      session: constants.USER_GET_SESSION,
    }),
    validateEmail() {
      // eslint-disable-next-line
      const validate = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
      return validate.test(this.username);
    },
    validateButton() {
      return this.username !== '' && this.password !== '' && typeof this
        .rules.min() !== 'string' && typeof this
        .rules.validateEmail() !== 'string';
    },
  },
  methods: {
    validate() {
      this.$refs.form.validate();
      setTimeout(() => {
        this.login();
      }, 500);
    },
    ...mapActions({
      userLogin: constants.USER_LOGIN,
    }),
    ...mapMutations({
      userSetProperty: constants.USER_SET_PROPERTY,
    }),
    async login() {
      const user = {
        username: this.username,
        password: this.password,
      };
      try {
        const token = await users.login(user);
        localStorage.setItem('token', token);
        this.userSetProperty({ token });
        window.location.reload();
      } catch (error) {
        this.isError = true;
        this.message = error.response.data.message;
      }
    },
    reset() {
      this.name = '';
      this.password = '';
    },
  },
};
</script>
