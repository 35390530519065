import Vue from 'vue';
import 'vuetify/dist/vuetify.min.css';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#0b0146',
        // primary: '#ff5500',
        // primary: '#0E9F6E',
        secondary: '#b0bec5',
        accent: '#8c9eff',
        error: '#FF170D',
      },
    },
  },
});
