import * as constants from '@/store/constants';

const state = {
  session: false,
  token: null,
  isLoading: false,
  errorMessage: '',
};

const actions = {

  [constants.USER_GET_TOKEN]: ({ commit }) => {
    const token = localStorage.getItem('token');
    commit(constants.USER_SET_PROPERTY, { session: !!token });
    commit(constants.USER_SET_PROPERTY, { token });
  },
};
const mutations = {

  // eslint-disable-next-line no-shadow
  [constants.USER_SET_PROPERTY]: (state, data) => {
    const [[property, value]] = Object.entries(data);
    state[property] = value;
  },
};

const getters = {

  // eslint-disable-next-line no-shadow
  [constants.USER_GET_SESSION]: (state) => state.session,
};

export default {
  state,
  actions,
  mutations,
  getters,
};
