<template>
  <div class="content-load">
    <v-card class="mt-5 rounded-lg b-shadow">
      <v-card-title>
        <v-btn color="primary"
          @click="$router.push({ name: ROUTES.LOADS.CREATE})"
        >
          create loads
        </v-btn>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <div class="load-table">
        <div class="load-table-content">
          <v-data-table
            v-if="!isLoading"
            :calculate-widths="false"
            dense
            :headers="headers"
            :single-expand="false"
            :items="loads"
            :page.sync="page"
            :items-per-page="itemsPerPage"
            hide-default-footer
            :mobile-breakpoint="100"
            class="text-body-1 font-weight-black"
          >
          <template v-slot:[`item.driverId.name`]="{ item }">
            <div v-if="item.driverId">
              {{ item.driverId.name.substring(0, 15) }}...
            </div>
          </template>
          <template v-slot:[`item.reference`]="{ item }">
            <div>
              {{ item.reference.substring(0, 10) }}
              <span v-if="item.reference.length >= 10">...</span>
            </div>
          </template>
          <template v-slot:[`item.brokerId`]="{ item }">
            <div v-if="item.brokerId">
              {{ item.brokerId.company.substring(0, 13) }}...
            </div>
          </template>
          <template v-slot:[`item.shipperId`]="{ item }">
            <div v-for="(row, o) in item.shipperId" :key="`a-${o}`">
                {{ o + 1 }}.
                {{ row.path}}
            </div>
          </template>
          <template v-slot:[`item.income`]="{ item }">
            <div v-if="item.accesorialId.length > 0">
              $ {{ calculateIncome(item).totalIncome }}
            </div>
          </template>
          <template v-slot:[`item.rpm`]="{ item }">
            <div v-if="item.accesorialId.length > 0">
              $ {{ calculateIncome(item).rpm }}
            </div>
          </template>
          <template v-slot:[`item.shipperId[0].pickup_start_date`]="{ item }">
            <div v-for="(row, f) in item.shipperId" :key="`b-${f}`">
              {{ row.pickup_start_date | formatDate(true, false) }}
            <span v-if="row.pickup_end_date">
              &ensp;to&ensp; {{ row.pickup_end_date | formatDate(true, false) }}
            </span>
            </div>
          </template>
          <template v-slot:[`item.consigneeId`]="{ item }">
              <div v-for="(row, i) in item.consigneeId" :key="`d-${i}`">
                {{ i + 1}}.
                {{ row.path}}
            </div>
          </template>
          <template v-slot:[`item.consigneeId[0].drop_start_date`]="{ item }">
            <div v-for="(row, e) in item.consigneeId" :key="`e-${e}`">
             {{ row.drop_start_date | formatDate(true, false) }}
             <span v-if="row.drop_end_date">
              &ensp;to&ensp; {{ row.drop_end_date | formatDate(true, false)}}
            </span>
            </div>
          </template>
          <template v-slot:[`item.milles`]="{ item }">
            <div>
              {{ item.milles | fixedDecimal()}}
            </div>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon
              small
              class="mr-2"
              @click="editLoad(item)"
            >
              mdi-pencil-outline
            </v-icon>
            <v-icon
              small
              @click="openDeleteDialog(item, item.id)"
            >
              mdi-trash-can-outline
            </v-icon>
          </template>
          <template v-slot:[`item.stateLoadId.description`]="{ item }">
            <v-chip
              :class="item.stateLoadId.description "
              class="d-flex justify-center"
              dark
              small
              v-if="item.stateLoadId"
              :style="{width: 100 + '%'}"
            >
              {{ item.stateLoadId.description }}
            </v-chip>
          </template>
          </v-data-table>
        </div>
      </div>
      <loading-table v-if="isLoading" />
      <template v-if="deleteDialog">
        <delete-dialog
        :showModal="deleteDialog"
        @closed="closeDeleteDialog"
        />
      </template>
      <div class="text-center my-4 pb-3">
        <v-pagination
          v-model="page"
          :length="pageCount"
        ></v-pagination>
      </div>
    </v-card>
    <v-card v-if="!isLoading" class="mt-5 rounded-lg b-shadow pa-5">
      <div class="d-flex justify-end">
        Total Income: ${{ totalIncome }}
      </div>
    </v-card>
  </div>
</template>
<script>
import { ROUTES } from '@/store/constants';
import * as Loads from '@/services/loads';
import LoadingTable from '@/components/general/LoadingTable.vue';
import DeleteDialog from '@/components/dialog/Delete.vue';

export default {
  name: 'Loads',
  components: {
    LoadingTable,
    DeleteDialog,
  },
  data() {
    return {
      totalIncome: 0,
      take: 40,
      skip: 0,
      deleteDialog: false,
      isLoading: false,
      loads: [],
      load: [],
      loadId: 0,
      ROUTES,
      page: 1,
      pageCount: 5,
      itemsPerPage: 40,
      search: '',
      expanded: [],
      singleExpand: false,
      headers: [
        { text: 'Actions', value: 'actions', sortable: false },
        { text: 'Load ID', value: 'id' },
        { text: 'Reference', value: 'reference' },
        { text: 'Load Status', value: 'stateLoadId.description', sortable: true },
        { text: 'Customers', value: 'brokerId' },
        { text: 'Picks', value: 'shipperId' },
        { text: 'Pick Date', value: 'shipperId[0].pickup_start_date' },
        { text: 'Drops', value: 'consigneeId' },
        { text: 'Drop Date', value: 'consigneeId[0].drop_start_date' },
        { text: 'Driver', value: 'driverId.name' },
        { text: 'Tractor P.U', value: 'tractorId.power_unit' },
        { text: 'Income', value: 'income' },
        { text: 'RPM', value: 'rpm' },
        { text: 'Distance', value: 'milles' },
      ],
    };
  },
  watch: {
    page() {
      this.skip = (this.page - 1) * 40;
      this.getLoads();
    },
    search() {
      this.skip = 0;
      this.getLoads();
    },
  },
  methods: {
    editLoad(load) {
      const to = { name: 'edit load', params: { id: load.id } };
      this.$router.push(to);
    },
    async getLoads() {
      this.isLoading = true;
      const loadsTypes = [1, 2, 3, 4, 5];
      const response = await Loads.getLoads(this.take, this.skip, this.search, loadsTypes);
      this.totalIncome = response.totalIncome;
      this.pageCount = Math.ceil(response.count / 40);
      this.loads = response.data;
      this.isLoading = false;
    },
    openDeleteDialog(load, id) {
      this.load = load;
      this.loadId = id;
      this.deleteDialog = true;
    },
    async closeDeleteDialog(close) {
      if (close) {
        this.load.stateId = 3;
        this.load.reference = '0';
        const result = await Loads.deleteLoad(this.loadId, this.load);
        if (result) this.getLoads();
      }
      this.deleteDialog = false;
    },
    calculateIncome(load) {
      let totalIncome = 0;
      let rpm = 0;
      const { accesorialId, milles } = load;
      if (accesorialId.length === 0) return;
      accesorialId.forEach((acc) => {
        if (acc.accesorialTypeId.id === 1) {
          totalIncome += acc.total;
        }
      });
      rpm = totalIncome / milles;
      // eslint-disable-next-line
      return {
        totalIncome,
        rpm: rpm.toFixed(2),
      };
    },
  },
  created() {
    this.getLoads();
  },
};
</script>
