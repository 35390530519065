import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from '@/router/routes';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, _, next) => {
  const token = localStorage.getItem('token');
  switch (to.name) {
    case 'login':
      if (!token) next();
      next({ path: '/' });
      break;
    case 'index':
      if (!token) next({ name: 'login' });
      next();
      break;
    default:
      if (token) {
        next();
      } else {
        next({ name: 'login' });
      }
      break;
  }
});

export default router;
