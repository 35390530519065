<template>
  <v-card class="rounded-lg b-shadow pa-5 mt-5 mb-16">
    <div class="mb-10 display-1 text-capitalize">{{ formTitle }}</div>
    <v-form
      ref="form"
      v-model="validateForm"
      lazy-validation
    >
      <v-row>
        <v-col lg="6" sm="12" class="py-0">
          <v-text-field
            v-model="trailer.model"
            :rules="[rules.required]"
            label="Make/Model"
            required
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col lg="6" sm="12" class="py-0">
          <v-text-field
            v-model="trailer.trailer_number"
            :rules="[rules.required]"
            label="Trailer Number"
            required
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col lg="6" sm="12" class="py-0">
          <v-text-field
            v-model="trailer.year_model"
            :rules="[rules.required]"
            label="Model Year"
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col lg="6" sm="12" class="py-0">
          <v-text-field
            v-model="trailer.vehicle_id"
            :rules="[rules.required]"
            label="Vehicle Id Number"
            required
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col lg="6" sm="12" class="py-0">
          <v-text-field
            v-model="trailer.type"
            :rules="[rules.required]"
            label="Type Trailer"
            required
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col lg="6" sm="12" class="py-0">
          <v-text-field
            v-model="trailer.license_plate"
            :rules="[rules.required]"
            label="License Plate"
            outlined
            required
            dense
          ></v-text-field>
        </v-col>
        <v-col lg="6" sm="12" class="py-0">
          <v-autocomplete
            v-model="trailer.stateId"
            :rules="rules.validateState"
            :items="state"
            outlined
            dense
            label="Status"
            required
            :return-object="true"
            item-text="description"
          >
          </v-autocomplete>
        </v-col>
      </v-row>
      <div class="text-center my-5">
        <v-btn width="140px" color="primary"
          :loading="isLoading"
          @click="validate"
        >
          Submit
        </v-btn>
      </div>
    </v-form>
    <notification
      :error="error"
      :notification="notification"
      :message="message"
      @closed="closed"
    />
  </v-card>
</template>
<script>
import * as state from '@/services/states';
import * as Trailers from '@/services/trailers';
import Notification from '@/components/general/Notification.vue';

export default {
  name: 'FormTrailer',
  components: {
    Notification,
  },
  data() {
    return {
      isLoading: false,
      validateForm: false,
      trailerId: 0,
      state: [],
      createTrailerAction: true,
      trailer: {
        model: '',
        type: '',
        trailer_number: '',
        license_plate: '',
        year_model: '',
        vehicle_id: '',
        stateId: '',
      },
      rules: {
        required: (v) => !!v || '*required',
        validateState: [
          (v) => v !== '' || '* state is required',
          (v) => v !== null || '* state is required',
        ],
      },
      message: '',
      error: false,
      notification: false,
    };
  },
  computed: {
    formTitle() {
      const route = this.$route;
      return route.name;
    },
  },
  methods: {
    validate() {
      this.$refs.form.validate();
      setTimeout(() => {
        this.setTrailer();
      }, 500);
    },
    async setTrailer() {
      if (!this.validateForm) return;
      const trailerCopy = { ...this.trailer };
      this.isLoading = true;
      this.error = false;
      delete trailerCopy.id;
      delete trailerCopy.createAt;

      Object.assign(trailerCopy, {
        stateId: this.trailer.stateId.id,
      });
      try {
        if (this.createTrailerAction) {
          await Trailers.createTrailer(trailerCopy);
          this.notification = true;
          this.message = 'Trailer created';
          this.$refs.form.reset();
        } else {
          await Trailers.updateTriler(this.trailerId, trailerCopy);
          this.notification = true;
          this.message = 'Trailer updated';
        }
      } catch (error) {
        this.error = true;
        this.notification = true;
        this.message = error.response.data.message;
      }
      this.isLoading = false;
    },
    async getState() {
      const dataState = await state.getStates();
      this.state = dataState;
    },
    async getTrailer() {
      const route = this.$route;
      if (!route.params?.id) return;
      this.trailerId = route.params?.id;
      this.createTrailerAction = false;
      if (this.trailerId) {
        this.trailer = await Trailers.getOneTrailer(this.trailerId);
      }
    },
    closed() {
      this.notification = false;
      this.message = '';
      this.error = false;
    },
  },
  created() {
    this.getState();
    this.getTrailer();
  },
};
</script>
