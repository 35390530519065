<template>
  <div class="">
    <v-card class="mt-5 rounded-lg b-shadow">
      <v-card-title>
        <v-btn color="primary"
          @click="$router.push({ path: '/users/create' })"
        >
          create User
        </v-btn>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        v-if="!isLoading"
        :headers="headers"
        :items="users"
        :search="search"
        :page.sync="page"
        :items-per-page="itemsPerPage"
        hide-default-footer
        class="text-body-1 font-weight-black"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon
            small
            class="mr-2"
            @click="editUser(item)"
          >
            mdi-pencil-outline
          </v-icon>
          <v-icon
            small
            @click="deleteUser(item)"
          >
            mdi-trash-can-outline
          </v-icon>
        </template>
      </v-data-table>
      <loading-table
        v-if="isLoading"
      />
      <div class="text-center my-4 pb-3">
        <v-pagination
          v-model="page"
          :length="pageCount"
        ></v-pagination>
      </div>
    </v-card>
  </div>
</template>
<script>
import { mapMutations } from 'vuex';
import * as Users from '@/services/users';
import * as constants from '@/store/constants';
import LoadingTable from '@/components/general/LoadingTable.vue';

export default {
  components: {
    LoadingTable,
  },
  name: 'Home',
  data() {
    return {
      take: 10,
      skip: 0,
      valid: true,
      isLoading: false,
      page: 1,
      pageCount: 1,
      itemsPerPage: 10,
      search: '',
      users: [],
      headers: [
        { text: 'Actions', value: 'actions', sortable: false },
        { text: 'Id', value: 'id' },
        { text: 'Name User', value: 'name' },
        { text: 'Email', value: 'email' },
        { text: 'role', value: 'roleId.description' },
        { text: 'state', value: 'stateId.description' },

      ],
    };
  },
  watch: {
    page() {
      this.skip = (this.page - 1) * 10;
      this.getUsers();
    },
    search() {
      this.skip = 0;
      this.getUsers();
    },
  },
  methods: {
    ...mapMutations({
      sessionSetProperty: constants.USER_SET_PROPERTY,
    }),
    editUser(user) {
      const to = { name: 'edit user', params: { id: user.id } };
      this.$router.push(to);
    },
    async getUsers() {
      this.isLoading = true;
      const response = await Users.getUsers(this.take, this.skip, this.search);
      this.pageCount = Math.ceil(response.count / 10);
      this.users = response.data.filter((user) => user.roleId.id === 1);
      this.isLoading = false;
    },
  },
  created() {
    this.getUsers();
  },
};
</script>
