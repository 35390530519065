<template>
  <v-card class="rounded-lg b-shadow pa-5 mt-5 mb-16">
    <div class="mb-10 display-1 text-capitalize">{{ formTitle }}</div>
    <v-form
      ref="form"
      v-model="validateForm"
      lazy-validation
    >
      <v-row>
        <v-col lg="6" sm="12" class="py-0">
          <v-text-field
            label="Direct Customers / Broker"
            v-model="broker.company"
            :rules="[rules.required]"
            required
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col lg="6" sm="12" class="py-0" >
          <v-text-field
            label="Contact"
            v-model="broker.contact"
            :rules="[rules.required]"
            required
            outlined
            dense
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col lg="6" sm="12" class="py-0">
          <v-text-field
            label="Address"
            v-model="broker.address"
            :rules="[rules.required]"
            required
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col lg="6" sm="12" class="py-0">
          <v-text-field
            label="Mc Number"
            v-model="broker.mc_number"
            :rules="[rules.required]"
            required
            outlined
            dense
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col lg="6" sm="12" class="py-0">
          <v-autocomplete
            v-model="broker.stateId"
            :rules="rules.validateState"
            :items="state"
            outlined
            dense
            label="Status"
            required
            :return-object="true"
            item-text="description"
          >
          </v-autocomplete>
        </v-col>

        <v-col lg="6" sm="12" class="py-0">
          <v-autocomplete
            v-model="broker.brokerType"
            :rules="rules.validateTypeBroke"
            :items="typeBroker"
            outlined
            dense
            label="Type"
            required
            :return-object="true"
            item-text="description"
          >
          </v-autocomplete>
        </v-col>
      </v-row>
      <div class="text-center my-5">
        <v-btn width="140px" color="primary"
          :loading="isLoading"
          @click="validate"
        >Submit</v-btn>
      </div>
    </v-form>
    <notification
      :error="error"
      :notification="notification"
      :message="message"
      @closed="closed"
    />
  </v-card>
</template>
<script>
import * as Brokers from '@/services/brokers';
import * as state from '@/services/states';
import Notification from '@/components/general/Notification.vue';

export default {
  name: 'Formbroker',
  components: {
    Notification,
  },
  data() {
    return {
      isLoading: false,
      brokerId: 0,
      state: [],
      typeBroker: [],
      broker: {
        stateId: '',
        brokerType: '',
      },
      createbrokerAction: true,
      validateForm: true,
      rules: {
        required: (v) => !!v || '*required',
        validateState: [
          (v) => v !== '' || '* state is required',
          (v) => v !== null || '* state is required',
        ],
        validateTypeBroke: [
          (v) => v !== '' || '* type is required',
          (v) => v !== null || '* type is required',
        ],
      },
      message: '',
      error: false,
      notification: false,
    };
  },
  computed: {
    formTitle() {
      const route = this.$route;
      return route.name;
    },
  },
  methods: {
    validate() {
      this.$refs.form.validate();
      setTimeout(() => {
        this.setBroker();
      }, 500);
    },
    async setBroker() {
      if (!this.validateForm) return;
      const brokerCopy = { ...this.broker };
      this.isLoading = true;
      this.error = false;
      delete brokerCopy.id;
      delete brokerCopy.createAt;
      Object.assign(brokerCopy, {
        stateId: this.broker?.stateId?.id,
        brokerType: this.broker?.brokerType.id,
      });
      try {
        if (this.createbrokerAction) {
          await Brokers.createBroker(brokerCopy);
          this.notification = true;
          this.message = 'Broker created';
          this.$refs.form.reset();
        } else {
          await Brokers.updateBroker(this.brokerId, brokerCopy);
          this.notification = true;
          this.message = 'Broker updated';
        }
      } catch (error) {
        this.error = true;
        this.notification = true;
        this.message = error.response.data.message;
      }
      this.isLoading = false;
    },
    async getbroker() {
      const route = this.$route;
      if (!route.params?.id) return;
      this.brokerId = route.params?.id;
      this.createbrokerAction = false;
      if (this.brokerId) {
        this.broker = await Brokers.getOneBroker(this.brokerId);
      }
    },
    async getState() {
      const dataState = await state.getStates();
      this.state = dataState;
    },
    async getTypeBrokers() {
      const dataTypeBroker = await Brokers.getTypeBrokers();
      this.typeBroker = dataTypeBroker;
    },
    closed() {
      this.notification = false;
      this.message = '';
      this.error = false;
    },
  },
  created() {
    this.getbroker();
    this.getState();
    this.getTypeBrokers();
  },
};
</script>
