<template>
  <v-row>
    <v-form
      ref="form"
      v-model="validateForm"
      lazy-validation
    >
      <v-row class="ma-0">
        <v-col class="py-0">
          <v-autocomplete
            label="Find Consignee Address"
            :items="places.places"
            v-model="findPath"
            item-text="name"
            :return-object="true"
            item-value="address"
            required
            outlined
            dense
          ></v-autocomplete>
      </v-col>
      </v-row>

      <v-row class="ma-0">
        <v-col class="py-0 ma-0">
          <v-text-field
            label="Consignee Name"
            v-model="consignee.consignee_name"
            required
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col class="py-0">
        <v-text-field
            v-model="consignee.drop_start_date"
            :label="!setDate ? 'Drop Date' : 'Start Time'"
            outlined
            dense
            type="datetime-local"
          ></v-text-field>
          <v-text-field
            v-if="setDate"
            v-model="consignee.drop_end_date"
            :label="'End Time'"
            outlined
            dense
            type="datetime-local"
          ></v-text-field>
          <!-- eslint-disable-next-line -->
          <div class="date-text"
            @click="setDate = !setDate"
          >Set a specific date/time</div>
          <div class="date-text">
            <v-checkbox
              v-model="consignee.to_be_assigned"
              label="To be assigned"
              color="red"
              hide-details
            ></v-checkbox>
          </div>
        </v-col>
      </v-row>
      <v-row class="ma-0">
        <v-col class="py-0">
          <place-autocomplete
            @eventPlace="eventPlace"
            label="Dropoff Address"
            :required="true"
            :value="consignee.path"
          />
        </v-col>
      </v-row>
      <v-row class="ma-0 py-6">
        <v-col class="py-0 ma-0">
          <v-text-field
          label="Consignee Contact"
          v-model="consignee.consignee_contact"
          required
          outlined
          dense
          ></v-text-field>
        </v-col>
        <v-col class="py-0 ma-0">
          <v-text-field
            label="Drop #"
            v-model="consignee.consignee_no"
            required
            outlined
            dense
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row class="ma-0">
        <v-col class="py-0 ma-0">
          <v-textarea
            label="Consignee Notes"
            v-model="consignee.consignee_note"
            outlined
            dense
            rows="2"
          ></v-textarea>
        </v-col>
      </v-row>
      <v-row class="ma-0 py-1">
        <v-col class="py-0 ma-0">
          <v-text-field
          label="Drop Position"
            v-model="consignee.position"
            outlined
            dense
            :disabled="!consignee.path ? true: false"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn
              color="primary"
              @click="validate"
            >
              Add Consignee
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-row>
</template>
<script>
import { mapActions } from 'vuex';
import * as constants from '@/store/constants';
import * as places from '@/db/places.json';
import PlaceAutocomplete from '../general/PlaceAutocomplete.vue';

export default {
  name: 'ConsigneeDialog',
  components: {
    PlaceAutocomplete,
  },
  props: {
    data: {
      type: Object,
    },
  },
  data() {
    return {
      places,
      dialog: true,
      validateForm: false,
      setDate: false,
      findPath: {
        address: '',
        name: '',
      },
      consignee: {
        path: '',
        drop_start_date: '',
        drop_end_date: '',
        to_be_assigned: false,
        stateId: 1,
        consignee_name: '',
      },
      rules: {
        required: (v) => !!v || '*required',
        validateState: [
          (v) => v !== '' || '* state is required',
          (v) => v !== null || '* state is required',
        ],
      },
    };
  },
  watch: {
    data() {
      let d = '';
      let e = '';
      if (this.data.drop_start_date) d = new Date(this.data.drop_start_date);

      if (this.data.drop_end_date) e = new Date(this.data.drop_end_date);

      if (this.data) {
        this.consignee = this.data;
        if (this.consignee.drop_start_date) {
          this.consignee.drop_start_date = `${d.getFullYear()}-${(`00${d.getMonth() + 1}`).slice(-2)}-${(`00${d.getDate()}`).slice(-2)} ${(`00${d.getHours()}`).slice(-2)}:${(`00${d.getMinutes()}`).slice(-2)}`;
        }
        if (this.consignee.drop_end_date) {
          this.consignee.drop_end_date = `${e.getFullYear()}-${(`00${e.getMonth() + 1}`).slice(-2)}-${(`00${e.getDate()}`).slice(-2)} ${(`00${e.getHours()}`).slice(-2)}:${(`00${e.getMinutes()}`).slice(-2)}`;
        }
      }
    },
    findPath() {
      this.consignee.path = this.findPath.address;
      this.consignee.consignee_name = this.findPath.name;
    },
  },
  computed: {
  },

  methods: {
    ...mapActions({
      loadAddConsignee: constants.LOADS_ADD_CONSIGNEE,
    }),
    validate() {
      this.$refs.form.validate();
      setTimeout(() => {
        if (this.consignee.path) this.addConsignee();
      }, 500);
    },
    addConsignee() {
      if (!this.validateForm) return;
      this.consignee.stateId = 1;
      if (this.consignee.drop_start_date) {
        this.consignee.drop_start_date = new Date(this.consignee.drop_start_date);
      }
      if (this.consignee.drop_end_date) {
        this.consignee.drop_end_date = new Date(this.consignee.drop_end_date);
      }
      this.loadAddConsignee(this.consignee);
      this.resetData();
    },
    eventPlace(value) {
      this.consignee.path = value;
    },
    resetData() {
      this.consignee = {
        path: '',
        drop_start_date: '',
        drop_end_date: '',
        to_be_assigned: false,
        stateId: 1,
        consignee_name: '',
      };
      this.findPath = {
        address: '',
        name: '',
      };
    },
    closeDialog() {
      this.$emit('closed');
    },
  },
};
</script>
