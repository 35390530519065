import Vue from 'vue';
import moment from 'moment';

Vue.filter(
  'formatPrice',
  (value) => `$ ${Number(value).toLocaleString('eu', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })}`,
);

Vue.filter(
  'fixedDecimal',
  (value) => `${Number(value).toFixed(2)}`,
);

Vue.filter('formatDate', (value, time = false, year = true) => {
  if (!value) return '';
  let parseDate = moment();
  if (year) parseDate = moment(value, 'YYYY-MM-DD').format('MM/DD/YYYY');
  if (time) parseDate = moment(value, 'YYYY-MM-DD HH:mm Z').format('MM/DD-HH:mm');
  if (time && year) parseDate = moment(value, 'YYYY-MM-DD HH:mm Z').format('MM/DD/YYYY-HH:mm');
  return parseDate;
});
