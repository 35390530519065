<template>
  <div class="my-5">
    <v-row class="pb-3">
      <v-col class="py-0 ma-0">
        <v-toolbar
          ref="toolbar"
          rounded="lg"
          outlined
          dark
          color="grey lighten-3"
          height="30"
          elevation="0"
          class="mb-3"
        >
        <v-toolbar-title class="black--text">Edit Stops</v-toolbar-title>
        </v-toolbar>
      </v-col>
    </v-row>
    <v-row>
      <v-col lg="6" sm="12" class="py-0 ma-0 mb-3">
        <place-autocomplete
          @eventPlace="eventPlace"
          label="Deadhead Origin"
          :value="deadhead"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col lg="6" sm="12">
        <v-toolbar
          rounded="lg"
          class="mb-3"
          outlined
          dark
          color="grey lighten-3"
          height="30"
          elevation="0"
        >
          <v-toolbar-title class="black--text">Shippers</v-toolbar-title>
        </v-toolbar>
      <v-col>
        <v-row class="ma-0">
          <template>
            <shipper
              :data="shipperData"
            />
          </template>
        </v-row>
      </v-col>
      <br>
        <v-row class="ma-0">
          <v-card v-if="shipper.length === 0" width="100%" height="60" elevation="0"
            class="d-flex align-center justify-center"
          >
            no data <v-icon class="ml-1" color="primary">mdi-file-alert-outline</v-icon>
          </v-card>
          <v-expansion-panels class="mb-5" v-else multiple v-model="expanded2">
            <v-expansion-panel
              v-for="(item, i) in shipper"
              :key="i"
            >
              <div class="d-flex align-center">
                <v-icon small class="mr-2" color="green" @click="editShipperPath(item, i)">
                  mdi-pencil-outline
                </v-icon>
                <v-icon small color="red" @click="openDeleteDialog(item, 'shipper')">
                  mdi-trash-can-outline
                </v-icon>
                <v-expansion-panel-header>
                  {{ item.path }}
                </v-expansion-panel-header>
              </div>
              <v-expansion-panel-content>
                <div>Name: {{ item.shipper_name }}</div>
                <v-divider></v-divider>
                <div>Address: {{ item.path  }}</div>
                <v-divider></v-divider>
                <div>Date: {{ item.pickup_start_date | formatDate(true, true) }}
                || {{ item.pickup_end_date | formatDate(true, true) }}</div>
                <v-divider></v-divider>
                <div>Contact: {{ item.shipper_contact }}</div>
                <v-divider></v-divider>
                <div>Number: {{ item.shipper_no }}</div>
                <v-divider></v-divider>
                <div>Notes: {{ item.shipper_note }}</div>
                <div>Pickup Position: {{ item.position }}</div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-row>
      </v-col>
      <v-col lg="6" sm="12">
        <v-toolbar
          rounded="lg"
          class="mb-3"
          outlined
          dark
          color="grey lighten-3"
          height="30"
          elevation="0"
        >
          <v-toolbar-title class="black--text">Consignee</v-toolbar-title>
        </v-toolbar>
      <v-col>
        <v-row class="ma-0">
          <template >
            <consignee
              :data="consigneeData"
            />
          </template>
        </v-row>
      </v-col>
        <br>
        <v-row class="ma-0">
          <v-card v-if="consignee.length === 0" width="100%" height="60" elevation="0"
            class="d-flex align-center justify-center"
          >
            no data <v-icon class="ml-1" color="primary">mdi-file-alert-outline</v-icon>
          </v-card>
          <v-expansion-panels class="mb-5" v-else multiple v-model="expanded">
            <v-expansion-panel
              v-for="(item, i) in consignee"
              :key="i"
            >
              <div class="d-flex align-center">
                <v-icon small class="mr-2" color="green" @click="editConsigneePath(item, i)">
                  mdi-pencil-outline
                </v-icon>
                <v-icon small color="red" @click="openDeleteDialog(item, 'consignee')">
                  mdi-trash-can-outline
                </v-icon>
                <v-expansion-panel-header>
                  {{ item.path }}
                </v-expansion-panel-header>
              </div>
              <v-expansion-panel-content>
                <div>Name: {{ item.consignee_name }}</div>
                <v-divider></v-divider>
                <div>Address: {{ item.path }}</div>
                <v-divider></v-divider>
                <div>Date: {{ item.drop_start_date | formatDate(true, true)}}
                || {{ item.drop_end_date | formatDate(true, true) }}</div>
                <v-divider></v-divider>
                <div>Contact: {{ item.consignee_contact }}</div>
                <v-divider></v-divider>
                <div>Number: {{ item.consignee_no }}</div>
                <v-divider></v-divider>
                <div>Notes: {{ item.consignee_note }}</div>
                <div>Drop Position: {{ item.position }}</div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div class="d-flex">
          <v-spacer></v-spacer>
          <v-btn text @click="renderMap"><v-icon>mdi-refresh</v-icon></v-btn>
        </div>
        <div id="map" style="with:300px; height: 400px"></div>
      </v-col>
    </v-row>
    <v-divider class="my-5"></v-divider>
    <v-row class="ma-0">
      <v-col class="pa-0 ma-0">
        <b>Carrier Mileage</b>
        <p>Using calculated carrier mileage</p>
      </v-col>
      <v-col class="py-0 ma-0">
        {{ carrierMilles }} miles
      </v-col>
    </v-row>
    <v-row class="ma-0">
      <v-col class="pa-0 ma-0">
        <b>Deadhead Mileage</b>
        <p>(If applicable)</p>
      </v-col>
      <v-col>
        {{ deadheadMilles }} miles
      </v-col>
    </v-row>
    <v-row class="my-5">
      <v-col class="py-0 ma-0">
        <v-toolbar
          rounded="lg"
          outlined
          dark
          color="grey lighten-3"
          height="30"
          elevation="0"
        >
        <v-toolbar-title class="black--text">Accessorial</v-toolbar-title>
        </v-toolbar>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <accesorial-table />
      </v-col>
    </v-row>
    <total />
    <v-row>
      <v-col lg="3" sm="12" class="py-0">
        <v-text-field
          v-model="load.billed_date"
          label="Billed Date"
          outlined
          dense
          type="date"
        ></v-text-field>
      </v-col>
    </v-row>
    <template v-if="deleteDialog">
      <delete-dialog
      :showModal="deleteDialog"
      @closed="closeDeleteDialog"
      />
    </template>
  </div>
</template>
<script src="https://maps.googleapis.com/maps/api/js?key=AIzaSyAs59dPfauDX-PJk5omzSvy0gMMrG5eG_Q&libraries=places"></script>
<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import * as constants from '@/store/constants';
import { getDistace } from '@/utils';
import Shipper from '@/components/dialog/Shipper.vue';
import Consignee from '@/components/dialog/Consignee.vue';
import PlaceAutocomplete from '@/components/general/PlaceAutocomplete.vue';
import DeleteDialog from '../dialog/Delete.vue';
import Total from './Total.vue';
import AccesorialTable from '@/components/loads/accesorial/Accesorial.vue';

export default {
  name: 'step2',
  components: {
    Shipper,
    Consignee,
    PlaceAutocomplete,
    DeleteDialog,
    Total,
    AccesorialTable,
  },
  data() {
    return {
      expanded: [0, 1, 2, 3],
      expanded2: [0, 1, 2, 3],
      e1: 1,
      actionDelete: '',
      pathToDelete: null,
      shipperData: {},
      consigneeData: {},
      shipperDialog: false,
      consigneeDialog: false,
      deleteDialog: false,
      waypts: [],
      zoom: 5,
      center: { lat: 37.090240, lng: -95.712891 },
    };
  },
  watch: {
    loadMap() {
      if (this.loadMap) this.renderMap();
    },
  },
  computed: {
    ...mapState({
      shipper: (state) => state.Loads.shipper,
      consignee: (state) => state.Loads.consignee,
      directions: (state) => state.Loads.directions,
      deadhead: (state) => state.Loads.deadhead,
      deadheadMilles: (state) => state.Loads.deadheadMilles,
      carrierMilles: (state) => state.Loads.carrierMilles,
      load: (state) => state.Loads.load,
      isEditLoad: (state) => state.Loads.isEditLoad,
      loadMap: (state) => state.Loads.loadMap,
    }),
  },
  methods: {
    ...mapActions({
      loadAddDeadhead: constants.LOADS_ADD_DEADHEAD,
      loadAddDirection: constants.LOADS_ADD_DIRECTION,
      loadRemovePath: constants.LOADS_REMOVE_PATH,
      loadSetProperty: constants.LOADS_SET_PROPERTY,
    }),
    ...mapMutations({
      loadSetProperty: constants.LOADS_SET_PROPERTY,
    }),
    renderMap() {
      const map = new google.maps.Map(document.getElementById('map'), {
        zoom: this.zoom,
        center: this.center,
        mapTypeId: 'terrain',
      });
      const directionsService = new google.maps.DirectionsService();
      const directionsDisplay = new google.maps.DirectionsRenderer({
        suppressInfoWindows: true,
        suppressMarkers: false,
      });
      if (this.directions.length <= 1) return;
      directionsDisplay.setMap(map);
      let initRoute = '';
      let destinationRoute = '';
      let waypoints = [];
      if (this.directions.length === 2) {
        initRoute = this.directions[0].path;
        destinationRoute = this.directions[1].path;
      }
      if (this.directions.length > 2) {
        initRoute = this.directions[0].path;
        destinationRoute = this.directions[this.directions.length - 1].path;
        this.directions.map((direction, i) => {
          if (i > 0) {
            waypoints.push(
              {
                location: direction.path,
                stopover: true,
              },
            );
          }
        })
      }
      waypoints.pop();
      const path = {
        origin: initRoute,
        destination: destinationRoute,
        waypoints: waypoints.length > 0 ? waypoints : [],
        travelMode: google.maps.TravelMode.DRIVING,
        unitSystem: google.maps.UnitSystem.IMPERIAL
      }

      directionsService.route(path, (result, status) => {
        if (status == google.maps.DirectionsStatus.OK) {
          const data = getDistace(result, this.deadhead);
          this.loadSetProperty({ deadheadMilles: data.deadhead });
          this.loadSetProperty({ carrierMilles: data.carrier });

          // load info
          this.load.dead_head_milles = data.deadhead;
          if (this.directions.length > 2 && data.carrier === 0) this.renderMap();
          this.load.milles = data.carrier + data.deadhead;
          directionsDisplay.setDirections(result);
        } else {
          directionsDisplay.setDirections({ routes: [] });
          map.setCenter(this.center);
        }
      });
    },
    closeDialog() {
      this.shipperDialog = false;
      this.consigneeDialog = false;
      this.reset();
    },
    eventPlace(value) {
      this.load.dead_head_address = value;
      this.loadAddDeadhead(value);
      this.renderMap();
    },

    editShipperPath(path, index) {
      this.shipperData = { ...path };
      this.shipperData.edit = true;
      this.shipperData.index = index;
      this.shipperDialog = true;
    },
    editConsigneePath(path, index) {
      this.consigneeData = { ...path };
      this.consigneeData.edit = true;
      this.consigneeData.index = index;
      this.consigneeDialog = true;
    },
    openDeleteDialog(data, action) {
      this.actionDelete = action;
      this.deleteDialog = true;
      this.pathToDelete = data;
    },
    closeDeleteDialog(value) {
      this.deleteDialog = false;
      if (value && this.actionDelete === 'shipper') {
        this.pathToDelete.stateId = 3;
        this.loadRemovePath({ ...this.pathToDelete, action: 'shipper' });
      } else if (value && this.actionDelete === 'consignee') {
        this.pathToDelete.stateId = 3;
        this.loadRemovePath({ ...this.pathToDelete, action: 'consignee' });
      }
    },
    reset() {
      this.shipperData = {};
      this.consigneeData = {};
      this.pathToDelete = {};
    },
  },
  created() {
    setTimeout(() => {
      if (!this.isEditLoad) this.renderMap();
    }, 3000);
  },
};
</script>
