// eslint-disable-next-line
export const getDistace = (data, dh) => {
  const { legs } = data.routes[0];
  const millas = 1609.34;
  let carrier = 0;
  let deadhead = 0;
  legs.forEach((dist, i) => {
    if (dh && i === 0) {
      deadhead = dist.distance.value;
      deadhead /= millas;
    } else {
      carrier += dist.distance.value;
    }
  });
  carrier = carrier > 0 ? carrier / millas : 0;
  return {
    deadhead: Number(deadhead.toFixed(2)),
    carrier: Number(carrier.toFixed(2)),
  };
};
