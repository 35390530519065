<template>
  <div class="home px-5">
    <h1 class="">Dashboard</h1>
    <div class="mt-3 font-weight-medium">Monthly Statistics: {{ getCurrentDate }}</div>
    <div class="d-flex justify-space-between flex-wrap">
      <v-card
      class="mt-4 card-test rounded-lg pa-2"
      max-width="300"
      min-height="200"
      rounded
      >
        <v-list-item three-line>
          <v-list-item-content>
            <div class="text-overline">
              Loads: {{ loads.length }} <br>
            </div>
            <div class="mb-2">
              <v-chip x-small class="Pending ma-0">
                Pending
              </v-chip>
              <v-chip x-small class="In Transit ma-0">
                In Transit
              </v-chip>
              <v-chip x-small class="Dispatched ma-0">
                Dispatched
              </v-chip>
              <v-chip x-small class="Delivered ma-0">
                Delivered
              </v-chip>
              <v-chip x-small class="teal lighten-3 ma-0">
                Need Driver
              </v-chip>
            </div>
            <h3 class="">Pending Income</h3>
            <div class="mt-2 font-weight-medium green--text accent-4 text-h4"
            >{{ totalIncome | formatPrice }}</div>
          </v-list-item-content>
        </v-list-item>
      </v-card>
      <v-card
      class="mt-4 card-test rounded-lg pa-2"
      max-width="300"
      height="200"
      rounded
      >
        <v-list-item three-line>
          <v-list-item-content>
            <div class="text-overline">
              Loads: {{ totalLoadFactored }} <br>
            </div>
            <div class="mb-2">
              <v-chip x-small class="Billed ma-0">
                Billed
              </v-chip>
              <v-chip x-small class="ma-0">
                Factored
              </v-chip>
            </div>
            <h3 class="">Total Loads Billed</h3>
            <div class="mt-2 font-weight-medium green--text accent-4 text-h4"
            >{{ totalFactored | formatPrice }}</div>
          </v-list-item-content>
        </v-list-item>
      </v-card>
      <v-card
      class="mt-4 card-test rounded-lg pa-2"
      max-width="300"
      height="200"
      rounded
      >
        <v-list-item three-line>
          <v-list-item-content>
            <div class="text-overline">
              Loads: {{ totalLoadBrokers }}
            </div>
            <div class="mb-2">
              <v-chip x-small class="Pending ma-0">
                Pending
              </v-chip>
              <v-chip x-small class="In Transit ma-0">
                In Transit
              </v-chip>
              <v-chip x-small class="Dispatched ma-0">
                Dispatched
              </v-chip>
              <v-chip x-small class="Delivered ma-0">
                Delivered
              </v-chip>
              <v-chip x-small class="Billed ma-0">
                Billed
              </v-chip>
              <v-chip x-small class="ma-0">
                Factored
              </v-chip>
              <v-chip x-small class="teal lighten-3 ma-0">
                Need Driver
              </v-chip>
            </div>
            <h3 class="">Brokers RPM</h3>
            <div class="mt-2 font-weight-medium green--text accent-4 text-h4"
            >{{ totalIncomeBroker | formatPrice }}</div>
          </v-list-item-content>
        </v-list-item>
      </v-card>
      <v-card
      class="mt-4 card-test rounded-lg pa-2"
      max-width="300"
      height="200"
      rounded
      >
        <v-list-item three-line>
          <v-list-item-content>
            <div class="text-overline">
              Loads: {{ totalLoadCustomer }}
            </div>
            <div class="mb-2">
              <v-chip x-small class="Pending ma-0">
                Pending
              </v-chip>
              <v-chip x-small class="In Transit ma-0">
                In Transit
              </v-chip>
              <v-chip x-small class="Dispatched ma-0">
                Dispatched
              </v-chip>
              <v-chip x-small class="Delivered ma-0">
                Delivered
              </v-chip>
              <v-chip x-small class="Billed ma-0">
                Billed
              </v-chip>
              <v-chip x-small class="ma-0">
                Factored
              </v-chip>
              <v-chip x-small class="teal lighten-3 ma-0">
                Need Driver
              </v-chip>
            </div>
            <h3 class="">Customers RPM</h3>
            <div class="mt-2 font-weight-medium green--text accent-4 text-h4"
            >{{ totalIncomeCustomer | formatPrice }}</div>
          </v-list-item-content>
        </v-list-item>
      </v-card>
    </div>

    <loading-table class="mt-10 mb-4" v-if="isLoading" />

    <h2 class="mt-10 mb-4" v-if="!isLoading">Recent Loads</h2>
    <v-card class="mt-5 rounded-lg b-shadow mb-10" v-if="!isLoading">
      <div class="load-table">
        <div class="load-table-content">
          <v-data-table
            :calculate-widths="false"
            dense
            :headers="headers"
            :single-expand="false"
            :items="loads"
            :page.sync="page"
            :items-per-page="itemsPerPage"
            hide-default-footer
            :mobile-breakpoint="100"
          >
          <template v-slot:[`item.driverId.name`]="{ item }">
            <div v-if="item.driverId">
              {{ item.driverId.name.substring(0, 15) }}...
            </div>
          </template>
          <template v-slot:[`item.reference`]="{ item }">
            <div>
              {{ item.reference.substring(0, 10) }}
              <span v-if="item.reference.length >= 10">...</span>
            </div>
          </template>
          <template v-slot:[`item.brokerId`]="{ item }">
            <div v-if="item.brokerId">
              {{ item.brokerId.company.substring(0, 13) }}...
            </div>
          </template>
          <template v-slot:[`item.shipperId`]="{ item }">
            <div v-for="(row, o) in item.shipperId" :key="`a-${o}`">
                {{ o + 1 }}.
                {{ row.path}}
            </div>
          </template>
          <template v-slot:[`item.consigneeId`]="{ item }">
              <div v-for="(row, i) in item.consigneeId" :key="`d-${i}`">
                {{ i + 1}}.
                {{ row.path}}
            </div>
          </template>
          <template v-slot:[`item.stateLoadId.description`]="{ item }">
            <v-chip
              :class="item.stateLoadId.description "
              class="d-flex justify-center"
              dark
              small
              v-if="item.stateLoadId"
              :style="{width: 100 + '%'}"
            >
              {{ item.stateLoadId.description }}
            </v-chip>
          </template>
          </v-data-table>
        </div>
      </div>
    </v-card>
  </div>
</template>
<script>
import * as Loads from '@/services/loads';
import LoadingTable from '@/components/general/LoadingTable.vue';

export default {
  name: 'Home',
  components: {
    LoadingTable,
  },
  data() {
    return {
      totalLoads: 0,
      totalMillesBroker: 0,
      totalMillesCustomer: 0,
      totalIncome: 0,
      totalIncomeBroker: 0,
      totalIncomeCustomer: 0,
      totalLoadFactored: 0,
      totalLoadBrokers: 0,
      totalLoadCustomer: 0,
      totalFactored: 0,
      totalExpense: 0,
      totalEarns: 0,
      loads: [],
      page: 1,
      itemsPerPage: 10,
      isLoading: false,
      headers: [
        { text: 'Load ID', value: 'id' },
        { text: 'Reference', value: 'reference' },
        { text: 'Load Status', value: 'stateLoadId.description' },
        { text: 'Customers', value: 'brokerId' },
        { text: 'Picks', value: 'shipperId' },
        { text: 'Drops', value: 'consigneeId' },
        { text: 'Driver', value: 'driverId.name' },
        { text: 'Tractor P.U', value: 'tractorId.power_unit' },
      ],
    };
  },
  computed: {
    getCurrentDate() {
      const date = new Date();
      return date.toLocaleString('en-US', { month: 'long' });
    },
  },
  methods: {
    async getLoads() {
      const date = new Date();
      const actualDate = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate() + 1,
      );
      const startDate = new Date(
        date.getFullYear(),
        date.getMonth(),
        1,
      );
      this.isLoading = true;
      const loadsTypes = [1, 2, 3, 4, 5];
      Promise.all([
        Loads.getLoads(100, 0, '', loadsTypes),
        Loads.getLoadsReport([6, 9], [], [], [], [], startDate, actualDate),
        Loads.getLoadsReport([], [], [], [], [], startDate, actualDate),
      ])
        .then(([loads, billedFact, brokers]) => {
          this.loads = loads.data;
          this.totalIncome = loads.totalIncome;
          const loadBilled = billedFact.data.filter((load) => load.shipperId.length > 0);

          loadBilled.forEach((load) => {
            load.accesorialId.forEach((data) => {
              if (data.accesorialTypeId.id === 1) {
                this.totalLoadFactored += 1;
                this.totalFactored += data.total;
              }
              if (data.accesorialTypeId.id === 2) {
                this.totalExpense += data.total;
              }
            });
          });
          const loadbrokers = brokers.data.filter((load) => load.shipperId.length > 0);
          loadbrokers.forEach((load) => {
            if (load.brokerId.brokerType.id === 1) {
              load.accesorialId.forEach((data) => {
                if (data.accesorialTypeId.id === 1) {
                  this.totalLoadBrokers += 1;
                  this.totalIncomeBroker += data.total;
                  this.totalMillesBroker += parseInt(load.milles, 10);
                }
              });
            }
            if (load.brokerId.brokerType.id === 2) {
              load.accesorialId.forEach((data) => {
                if (data.accesorialTypeId.id === 1) {
                  this.totalLoadCustomer += 1;
                  this.totalIncomeCustomer += data.total;
                  this.totalMillesCustomer += parseInt(load.milles, 10);
                }
              });
            }
          });
          this.totalIncomeBroker /= this.totalMillesBroker;
          this.totalIncomeCustomer /= this.totalMillesCustomer;
          this.isLoading = false;
        });
    },
  },
  created() {
    this.getLoads();
  },
};
</script>
