export const USER_LOGIN = 'USER_LOGIN';
export const USER_SET_PROPERTY = 'USER_SET_PROPERTY';
export const USER_GET_SESSION = 'USER_GET_SESSION';
export const USER_GET_TOKEN = 'USER_GET_TOKEN';

export const TRAILERS_GET = 'TRAILERS_GET';
export const TRAILERS_SET_PROPERTY = 'TRAILERS_SET_PROPERTY';
export const TRAILERS_CREATE = 'TRAILERS_CREATE_TRAILER';
export const TRAILERS_GET_ONE = 'TRAILERS_GET_ONE';
export const TRAILERS_UPDATE = 'TRAILERS_UPDATE';

export const LOADS_RENDER_MAP = 'LOADS_RENDER_MAP';
export const LOADS_ADD_DEADHEAD = 'LOADS_ADD_DEADHEAD';
export const LOADS_ADD_SHIPPER = 'LOADS_ADD_SHIPPER';
export const LOADS_ADD_CONSIGNEE = 'LOADS_ADD_CONSIGNEE';
export const LOADS_SET_ACCESORIAL = 'LOADS_SET_ACCESORIAL';
export const LOADS_SET_PROPERTY = 'LOADS_SET_PROPERTY';
export const LOADS_ADD_DIRECTION = 'LOADS_ADD_DIRECTION';
export const LOADS_REMOVE_PATH = 'LOADS_REMOVE_PATH';
export const LOADS_GET_INFORMATION = 'LOADS_GET_INFORMATION';
export const LOADS_CREATE_LOAD = 'LOADS_CREATE_LOAD';
export const LOADS_GET_LOAD = 'LOADS_GET_LOAD';
export const LOADS_RESET_DATA = 'LOADS_RESET_DATA';
export const LOADS_RELOAD_MAP = 'LOADS_RELOAD_MAP';

export const ROUTES = {
  HOME: '/',
  LOGIN: 'login',
  DRIVERS: {
    HOME: 'home-drivers',
    ROOT: 'drivers',
    CREATE: 'create drivers',
  },
  TRACTORS: {
    HOME: 'home-tractors',
    ROOT: 'tractors',
    CREATE: 'create tractors',
  },
  TRAILERS: {
    HOME: 'home-trailers',
    ROOT: 'trailers',
    CREATE: 'create trailers',
  },
  BROKERS: {
    HOME: 'home-brokers',
    ROOT: 'brokers',
    CREATE: 'create brokers',
  },
  USERS: {
    HOME: 'home-users',
    ROOT: 'users',
    CREATE: 'create users',
  },
  LOADS: {
    HOME: 'home-loads',
    ROOT: 'loads',
    CREATE: 'create loads',
    FACTURED: 'factured',
    BILLED: 'billed',
    PAID: 'paid',
  },
  BILLING: {
    HOME: 'home-billing',
    ROOT: 'billing',
  },
  REPORTING: {
    HOME: 'reporting',
  },
};
