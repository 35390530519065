<template>
  <v-card class="rounded-lg b-shadow pa-5 mt-5 mb-16">
    <div class="mb-10 display-1 text-capitalize">{{ formTitle }}</div>
    <v-form
      ref="form"
      v-model="validateForm"
      lazy-validation
    >
      <v-row>
        <v-col lg="6" sm="12" class="py-0">
          <v-text-field
            label="Model"
            v-model="tractor.model"
            :rules="[rules.required]"
            required
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col lg="6" sm="12" class="py-0">
          <v-text-field
            label="Power Unit No."
            v-model="tractor.power_unit"
            :rules="[rules.required]"
            required
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col lg="6" sm="12" class="py-0">
          <v-text-field
            label="vehicle_id"
            v-model="tractor.vehicle_id"
            :rules="[rules.required]"
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col lg="6" sm="12" class="py-0">
          <v-text-field
            label="year_model"
            v-model="tractor.year_model"
            :rules="[rules.required]"
            required
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col lg="6" sm="12" class="py-0">
          <v-text-field
            label="License Plate"
            v-model="tractor.license_plate"
            :rules="[rules.required]"
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col lg="6" sm="12" class="py-0">
          <v-text-field
            label="Registration Expiration"
            v-model="tractor.register_expiration"
            :rules="[rules.required]"
            outlined
            type="date"
            dense
          ></v-text-field>
        </v-col>
        <v-col lg="6" sm="12" class="py-0">
           <v-autocomplete
            outlined
            dense
            v-model="tractor.stateId"
            :rules="rules.validateState"
            :items="state"
            label="Status"
            required
            :return-object="true"
            item-text="description"
          >
          </v-autocomplete>
        </v-col>
      </v-row>
      <div class="text-center my-5">
        <v-btn width="140px" color="primary"
          @click="validate"
        >Submit</v-btn>
      </div>
    </v-form>
    <notification
      :error="error"
      :notification="notification"
      :message="message"
      @closed="closed"
    />
  </v-card>
</template>
<script>
import * as Tractors from '@/services/tractors';
import * as state from '@/services/states';
import Notification from '@/components/general/Notification.vue';

export default {
  name: 'FormTractor',
  components: {
    Notification,
  },
  data() {
    return {
      tractorId: 0,
      state: [],
      tractor: {
        stateId: '',
      },
      createTractorAction: true,
      validateForm: false,
      rules: {
        required: (v) => !!v || '*required',
        validateState: [
          (v) => v !== '' || '* state is required',
          (v) => v !== null || '* state is required',
        ],
      },
      message: '',
      error: false,
      notification: false,
    };
  },
  computed: {
    formTitle() {
      const route = this.$route;
      return route.name;
    },
  },
  methods: {
    validate() {
      this.$refs.form.validate();
      setTimeout(() => {
        this.setTractor();
      }, 500);
    },
    async setTractor() {
      if (!this.validateForm) return;
      const tractorCopy = { ...this.tractor };
      this.isLoading = true;
      this.error = false;
      delete tractorCopy.id;
      delete tractorCopy.createAt;

      Object.assign(tractorCopy, {
        stateId: this.tractor.stateId.id,
      });
      try {
        if (this.createTractorAction) {
          await Tractors.createTractor(tractorCopy);
          this.notification = true;
          this.message = 'Tractor created';
          this.$refs.form.reset();
        } else {
          await Tractors.updateTractor(this.tractorId, tractorCopy);
          this.notification = true;
          this.message = 'Tractor updated';
        }
      } catch (error) {
        this.error = true;
        this.notification = true;
        this.message = error.response.data.message;
      }
      this.isLoading = false;
    },
    async getTractor() {
      const route = this.$route;
      if (!route.params?.id) return;
      this.tractorId = route.params?.id;
      this.createTractorAction = false;
      if (this.tractorId) {
        this.tractor = await Tractors.getOneTractor(this.tractorId);
      }
    },
    async getState() {
      const dataState = await state.getStates();
      this.state = dataState;
    },
    closed() {
      this.notification = false;
      this.message = '';
      this.error = false;
    },
  },
  created() {
    this.getTractor();
    this.getState();
  },
};
</script>
