<template>
  <v-snackbar
    class="b-shadow rounded-lg"
    v-model="snackbar"
    top
    right
    :color="error ? 'error' : 'primary'"
    :value="true"
    outlined
  >
    {{ message }}
    <template v-slot:action="{ attrs }">
      <v-icon
        v-bind="attrs"
        @click="snackbar = false"
      >
        mdi-close-circle-outline
      </v-icon>
    </template>
  </v-snackbar>
</template>
<script>
export default {
  name: 'Notification',
  props: {
    notification: {
      type: Boolean,
      required: true,
    },
    error: {
      type: Boolean,
      required: true,
    },
    message: {
      type: String,
      required: true,
    },
  },
  watch: {
    notification() {
      this.snackbar = this.notification;
    },
    snackbar() {
      if (this.snackbar) return;
      this.$emit('closed');
    },
  },
  data() {
    return {
      snackbar: this.notification,
    };
  },
};
</script>
