<template>
  <v-card class="rounded-lg b-shadow pa-5 mt-5 mb-16">
    <div class="mb-10 display-1 text-capitalize">{{ formTitle }}</div>
    <v-form
      ref="form"
      v-model="validateForm"
      lazy-validation
    >
      <v-row>
        <v-col lg="6" sm="12" class="py-0" v-if="createUserAction || user.roleId.id == 1">
          <v-text-field
            outlined
            dense
            v-model="user.name"
            label="Name*"
            required
            :rules="[rules.validateName]"
          ></v-text-field>
        </v-col>
        <v-col lg="6" sm="12" class="py-0" v-if="user.roleId.id == 2">
          <v-text-field
            outlined
            dense
            v-model="user.name"
            label="POD"
            required
            :rules="[rules.validateName]"
          ></v-text-field>
        </v-col>
        <v-col lg="6" sm="12" class="py-0">
          <v-text-field
            outlined
            dense
            v-model="user.email"
            label="Email*"
            required
            color="grey"
            :disabled="!createUserAction && user.roleId.id == 1 || createUserAction"
            :rules="[rules.validateEmail]"
          ></v-text-field>
        </v-col>
        <v-col lg="6" sm="12" class="py-0" v-if="user.roleId.id == 2">
          <v-text-field
            outlined
            dense
            v-model="user.password"
            label="CC"
            required
            color="grey"
          ></v-text-field>
        </v-col>
        <v-col lg="6" sm="12" class="py-0" v-if="createUserAction || user.roleId.id == 1">
          <v-autocomplete
            outlined
            dense
            v-model="user.stateId"
            :items="state"
            label="Status"
            required
            :return-object="true"
            item-text="description"
            :rules="rules.validateState"
          >
          </v-autocomplete>
        </v-col>
        <v-col lg="6" sm="12" class="py-0" v-if="createUserAction || user.roleId.id == 1">
          <v-autocomplete
            v-model="user.roleId"
            :rules="rules.validateRole"
            :items="roles"
            outlined
            dense
            label="type of user"
            required
            :return-object="true"
            item-text="description"
          >
          </v-autocomplete>
        </v-col>
      </v-row>
      <div class="text-center my-5">
        <v-btn width="140px" color="primary"
          @click="validate"
          :loading="isLoading"
        >
          Save
        </v-btn>
      </div>
    </v-form>
    <notification
      :error="error"
      :notification="notification"
      :message="message"
      @closed="closed"
    />
  </v-card>
</template>
<script>
import * as users from '@/services/users';
import * as roles from '@/services/roles';
import * as state from '@/services/states';
import Notification from '@/components/general/Notification.vue';

export default {
  name: 'FormUser',
  components: {
    Notification,
  },
  data() {
    return {
      isLoading: false,
      validateForm: false,
      userId: 0,
      roles: [],
      state: [],
      rules: {
        validateName: (v) => v !== '' || '* name is required',
        validateEmail: () => this.validateEmail || '* enter a valid email',
        validateState: [
          (v) => v !== '' || '* state is required',
          (v) => v !== null || '* state is required',
        ],
        validateRole: [
          (v) => v !== '' || '* role is required',
          (v) => v !== null || '* role is required',
        ],
      },
      user: {
        name: '',
        email: '',
        password: '',
        roleId: '',
        stateId: '',
      },
      createUserAction: true,
      message: '',
      error: false,
      notification: false,
    };
  },
  computed: {
    formTitle() {
      const route = this.$route;
      return route.name;
    },
    validateEmail() {
      // eslint-disable-next-line
      const validate = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return validate.test(this.user.email);
    },
  },
  methods: {
    validate() {
      this.$refs.form.validate();
      setTimeout(() => {
        this.setUser();
      }, 500);
    },
    async setUser() {
      if (!this.validateForm) return;
      this.isLoading = true;
      this.error = false;
      const userC = {
        name: this.user.name,
        email: this.user.email,
        roleId: this.user.roleId.id,
        stateId: this.user.stateId.id,
        password: this.user.password,
      };
      try {
        if (this.createUserAction) {
          await users.createUser(userC);
          this.notification = true;
          this.message = 'User created';
          this.$refs.form.reset();
        } else {
          await users.updateUser(this.userId, userC);
          this.notification = true;
          this.message = 'User updated';
        }
      } catch (error) {
        this.error = true;
        this.message = error.response.data.message;
      }
      this.isLoading = false;
    },
    async getRoles() {
      const data = await roles.getRoles();
      this.roles = data;
    },
    async getState() {
      const dataState = await state.getStates();
      this.state = dataState;
    },
    async getUser() {
      const route = this.$route;
      if (!route.params?.id) return;
      this.userId = route.params?.id;
      this.createUserAction = false;
      if (this.userId) {
        this.user = await users.getOneUser(this.userId);
      }
    },
    closed() {
      this.notification = false;
      this.message = '';
      this.error = false;
    },
  },
  created() {
    this.getRoles();
    this.getState();
    this.getUser();
  },
};
</script>
