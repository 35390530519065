import GmapCluster from 'vue2-google-maps/dist/components/cluster';
import Vue from 'vue';
import excel from 'vue-excel-export';
import * as VueGoogleMaps from 'vue2-google-maps';
import dateTimePicker from 'vuetify-datetime-picker';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import './scss/main.scss';

Vue.use(excel);

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyAs59dPfauDX-PJk5omzSvy0gMMrG5eG_Q',
    libraries: 'places',
  },
  installComponents: true,
});
Vue.component('GmapCluster', GmapCluster);

require('./filters');

Vue.config.productionTip = false;
Vue.use(dateTimePicker);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
