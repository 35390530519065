<template>
  <v-card class="rounded-lg b-shadow pa-5 mt-5 mb-16">
    <div class="d-flex" v-if="!isLoadingLoad && !isLoadingOneLoad">
      <div class="mb-5 display-1 text-capitalize">{{ title }}</div>
      <v-spacer></v-spacer>
        <v-btn small color="primary"
          :disabled="!showOptionsButtons"
          @click="validate" :loading="isLoadingAction">
          Save Load
        </v-btn>
      <v-btn v-if="loadId" small
        :disabled="!showOptionsButtons"
        color="orange" @click="dialogMessage = true" class="ml-2">
        Settings messages</v-btn>
      <v-btn v-if="loadId" small color="green" @click="generateEnvoice" class="ml-2"
        :disabled="!showOptionsButtons"
        :loading="loadInvoice"
      >
        Generate Invoice</v-btn>
    </div>
    <invoice v-if="loadId && !isLoadingLoad" :load="load" />
    <v-row v-if="isLoadingLoad || isLoadingOneLoad">
      <v-col md="6" cols="12">
        <v-skeleton-loader
          type="article, list-item-two-line"
        ></v-skeleton-loader>
      </v-col>
      <v-col md="6" cols="12">
        <v-skeleton-loader
          type="article, list-item-two-line"
        ></v-skeleton-loader>
      </v-col>
      <v-col md="6" cols="12">
        <v-skeleton-loader
          type="article, list-item-two-line"
        ></v-skeleton-loader>
      </v-col>
      <v-col md="6" cols="12">
        <v-skeleton-loader
          type="article, list-item-two-line"
        ></v-skeleton-loader>
      </v-col>
    </v-row>
     <v-form
      v-else
      ref="form"
      v-model="validateForm"
      lazy-validation
    >
      <v-stepper v-model="e1" elevation="0">
        <v-stepper-header>
          <v-stepper-step editable :complete="e1 > 1" step="1">
            Load Basic
          </v-stepper-step>
          <v-divider></v-divider>

          <v-stepper-step editable :complete="e1 > 2" step="2">
            Map
          </v-stepper-step>
          <v-divider></v-divider>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content step="1">
            <step-1 />
          <div class="spacer">
            <v-btn color="primary" @click="e1 = 2">
              Continue
            </v-btn>
            <v-btn class="ml-2" color="primary"
            :disabled="!showOptionsButtons"
              @click="validate" :loading="isLoadingAction">
              Save Load
            </v-btn>
          </div>
          </v-stepper-content>

          <v-stepper-content step="2">
            <step-2 />
          <div>
              <div class="d-flex" v-if="!isLoadingLoad">
                <div class="mb-5 display-1 text-capitalize">
                      <v-btn color="primary"
                        :disabled="!showOptionsButtons"
                        @click="validate" :loading="isLoadingAction">
                        Save Load
                      </v-btn>
                </div>
                <v-spacer></v-spacer>
                <v-btn v-if="loadId" small color="orange" @click="dialogMessage = true">
                  Settings messages</v-btn>
                <v-btn v-if="loadId" small color="green" @click="generateEnvoice" class="ml-2"
                  :loading="loadInvoice"
                >
                  Generate Invoice</v-btn>
              </div>
          </div>

          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
     </v-form>
    <template v-if="dialogMessage">
      <message-dialog :showDialog="dialogMessage" @closed="closeDialog" />
    </template>
  </v-card>
</template>
<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import * as constants from '@/store/constants';
import MessageDialog from '@/components/dialog/Message.vue';
import html2PDF from 'jspdf-html2canvas';
import Invoice from './Invoice.vue';
import Step1 from './Step1.vue';
import Step2 from './Step2.vue';

export default {
  name: 'FormLoads',
  components: {
    MessageDialog,
    Step1,
    Step2,
    Invoice,
  },
  data() {
    return {
      e1: 1,
      dialogMessage: false,
      loadId: 0,
      createLoadAction: true,
      validateForm: false,
      error: false,
      notification: false,
      loadInvoice: false,
    };
  },
  watch: {
    $route() {
      this.getLoad();
    },
  },
  computed: {
    ...mapState({
      load: (state) => state.Loads.load,
      isLoading: (state) => state.Loads.isLoading,
      isLoadingLoad: (state) => state.Loads.isLoadingLoad,
      isError: (state) => state.Loads.isError,
      message: (state) => state.Loads.message,
      isLoadingAction: (state) => state.Loads.isLoadingAction,
      isLoadingOneLoad: (state) => state.Loads.isLoadingOneLoad,
      showOptionsButtons: (state) => state.Loads.showOptionsButtons,
    }),
    title() {
      return this.loadId ? 'Edit Load' : 'Create Load';
    },
  },
  methods: {
    validate() {
      this.$refs.form.validate();
      setTimeout(() => {
        this.setLoad();
      }, 500);
    },
    ...mapMutations({
      loadSetProperty: constants.LOADS_SET_PROPERTY,
    }),
    ...mapActions({
      loadsGetInformation: constants.LOADS_GET_INFORMATION,
      createLoad: constants.LOADS_CREATE_LOAD,
      chargeLoad: constants.LOADS_GET_LOAD,
      resetData: constants.LOADS_RESET_DATA,
    }),
    setLoad() {
      if (!this.validateForm) {
        this.e1 = 1;
        return;
      }
      this.createLoad();
    },
    closeDialog() {
      this.dialogMessage = false;
    },
    getLoad() {
      this.loadSetProperty({ isEditLoad: false });
      const route = this.$route;
      if (!route.params?.id) return;
      this.loadId = route.params?.id;
      this.createLoadAction = false;
      this.loadSetProperty({ isEditLoad: true });
      if (this.loadId) {
        this.chargeLoad(this.loadId);
      }
    },
    generateEnvoice() {
      const page = document.getElementsByClassName('invoice');
      this.loadInvoice = true;
      html2PDF(
        page,
        {
          jsPDF: {
            unit: 'px',
            format: 'a4',
          },
          imageType: 'image/jpeg',
          imageQuality: 1,
          output: '/generate.pdf',
          margin: {
            top: 30,
            right: 20,
            bottom: 20,
            left: 20,
          },
          init: (pdf) => {
            pdf.setFont('helvetica');
            pdf.setFontSize(12);
          },
          success: (pdf) => {
            pdf.output('dataurlnewwindow', { filename: 'Invoice.pdf' });
            this.loadInvoice = false;
          },
        },
      );
    },
  },
  created() {
    this.resetData();
    this.loadsGetInformation();
    this.getLoad();
  },
};
</script>
