<template>
  <div>
    <v-card elevation="0">
      <div class="d-flex align-center py-0">
        <div class="font-weight-black">Income</div>
        <v-btn text small @click="openAccesesorial(type = 1, action = 'create')">
          <v-icon small>mdi-plus</v-icon>
          add
        </v-btn>
      </div>
      <v-divider></v-divider>
      <table-acceserial v-if="!isLoadExpense" :key="isLoadExpense" :items="accesorialsIncome" />
      <table-acceserial v-else :key="isLoadExpense" :items="accesorialsIncome" />
    </v-card>
    <v-card elevation="0">
      <div class="d-flex align-center py-0">
        <div class="font-weight-black">Expense</div>
        <v-btn text small @click="openAccesesorial(type = 2, action = 'create')">
          <v-icon small>mdi-plus</v-icon>
          add
        </v-btn>
      </div>
      <v-divider></v-divider>
      <table-acceserial :items="accesorialsExpense" />
    </v-card>
    <v-card elevation="0">
      <div class="d-flex align-center py-0">
        <div class="font-weight-black">Deductions</div>
        <v-btn text small @click="openAccesesorial(type = 3, action = 'create')">
          <v-icon small>mdi-plus</v-icon>
          add
        </v-btn>
      </div>
      <v-divider></v-divider>
      <table-acceserial :items="accesorialsDeductions" />
    </v-card>
    <template v-if="accesorialDialog">
      <accesorial-dialog
        :showModal="accesorialDialog"
        @closed="closeDialog"
        @addAccesorial="addAccesorial"
        :type="accesorialType"
        :data="accesorialEdit"
      />
    </template>
  </div>
</template>
<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import * as constants from '@/store/constants';
import TableAcceserial from '@/components/loads/accesorial/Table.vue';
import AccesorialDialog from '@/components/dialog/Accesorial.vue';

export default {
  name: 'AccesorialContent',
  components: {
    TableAcceserial,
    AccesorialDialog,
  },
  data() {
    return {
      accesorialType: 0,
      accesorialEdit: {},
      isLoadExpense: 0,
    };
  },
  watch: {
    isLoadAccesorial() {
      if (this.isLoadAccesorial) this.filterAccesorialData();
    },
    accesorials() {
      this.filterAccesorialData();
    },
    driverId() {
      this.loadInfoDriver();
    },
    loadMap() {
      this.loadInfoDriver();
    },
    milles() {
      this.loadInfoDriver();
    },
  },
  computed: {
    ...mapState({
      accesorials: (state) => state.Loads.accesorials,
      driverId: (state) => state.Loads.load.driverId,
      carrierMilles: (state) => state.Loads.carrierMilles,
      deadheadMilles: (state) => state.Loads.deadheadMilles,
      load: (state) => state.Loads.load,
      milles: (state) => state.Loads.load.milles,
      accesorialDialog: (state) => state.Loads.accesorialDialog,
      isLoadAccesorial: (state) => state.Loads.isLoadAccesorial,
      accesorialsIncome: (state) => state.Loads.accesorialsIncome,
      accesorialsExpense: (state) => state.Loads.accesorialsExpense,
      accesorialsDeductions: (state) => state.Loads.accesorialsDeductions,
      accesorialCategories: (state) => state.Loads.accesorialCategories,
      accesorialTypes: (state) => state.Loads.accesorialTypes,
      loadMap: (state) => state.Loads.loadMap,
    }),
  },
  methods: {
    ...mapMutations({
      loadSetProperty: constants.LOADS_SET_PROPERTY,
    }),
    ...mapActions({
      loadAddAccesorial: constants.LOADS_SET_ACCESORIAL,
    }),
    addAccesorial(accesorial) {
      this.loadAddAccesorial({ accesorial, type: 1 });
    },
    closeDialog() {
      this.loadSetProperty({ accesorialDialog: false });
    },
    filterAccesorialData() {
      const accesorialsIncome = this.accesorials.filter((acc) => acc.accesorialTypeId.id === 1);
      this.loadSetProperty({ accesorialsIncome });
      const accesorialsExpense = this.accesorials.filter((acc) => acc.accesorialTypeId.id === 2);
      this.loadSetProperty({ accesorialsExpense });
      const accesorialsDeductions = this.accesorials.filter((acc) => acc.accesorialTypeId.id === 3);
      this.loadSetProperty({ accesorialsDeductions });
    },
    // type is actionType e.g: income = 1 etc
    openAccesesorial(type, action) {
      this.loadSetProperty({ accesorialDialog: true });
      this.loadSetProperty({ accesorialAction: action });
      this.loadSetProperty({ accesorialNumberType: type });
    },
    loadInfoDriver() {
      this.isLoadExpense = true;
      const info = {};
      info.accesorialTypeId = this.accesorialTypes
        .filter((acc) => acc?.id === 2).pop();
      info.accesorialCategoryId = this.accesorialCategories
        .filter((acc) => acc?.id === 3).pop();
      info.driver = this.driverId;
      info.isDriver = true;
      info.rate = this.driverId?.rate;
      info.quantity = Number(this.load.milles).toFixed(2);
      const value = (info.quantity * info.rate).toFixed(2) || 0;
      info.total = Number(value) || 0;
      const index = this.accesorials.findIndex((acc) => acc?.accesorialTypeId.id === 2);
      if (index >= 0) {
        this.accesorials[index] = { ...this.accesorials[index], ...info };
      } else {
        this.accesorials.push(info);
      }
      this.isLoadExpense = false;
      this.filterAccesorialData();
    },
  },
};
</script>
