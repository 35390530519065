<template>
  <div class="">
    <v-card class="mt-5 rounded-lg b-shadow">
      <v-card-title>
        <v-btn color="primary"
          @click="$router.push({ name: ROUTES.DRIVERS.CREATE})"
        >
          create drivers
        </v-btn>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search Driver"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        v-if="!isLoading"
        :headers="headers"
        :items="drivers"
        :search="search"
        :page.sync="page"
        :items-per-page="itemsPerPage"
        hide-default-footer
        class="text-body-1 font-weight-black"
      >
        <!-- @page-count="pageCount = $event" -->
      <template v-slot:[`item.status`]="{ item }">
      <v-chip
        class="status-chip"
        :color="item.stateId.description === 'Active' ? 'green' : 'red'"
        dark
        small
      >
        {{ item.stateId.description }}
      </v-chip>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon
          small
          class="mr-2"
          @click="editDriver(item)"
        >
          mdi-pencil-outline
        </v-icon>
        <v-icon
          small
          @click="openDeleteDialog(item, item.id)"
        >
          mdi-trash-can-outline
        </v-icon>
      </template>
      </v-data-table>
      <loading-table v-if="isLoading" />
      <template v-if="deleteDialog">
        <delete-dialog
        :showModal="deleteDialog"
        @closed="closeDeleteDialog"
        />
      </template>
      <div class="text-center my-4 pb-3">
        <v-pagination
        v-model="page"
        :length="pageCount"
      ></v-pagination>
      </div>
    </v-card>
  </div>
</template>
<script>
import { ROUTES } from '@/store/constants';
import * as Drivers from '@/services/drivers';
import LoadingTable from '@/components/general/LoadingTable.vue';
import DeleteDialog from '@/components/dialog/Delete.vue';

export default {
  name: 'Drivers',
  components: {
    LoadingTable,
    DeleteDialog,
  },
  data() {
    return {
      take: 10,
      skip: 0,
      deleteDialog: false,
      ROUTES,
      isLoading: false,
      page: 1,
      pageCount: 1,
      itemsPerPage: 10,
      search: '',
      drivers: [],
      driver: [],
      driverId: 0,
      headers: [
        { text: 'Actions', value: 'actions', sortable: false },
        { text: 'Id', value: 'id' },
        { text: 'Name', value: 'name' },
        { text: 'Email', value: 'email' },
        { text: 'Contact', value: 'phone' },
        { text: 'Rate', value: 'rate' },
        { text: 'License No.', value: 'license_no' },
        { text: 'Status', value: 'status' },

      ],
    };
  },
  watch: {
    page() {
      this.skip = (this.page - 1) * 10;
      this.getDrivers();
    },
    search() {
      this.skip = 0;
      this.getDrivers();
    },
  },
  methods: {
    async getDrivers() {
      this.isLoading = true;
      const response = await Drivers.getDrivers(this.take, this.skip, this.search);
      this.pageCount = Math.ceil(response.count / 10);
      this.drivers = response.data;
      this.isLoading = false;
    },
    editDriver(driver) {
      const to = { name: 'edit driver', params: { id: driver.id } };
      this.$router.push(to);
    },

    openDeleteDialog(driver, id) {
      this.driver = driver;
      this.driverId = id;
      this.deleteDialog = true;
    },

    async closeDeleteDialog(close) {
      if (close) {
        this.driver.stateId = 3;
        this.driver.url_license_img = 'none';
        this.driver.url_medical_img = 'none';
        const result = await Drivers.deleteDriver(this.driverId, this.driver);
        if (result) this.getDrivers();
      }
      this.deleteDialog = false;
    },
  },
  created() {
    this.getDrivers();
  },
};
</script>
