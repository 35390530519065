<template>
  <div>
    <v-overlay :value="isLoading">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
import { mapState } from 'vuex';

export default {
  name: 'Container',
  computed: {
    ...mapState({
      isLoading: (state) => state.Session.isLoading,
    }),
  },
};
</script>
