import { ROUTES } from '@/store/constants';
import HomeView from '@/views/HomeView.vue';
import Login from '@/views/Login.vue';
import Drivers from '@/views/Drivers/Drivers.vue';
import Tractors from '@/views/Tractors/Tractors.vue';
import Trailers from '@/views/Trailers/Trailers.vue';
import FormDriver from '@/components/drivers/FormDriver.vue';
import FormTractor from '@/components/tractors/FormTractor.vue';
import FormTrailer from '@/components/trailers/FormTrailer.vue';
import FormBroker from '@/components/brokers/FormBroker.vue';
import Brokers from '@/views/Brokers/Brokers.vue';
import Users from '@/views/Users/Users.vue';
import FormUser from '@/components/users/FormUsers.vue';
import Loads from '@/views/Loads/Loads.vue';
import Factured from '@/views/Loads/Factured.vue';
import Billed from '@/views/Loads/Billed.vue';
import Paid from '@/views/Loads/Paid.vue';
import Billing from '@/views/Billing/PayManagement.vue';
import FormLoads from '@/components/loads/FormLoad.vue';
import Reporting from '@/views/Reporting/Reporting.vue';

export default [
  {
    path: `/${ROUTES.LOGIN}`,
    name: ROUTES.LOGIN,
    component: Login,
  },
  {
    path: '/',
    meta: { session: true },
    component: () => import(/* webpackChunkName: "Dashboard" */ '@/views/Dashboard/Dashboard.vue'),
    children: [
      {
        path: '',
        name: ROUTES.HOME,
        component: HomeView,
      },
      {
        path: '/drivers',
        component: () => import(/* webpackChunkName: "Home" */ '@/views/Drivers/Home.vue'),
        children: [
          {
            path: '',
            name: ROUTES.DRIVERS.ROOT,
            component: Drivers,
          },
          {
            path: 'create',
            name: ROUTES.DRIVERS.CREATE,
            component: FormDriver,
          },
          {
            path: 'edit/:id',
            name: 'edit driver',
            component: FormDriver,
          },
        ],
      },
      {
        path: '/tractors',
        component: () => import(/* webpackChunkName: "Home" */ '@/views/Tractors/Home.vue'),
        children: [
          {
            path: '',
            name: ROUTES.TRACTORS.ROOT,
            component: Tractors,
          },
          {
            path: 'create',
            name: ROUTES.TRACTORS.CREATE,
            component: FormTractor,
          },
          {
            path: 'edit/:id',
            name: 'edit tractor',
            component: FormTractor,
          },
        ],
      },
      {
        path: '/trailers',
        component: () => import(/* webpackChunkName: "Home" */ '@/views/Trailers/Home.vue'),
        children: [
          {
            path: '',
            name: ROUTES.TRAILERS.ROOT,
            component: Trailers,
          },
          {
            path: 'create',
            name: ROUTES.TRAILERS.CREATE,
            component: FormTrailer,
          },
          {
            path: 'edit/:id',
            name: 'edit trailer',
            component: FormTrailer,
          },
        ],
      },
      {
        path: '/users',
        component: () => import(/* webpackChunkName: "Home" */ '@/views/Users/Home.vue'),
        children: [
          {
            path: '',
            name: ROUTES.USERS.ROOT,
            component: Users,
          },
          {
            path: 'create',
            name: ROUTES.USERS.CREATE,
            component: FormUser,
          },
          {
            path: 'edit/:id',
            name: 'edit user',
            component: FormUser,
          },
        ],
      },
      {
        path: '/brokers',
        component: () => import(/* webpackChunkName: "Home" */ '@/views/Brokers/Home.vue'),
        children: [
          {
            path: '',
            name: ROUTES.BROKERS.ROOT,
            component: Brokers,
          },
          {
            path: 'create',
            name: ROUTES.BROKERS.CREATE,
            component: FormBroker,
          },
          {
            path: 'edit/:id',
            name: 'edit broker',
            component: FormBroker,
          },
        ],
      },
      {
        path: '/loads',
        component: () => import(/* webpackChunkName: "Home" */ '@/views/Loads/Home.vue'),
        children: [
          {
            path: '',
            name: ROUTES.LOADS.ROOT,
            component: Loads,
          },
          {
            path: '/factured',
            name: ROUTES.LOADS.FACTURED,
            component: Factured,
          },
          {
            path: '/billed',
            name: ROUTES.LOADS.BILLED,
            component: Billed,
          },
          {
            path: '/paid',
            name: ROUTES.LOADS.PAID,
            component: Paid,
          },
          {
            path: 'create',
            name: ROUTES.LOADS.CREATE,
            component: FormLoads,
          },
          {
            path: 'edit/:id',
            name: 'edit load',
            component: FormLoads,
          },
        ],
      },
      {
        path: '/billing',
        component: () => import(/* webpackChunkName: "Home" */ '@/views/Billing/Home.vue'),
        children: [
          {
            path: '',
            name: ROUTES.BILLING.ROOT,
            component: Billing,
          },
        ],
      },
      {
        path: `/${ROUTES.REPORTING.HOME}`,
        name: ROUTES.REPORTING.HOME,
        component: Reporting,
      },
    ],
  },
];
