<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="390"
    >
      <v-card>
        <v-card-title class="title d-flex justify-center">
          Are you sure to delete the registry?
        </v-card-title>
        <v-card-text class="d-flex justify-center">
          <v-icon small color="red" class="icon-delete my-auto">
            mdi-trash-can-outline
          </v-icon>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="gay darken-1"
            text
            @click="closeDialog(false)"
          >
            Cancel
          </v-btn>
          <v-btn
            color="red darken-1"
            @click="closeDialog(true)"
          >
            <span class="white--text">Delete</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
export default {
  name: 'DeleteDialog',
  props: {
    showModal: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
    },
  },
  data() {
    return {
      dialog: true,
    };
  },
  watch: {
    showModal() {
      this.dialog = this.showModal;
    },
    data() {
      this.shipper = this.data;
    },
  },
  methods: {
    closeDialog(value) {
      this.dialog = false;
      this.$emit('closed', value);
    },
  },
};
</script>
