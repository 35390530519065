<template>
  <div class="content-invoice">
    <div class="invoice">
      <div class="header d-flex justify-space-between">
        <div class="logo">
          <img src="@/assets/img/milam.png" alt="logo"/>
          <div><b>MILAM TRANSPORT, LLC</b></div>
          <div>TAMPA, FL 33646</div>
          <div>PO BOX 40783</div>
          <div><b>Phone:</b> 1-888-433-0331</div>
          <div><b>Fax:</b> 813-315-6260</div>
        </div>
        <div class="mt-10">
          <div class="invoice-number">
            <div class="item">
              <div class="number">Invoice #</div>
              <div>Reference</div>
              <div>Invoice Date</div>
            </div>
            <div class="value">
              <div> {{ load.id }} </div>
              <div> {{ load.reference }} </div>
              <div> {{ new Date()  | formatDate }} </div>
            </div>
          </div>
        </div>
      </div>
      <br>
      <div class="col-sm-12 col-12 space" v-if="load.brokerId">
          <div class="col-sm-12 col-12 space"> <b> BILL TO: {{ load.brokerId.company }} </b></div>
          <div class="col-sm-5 col-5 space">{{ load.brokerId.address }} </div>
          <div class="col-sm-12 col-12 space"> <b>Contact:</b> {{ load.brokerId.contact }} </div>
        </div>
      <div class="load-info mt-16">
        <table>
          <thead>
            <th>Type</th>
            <th>Location</th>
            <th>Date</th>
          </thead>
          <tbody>
            <tr v-for="(shipper, i) in load.shipperId" :key="i">
              <td>Pickup</td>
              <td>{{ shipper.path }}</td>
              <td>{{ shipper.pickup_start_date | formatDate }}</td>
            </tr>
            <tr v-for="(consig, i) in load.consigneeId" :key="i+100">
              <td>Drop</td>
              <td>{{ consig.path }}</td>
              <td>{{ consig.drop_start_date | formatDate }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="load-info mt-16">
        <table>
          <thead>
            <th>Invoice</th>
            <th>Amount</th>
          </thead>
          <tbody>
            <tr v-for="(accesorial , i) in load.accesorialId" :key="i">
              <template v-if="accesorial.accesorialTypeId.id === 1">
                <td>{{ accesorial.notes }}</td>
                <td class="amount">${{ accesorial.total }}</td>
              </template>
            </tr>
            <tr>
              <td style="border: none"></td>
              <td class="amount">
                sub-total:  ${{ calculateIncome(load).totalIncome }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="invoice-total text-right font-weight-black mt-10">
        <div class="pr-1">Invoice Total</div>
        <div> ${{ calculateIncome(load).totalIncome }} USD </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Invoice',
  props: {
    load: {
      type: Object,
      required: true,
    },
  },
  methods: {
    calculateIncome(load) {
      let totalIncome = 0;
      const { accesorialId } = load;
      if (accesorialId === undefined) {
        return {
          totalIncome,
        };
      }
      accesorialId.forEach((acc) => {
        if (acc.accesorialTypeId.id === 1) {
          totalIncome += acc.total;
        }
      });
      // eslint-disable-next-line
      return {
        totalIncome,
      };
    },
  },
};
</script>
