<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="500px"
      class="rounded-lg"
    >
      <v-card class="rounded-lg">
        <v-icon class="btn-close-dialog" @click="closeDialog(true)">mdi-close</v-icon>
        <v-card-title>
          <span class="text-h5 mb-2">{{title}}</span>
        </v-card-title>
        <v-card-text>
          <v-form
            ref="form"
            v-model="validateForm"
            lazy-validation
          >
            <v-row class="ma-0">
              <v-col class="py-0">
               <v-autocomplete
                v-model="accesorial.accesorialCategoryId"
                :items="accesorialCategories"
                item-text="description"
                :return-object="true"
                label="Category"
                outlined
                dense
                required
                :rules="[rules.required]"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row class="ma-0">
              <v-col class="py-0 ma-0">
                <v-text-field
                v-model="accesorial.rate"
                type="number"
                label="Rate"
                required
                outlined
                dense
                :rules="[rules.required]"
                ></v-text-field>
              </v-col>
              <v-col class="py-0 ma-0">
                <v-text-field
                  v-model="accesorial.quantity"
                  label="Quantity"
                  type="number"
                  required
                  outlined
                  dense
                  :rules="[rules.required]"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="ma-0">
              <v-col class="py-0 ma-0">
                <v-textarea
                  v-model="accesorial.notes"
                  label="Notes"
                  outlined
                  dense
                  rows="2"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red darken-1"
            text
            @click="closeDialog"
          >
          <span class="text-red">Close</span>
          </v-btn>
          <v-btn
            color="primary"
            @click="validate"
          >
            Add accesorial
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import * as constants from '@/store/constants';

export default {
  name: 'AccesorialDialog',
  props: {
    showModal: {
      type: Boolean,
      required: true,
    },
    data: {
      required: false,
    },
  },
  data() {
    return {
      accesorial: {
        company: '',
        state: 1,
        notes: '',
        accesorialCategoryId: {
          description: 'Flat Rate',
          id: 3,
        },
      },
      dialog: true,
      validateForm: false,
      rules: {
        required: (v) => !!v || '*required',
        validateState: [
          (v) => v !== '' || '* state is required',
          (v) => v !== null || '* state is required',
        ],
      },
    };
  },
  watch: {
    showModal() {
      this.dialog = this.showModal;
    },
  },
  computed: {
    ...mapState({
      accesorialCategories: (state) => state.Loads.accesorialCategories,
      accesorialTypes: (state) => state.Loads.accesorialTypes,
      accesorialAction: (state) => state.Loads.accesorialAction,
      accesorialNumberType: (state) => state.Loads.accesorialNumberType,
      accesorialEdit: (state) => state.Loads.accesorialEdit,
    }),
    title() {
      return 'Accessorial';
    },
  },
  methods: {
    ...mapActions({
      loadAddAccesorial: constants.LOADS_SET_ACCESORIAL,
    }),
    validate() {
      this.$refs.form.validate();
      setTimeout(() => {
        this.addAccesorial();
      }, 500);
    },
    addAccesorial() {
      if (!this.validateForm) return;
      const value = this.accesorial.rate * this.accesorial.quantity;
      this.accesorial.total = Number(value.toFixed(2));
      if (this.accesorialAction === 'create') {
        this.accesorial.accesorialTypeId = this.accesorialTypes
          .filter((acc) => acc.id === this.accesorialNumberType).pop();
      }
      this.loadAddAccesorial({ accesorial: this.accesorial, action: this.accesorialAction });
      this.$emit('closed');
    },
    closeDialog() {
      this.$emit('closed');
    },
  },
  created() {
    if (this.accesorialAction === 'edit') {
      this.accesorial = this.accesorialEdit;
    }
  },
};
</script>
