<template>
  <div class="content-load">
    <div class="display-1">Billed to Customer (UNPAID)</div>
    <v-card class="mb-10">
      <v-card-title class="d-flex flex-column align-stretch">
        <v-row>
          <v-spacer></v-spacer>
          <v-btn v-if="loadSelected.length > 0" text class="mx-3"
            color="primary"
            @click="makePayLoad"
            >
            <v-icon small>mdi-check</v-icon>
            {{ 'Mark Paid by Customer' }}
          </v-btn>
        </v-row>
        <v-row>
        <v-spacer></v-spacer>
        <v-col>
          <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
        </v-col>
        </v-row>
      </v-card-title>
      <div class="load-table">
        <div class="load-table-content">
          <v-data-table
            v-if="!isLoading"
            :calculate-widths="false"
            dense
            :headers="headers"
            :search="search"
            :single-expand="false"
            :items="loads"
            :page.sync="page"
            :items-per-page="itemsPerPage"
            hide-default-footer
            show-select
            @page-count="pageCount = $event"
            :mobile-breakpoint="100"
            v-model="loadSelected"
            class="text-body-1 font-weight-black"
          >
          <template v-slot:[`item.driverId.name`]="{ item }">
            <div v-if="item.driverId">
              {{ item.driverId.name.substring(0, 15) }}...
            </div>
          </template>
                <template v-slot:[`item.reference`]="{ item }">
            <div>
              {{ item.reference.substring(0, 10) }}
              <span v-if="item.reference.length >= 10">...</span>
            </div>
          </template>
          <template v-slot:[`item.shipperId`]="{ item }">
            <div v-if="item.shipperId.length > 0">
              {{ splitDirections(item.shipperId[0].path).substring(0, 15) }}...
            </div>
          </template>
          <template v-slot:[`item.income`]="{ item }">
            <div v-if="item.accesorialId.length > 0">
              $ {{ calculateIncome(item).totalIncome }}
            </div>
          </template>
          <template v-slot:[`item.rpm`]="{ item }">
            <div v-if="item.accesorialId.length > 0">
              $ {{ calculateIncome(item).rpm }}
            </div>
          </template>
          <template v-slot:[`item.shipperId[0].pickup_start_date`]="{ item }">
            <div v-for="(row, f) in item.shipperId" :key="`b-${f}`">
              {{ row.pickup_start_date | formatDate(true, false) }}
            <span v-if="row.pickup_end_date">
              &ensp;to&ensp; {{ row.pickup_end_date | formatDate(true, false) }}
            </span>
            </div>
          </template>
          <template v-slot:[`item.consigneeId`]="{ item }">
            <div v-if="item.consigneeId.length > 0">
              {{ splitDirections( item.consigneeId[0].path).substring(0, 15) }}...
            </div>
          </template>
          <template v-slot:[`item.consigneeId[0].drop_start_date`]="{ item }">
            <div v-for="(row, e) in item.consigneeId" :key="`e-${e}`">
             {{ row.drop_start_date | formatDate(true, false) }}
             <span v-if="row.drop_end_date">
              &ensp;to&ensp; {{ row.drop_end_date | formatDate(true, false)}}
            </span>
            </div>
          </template>
          <template v-slot:[`item.milles`]="{ item }">
            <div>
              {{ item.milles | fixedDecimal()}}
            </div>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon
              small
              class="mr-2"
              @click="editLoad(item)"
            >
              mdi-pencil-outline
            </v-icon>
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <v-chip
              :class="item.stateLoadId.description "
              class="d-flex justify-center"
              dark
              small
              v-if="item.stateLoadId"
              :style="{width: 100 + '%'}"
            >
              {{ item.stateLoadId.description }}
            </v-chip>
          </template>
          </v-data-table>
        </div>
      </div>
      <loading-table v-if="isLoading" />
      <div class="text-center my-4 pb-3">
        <v-pagination
        v-model="page"
        :length="pageCount"
      ></v-pagination>
      </div>
    </v-card>
  </div>
</template>
<script>
import { ROUTES } from '@/store/constants';
import * as Loads from '@/services/loads';
import LoadingTable from '@/components/general/LoadingTable.vue';

export default {
  name: 'Loads',
  components: {
    LoadingTable,
  },
  data() {
    return {
      take: 200,
      skip: 0,
      isLoading: false,
      loads: [],
      loadSelected: [],
      ROUTES,
      page: 1,
      pageCount: 5,
      itemsPerPage: 50,
      search: '',
      expanded: [],
      singleExpand: false,
      headers: [
        { text: 'Actions', value: 'actions', sortable: false },
        { text: 'Load ID', value: 'id' },
        { text: 'Reference', value: 'reference' },
        { text: 'Load Status', value: 'status' },
        { text: 'Customers', value: 'brokerId.company' },
        { text: 'Picks', value: 'shipperId' },
        { text: 'Pick Date', value: 'shipperId[0].pickup_start_date' },
        { text: 'Drops', value: 'consigneeId' },
        { text: 'Drop Date', value: 'consigneeId[0].drop_start_date' },
        { text: 'Driver', value: 'driverId.name' },
        { text: 'Income', value: 'income' },
        { text: 'RPM', value: 'rpm' },
        { text: 'Tractor P.U', value: 'tractorId.power_unit' },
        { text: 'Distance', value: 'milles' },
      ],
    };
  },
  watch: {
    page() {
      this.skip = (this.page - 1) * 10;
      this.getLoads();
    },
    search() {
      this.skip = 0;
      this.getLoads();
    },
  },
  methods: {
    editLoad(load) {
      const to = { name: 'edit load', params: { id: load.id } };
      this.$router.push(to);
    },
    async getLoads() {
      this.isLoading = true;
      const response = await Loads.getLoads(this.take, this.skip, this.search, [6]);
      this.pageCount = Math.ceil(response.count / 10);
      this.loads = response.data;
      this.isLoading = false;
    },
    async makePayLoad() {
      try {
        const loads = this.loadSelected
          .map((load) => ({ id: load.id, stateLoadId: 7 }));
        await Loads.makePayLoad(loads);
        this.loadSelected = [];
        await this.getLoads();
      } catch (error) {
        console.log('error: ', error);
      }
    },
    calculateIncome(load) {
      let totalIncome = 0;
      let rpm = 0;
      const { accesorialId, milles } = load;
      if (accesorialId.length === 0) return;
      accesorialId.forEach((acc) => {
        if (acc.accesorialTypeId.id === 1) {
          totalIncome += acc.total;
        }
      });

      rpm = totalIncome / milles;
      // eslint-disable-next-line
      return {
        totalIncome,
        rpm: rpm.toFixed(2),
      };
    },

    splitDirections(directions) {
      const split = directions.split(',');
      if (split.length > 3) {
        const result = split.splice(1, 10);

        return String(result);
      }
      return String(split);
    },
  },
  created() {
    this.getLoads();
  },
};
</script>
